import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: "15px",
        // flexBasis: '33.33%',
        flexBasis: '100%',
        flexShrink: 0,
        fontWeight: "bold",
    },
    // secondaryHeading: {
    //     fontSize: theme.typography.pxToRem(15),
    //     color: theme.palette.text.secondary,
    // },
}));

export default function CAccordionControlled(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        function handleStatusChange(status) {
        }

        // console.dir('props.initial', props.initial);
        if (props.initial) {
            setExpanded(props.initial);
        }
        return function cleanup() {
        };
    }, [])

    return (
        <div className={classes.root}>

            {props.titles.map((title, index) =>
                <Accordion key={"acc"+index+title} className={props.addclass} expanded={expanded === 'panel' + index}
                           onChange={handleChange('panel' + index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls={"panel" + index + "bh-content"}
                        id="panel1bh-header"
                    >
                        <div className={classes.heading}>
                            {
                                (typeof title === 'string' || title instanceof String)
                                ? <span style={{fontSize: '18px'}} dangerouslySetInnerHTML={{__html: title}}></span>
                                : <span style={{fontSize: '18px'}} >{title}</span>
                            }
                        </div>
                        {/*<Typography className={classes.secondaryHeading}>I am an accordion</Typography>*/}
                    </AccordionSummary>
                    <AccordionDetails style={{padding: "8px 16px 38px"}}>
                        {props.contents[index]}
                    </AccordionDetails>
                </Accordion>
            )}

        </div>
    );
}
