import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import * as constants from "../../../common/Constants"
import CTextField from "../../../common/CTextField";
import Editor from '../../../common/Editor';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import InvitationPreview from "./InvitationPreview";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";


export default function LoadedEmail(props)  {

    const {t, i18n} = useTranslation();
    const [editorContent, setEditorContent] = useState(null);
    const [email, setEmail] = useState([]);
    const [emailLocale, setEmailLocale] = useState([]);
    const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [previewData, setPreviewData] = useState(false);

    const notify = () => toast.success(t('Succesfully saved!'), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const changeLocale = (lang) => {
        setEditorContent(emailLocale.content);
        setLocaleLanguage(lang);
    }

    useEffect(() => {
        setLocaleData(email);
    },[localeLanguage])

    const thid =  props;

    const setLocaleData = (data) => {
        let localeData = {};
        Object.keys(data).map(value => {

            if(typeof data[value] === 'object' && data[value] !== null && value != "draggable_executives" && value != "files" && value != "executives" && value != "socials"){
                // console.log("a",value,data[value])
                localeData[value] = data[value][localeLanguage]?data[value][localeLanguage]:" ";
            }else{
                // setEmailLocale([value]:data[value])
                // console.log("b",value,data[value])
                localeData[value] = data[value]?data[value]:" ";
            }
        })
        // console.log("localeData",localeData,localeLanguage,email)
        setEmailLocale(localeData);
        setEditorContent(localeData.content);
    }

    useEffect(() => {
        const loadEmail = async () => {
            setLoading(true);
            const response = await axios.post(constants.LocalUrl + '/data/get_predefined_email', {id: thid}).then(response => {
                setEmail(response.data);
                setLocaleData(response.data);
                setLoading(false);
            });

        }
        loadEmail();
    }, []);

    useEffect(() => {
        setPreviewData({
            title: emailLocale.subject,
            message: emailLocale.content
        })
    }, [emailLocale]);


    const onSubmitForm = () => {
        // console.log("onSubmitForm",emailLocale)

        const submitTheData = async () => {
            await axios.post(
                constants.LocalUrl + '/data/set_predefined_email',{...emailLocale, lang: localeLanguage}).then(response => {

                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                }else{
                    notify();
                    props.triggerdataload();
                }
            });
        }

        // Call the function
        submitTheData();
    };

    const onInputchange = (event) => {
        event.persist();

        if(typeof email[event.target.name] === 'object'){
            // console.log('object')

            setEmail((prevState) => ({
                ...prevState,
                [event.target.name]:{
                    ...prevState[event.target.name],
                    [localeLanguage]:event.target.value,
                }
            }));
        }else{
            // console.log('nope')
            setEmail((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value,
            }));
        }
        setEmailLocale((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

        // console.log(email)
    };


    const handleSettingsFunc = (data) => {
        setEmailLocale((prevState) => ({
            ...prevState,
            content: data,
        }));

        // event.persist();
        setEmail((prevState) => ({
            ...prevState,
            ['content']:{
                ...prevState['content'],
                [localeLanguage]:data,
            }

        }));
        // setLocaleData(email)
        // console.log("handleSettingsFunc",emailLocale,email,data)

    }

    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            right: '9px',
            top: '0px'
        },
    }));

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: 0,
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        select : {
            width: '100%'
        }
    }));

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    if(loading === true){
        return <Loader />
    }

    let wrapper_style = {
        display:'flex',justifyContent:'center',transition:'1s all'
    }

    return (
        <>
            <div style={{width: '80vh' ,display:'flex',justifyContent:'center'}}>
            <Grid container spacing={2} style={{padding:'0px',maxWidth:'100%'}}>
                <Grid item xs={12}>
                    <div>
                        <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                            {t('Edit Email')}
                        </Typography>
                        {/*<Typography alignLeft variant="subtitle1" style={{textAlign:'center'}} gutterBottom>*/}
                        {/*    <span style={{fontSize:"21px",fontStyle:"italic"}}>{emailLocale.title}</span>*/}
                        {/*</Typography>*/}
                        <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
                        <div id='sponsors_wrapper' style={wrapper_style}>
                            <React.Fragment key={'right'}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <CTextField value={emailLocale.subject?emailLocale.subject:""} handler={onInputchange} name='subject' label={t('Subject')} required error={hasError('subject')} helperText={errorMessage('subject')}/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Editor lang={localeLanguage} handler={handleSettingsFunc} value={editorContent} name="content" label={t('Content')}/>
                                    </Grid>

                                    {
                                        showPreview
                                            ? <Grid item xs={12}>
                                                <InvitationPreview previewData={previewData}></InvitationPreview>
                                            </Grid>
                                            : null
                                    }


                                    <PanelBottomBar language={changeLocale} close={props.triggerdrawer(false)}  submit={onSubmitForm} switch={showPreview} switchhandler={() => setShowPreview(!showPreview)}/>

                                    {/*<Grid className="panel_utilities_element" item xs={12}>*/}
                                    {/*    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)} className={close_button.root}>{t("go_back")}</Button>*/}
                                    {/*    <FormControlLabel*/}
                                    {/*        control={<Switch checked={showPreview} onChange={()=>setShowPreview(!showPreview)} />}*/}
                                    {/*        label={t("Preview")}*/}
                                    {/*    />*/}
                                    {/*    <div className="right_part">*/}

                                    {/*        <LanguageChangerForPanel onChange={setLocaleLanguage}/>*/}
                                    {/*        <Button id="close_button" variant="contained" color="primary" onClick={onSubmitForm} component="span">*/}
                                    {/*            {t('SAVE')}*/}
                                    {/*        </Button>*/}
                                    {/*    </div>*/}
                                    {/*</Grid>*/}
                                </Grid>

                            </React.Fragment>
                        </div>
                    </div>
                </Grid>
            </Grid>
            </div>
        </>
    );

}

