import React, {useContext, useEffect, useState} from 'react';
import * as constants from "../../../../common/Constants";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CTextField from "../../../../common/CTextField"
import FileUploader from "../../../../common/FileUploader";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";
import ToastProvider from "../../../../../../Providers/ToastProvider";


export default function NewFile(props){

    const {notify} = useContext(ToastProvider);
    const {t, i18n} = useTranslation();
    const [file,setfile] = useState([]);


    useEffect(() => {
        // const loadSchedule = async () => {
        setfile(props.data?props.data:'');
        // }
        // console.log(props);
        // console.log("asd");

        // Call the function
        // loadSchedule();

    }, []);

    const handleFiles = (event) => {
        event.persist();

        setfile((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

    }

    const onSubmitForm = () => {
        // console.log({file_name:file,sponsor_id:props.sponsor_id})
        axios.post(
            constants.LocalUrl + '/data/sponsorAddFiles',{file_name:file,sponsor_id:props.sponsor_id,filename:props.filename}).then(response => {

            // console.log(response)
            if(response.data.status == 'ok'){
                notify();
            }
            props.triggerdataload(response.data.data);
            props.triggerdrawerfunc(false);
        });

    }

    // if(file.length === 0){
    //     return "Loading..."
    // }


    return (

        <div>
            {/*<Grid container spacing={3} style={{padding:'20px',position:"relative"}}>*/}
                {/*<Grid item xs={12}>*/}
                    {/*<CloseIcon onClick={props.triggerdrawer(false)} style={{position:'absolute',right:'9px',top:'9px'}}/>*/}
                {/*</Grid>*/}
            {/*</Grid>*/}

            <Grid container spacing={3} style={{padding:'20px'}}>
                {/*<Typography aligncenter variant="h3" style={{textAlign:'center'}} gutterBottom>*/}
                {/*    New File*/}
                {/*</Typography>*/}
                <Grid item xs={6} className="new_file_title">
                    <CTextField bgColor="#fff" name="title" label={t('File Name')} handler={handleFiles} value={file.title}/>
                    <br/><br/>

                    <FileUploader holdFilename={props.holdFilename} sponsor_id={props.sponsor_id} type={'sponsor_files_data'} filePath={"sponsors_files"} />

                </Grid>
                <Grid item xs={12} style={{textAlign:"right"}}>
                    <Button id="close_button" onClick={onSubmitForm} variant="contained" color="primary" component="span">{t('Save')}</Button>
                </Grid>
            </Grid>
            <ToastContainer
                position="top-right"
                autoClose={8000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )

}