import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PageProvider from "../../../Page/PageContext";
import * as constants from "../../common/Constants";
import {InputTextHandler} from "../../common/InputTextHandler";
import {SetLocaleData} from "../../../common/SetLocaleData";
import {FormErrors} from "../../../common/FormErrors";
import ToastProvider from "../../../../Providers/ToastProvider"

// import Users from "../../../../../api/services/Panel/users"
// import Agenda from "../../../../../api/services/Panel/agenda"


const SponsorHook = (props) => {

    const {settings} = useContext(PageProvider);
    const {t, i18n} = useTranslation();
    let non_translate = ["executives","socials"]
    const {hasError, errorMessage, setFormErrors} = FormErrors();
    const {notify} = useContext(ToastProvider);
    const [validationErrors, setValidationErrors] = useState(null);


    const [sponsorid, setSponsorid] = useState(null);
    const [sponsor, setSponsor] = useState([]);
    const [sponsorLocale, setSponsorLocale] = useState([]);
    const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
    const [executives, setExecutives] = useState([]);
    const [sponsorcategories, setSponsorcategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sponsorcategory, setSponsorcategory] = useState('');
    const [showSponsorFiles, setShowSponsorFiles] = useState({state:false,id:''});
    const [sponsorFiles, setSponsorFiles] = useState([]);
    const [filename, setFilename] = useState('');
    const [editorDescription, setEditorDescription] = useState(null);

    let video_types = [{id:'vimeo',title:'Vimeo'},{id:'youtube',title:'YouTube'}]
    let sponsor_sizes = [{id:'Large',title:t('Large')},{id:'Big',title:t('Medium')},{id:'Medium',title:t('Small')},{id:'Small',title: t('Line')}]


    const {
        onTextchange
        , handleDraggableListItemInState
        , handleDraggableOrder
        , handleEditor
        ,handleTwoDimensionArray
        ,setImage
    }
        = InputTextHandler({state: sponsor, setState: setSponsor, lang: localeLanguage});
    const {setLocaleDataFunction} = SetLocaleData(non_translate);
    let correct_end_time = {end_time: [t('set_correct_end_time')]}

    useEffect(() => {
        setFormErrors(validationErrors);
    }, [validationErrors])

    const setLocaleData = (data) => {
        setLocaleDataFunction(data ? data : sponsor, setSponsorLocale, localeLanguage, setEditorDescription);
    }

    useEffect(() => {
        setLocaleData();
    }, [sponsor, localeLanguage])

    useEffect(() => {
        if (Object.keys(sponsor).length > 0) {
            setEditorDescription(sponsor.description[localeLanguage]);
        }
    }, [localeLanguage])

    const changeLocale = (lang) => {
        setLocaleLanguage(lang);
    }

    const holdFilename = (filename) => {
        // console.log("filename")
        // console.log(filename)
        setFilename(filename);
    };

    const showSponsorFilesMenu = (state,id) => () => {
        // console.log(sponsorFiles,state,id)
        setShowSponsorFiles({state:state,id:id});
    };

    useEffect(() => {
        const loadSponsor = async () => {
            axios.post(constants.LocalUrl + '/data/get_sponsor_categories').then(response => {
                // console.log('response', response);
                // After fetching data stored it in posts state.
                setSponsorcategories(response.data);

                // Closed the loading page
                setLoading(false);
            });

            axios.post(constants.LocalUrl + '/data/get_all_executives').then(response => {
                // console.log('response', response);
                // After fetching data stored it in posts state.
                setExecutives(response.data);

                // Closed the loading page
                // setLoading(false);
            });
        }

        loadSponsor();

    },[])

    useEffect(() => {
        if (props.id) {
            loadSponsor();
        }
        else{
            loadSponsorNew();
        }
        // Call the function
    }, []);

    const loadSponsorNew = () => {

        setSponsor({
            name: "newsponsor"
        })

    }

    const loadSponsor = async () => {
        setSponsorid(props.id);
        // Till the data is fetch using API
        // the Loading page will show.
        setLoading(true);

        // Await make wait until that
        // promise settles and return its reult
        // console.log("props.idd",props)
        const response = await axios.post(constants.LocalUrl + '/data/sponsor_full_data', {id: props.id,type:'panel'}).then(response => {
            // console.log('response full data', response);
            // After fetching data stored it in posts state.
            setSponsor(response.data);
            setLocaleData(response.data)
            setSponsorFiles(response.data.files);

            // Closed the loading page
            setLoading(false);
        });

    }

    const create_sponsor = async () => {

        const __formData = new FormData();
        Object.keys(sponsor).map((value,index)=>{
            if( typeof sponsor[value] === 'object' && value != "banner_file" && value != "logo_file"){
                __formData.append(value, JSON.stringify(sponsor[value]));
            }else{
                __formData.append(value, sponsor[value]);
            }
        })

        axios({
            url: '/data/create_sponsor',
            method: 'POST',
            data: __formData,
            headers: {
                // Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            if(response.data.status == "error"){
                setFormErrors(response.data.data.errors);
            }else{
                setLoading(false);

                props.triggerdataload(response.data.data);

                props.triggerdrawerfunc(false);
            }

        });

    }

    const update_sponsor = async () => {
        await axios.post(
            constants.LocalUrl + '/data/update_sponsor',{...sponsorLocale, lang: localeLanguage}).then(response => {

            if (response.data.status == 'error') {
                setFormErrors(response.data.data.errors);
            }else{

                notify(t('Succesfully saved!'));
                let index_id;
                const i = props.posts.map((single_post,index) =>  {
                        if(single_post.id === response.data.data.id){
                            index_id = index;
                            return true;
                        }
                    }
                );

                let items = [...props.posts];
                let item = {...items[index_id]};
                item.title = response.data.data.title;
                items[index_id] = item;

                props.triggerdataload(items);
                // props.triggerdrawerfunc(false);
            }
        });
    }

    const onSubmit = () => {
        if (props.id) {
            // Call the function
            update_sponsor();
        } else {
            create_sponsor();
        }
    }


    // event, state, type, extra (κλειδιά από keys σε τα arrays)
    const onInputchange = (event) => {

        if(event.target.name === "category_id"){
            setSponsorcategory(event.target.value);
        }

        onTextchange(event);
    }

    const getImageLogo = (file) => {
        setImage(file,"logo_file")
    }

    const getImageWideBanner = (file) => {
        setImage(file,"banner_file")
    }

    const handleDescription = (data) => {
        handleEditor(data, 'description');
    }

    const handleExecutivesOrder = (event) => {
        handleDraggableOrder(event, 'executives');
    }

    const onInputchangeSocials = (event) => {
        // console.log('socials',event);
        // handleDraggableOrder(event, 'socials');
        handleTwoDimensionArray(event, 'socials');
    }

    const onExecutivesChange = (event, values) => {
        handleDraggableListItemInState(event, values, 'executives');
    }

    const addFileHandler = (file) => {
        // console.log("addFileHandler")
        // console.log(file,sponsorFiles)
        setSponsorFiles((sponsorFiles) => [...sponsorFiles,file])
        // console.log("addFileHandleraaa")
        // console.log("sponsorFiles")
        // console.log(sponsorFiles)
        // setPosts((posts) => [data, ...posts]);
    };

    const updatePostsHandler = (file) => {
        console.log("updatePostsHandler",file);
    };

    const showSponsorFilesMenuFunc = (state,id) => {
        // setShowSponsorFiles({state:state,id:id});
        // console.log("asdasd");
        setShowSponsorFiles({...showSponsorFiles, state: state,id:id});

    };

    return {
        sponsor_sizes:sponsor_sizes,
        video_types:video_types,
        sponsorid:sponsorid,
        filename:filename,
        sponsorcategories:sponsorcategories,
        sponsorcategory:sponsorcategory,
        showSponsorFiles:showSponsorFiles,
        sponsorFiles:sponsorFiles,
        settings: settings,
        sponsor: sponsor,
        executives:executives,
        sponsorLocale: sponsorLocale,
        editorDescription: editorDescription,
        localeLanguage: localeLanguage,
        loading: loading,
        getImageLogo,
        getImageWideBanner,
        holdFilename,
        addFileHandler,
        updatePostsHandler,
        showSponsorFilesMenuFunc,
        onInputchangeSocials,
        handleExecutivesOrder,
        onExecutivesChange,
        showSponsorFilesMenu,
        changeLocale,
        handleDescription,
        onInputchange,
        onSubmit,
        hasError, errorMessage, setFormErrors
    };

}

export default SponsorHook;