import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import {ReactSVG} from 'react-svg'
import * as constants from "../../../common/Constants";
import DeleteIcon from '@material-ui/icons/Delete';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";
import TooltipProvider from "../../../../../Providers/TooltipProvider"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const edit_icon = {
    icon: "/images/edit.svg",
    delete: "/images/trash.svg",
}


export default function ListDividers({props, triggerdrawer, triggerdataload}) {

    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const {BoldTooltip} = useContext(TooltipProvider);
    const deleteRow = (id) => {
        const response = axios.post(constants.LocalUrl + "/data/delete_object", {object: 'Agenda',id:id}).then(response => {
            if(response.data.status == "error"){
                toast.error(t('item not deleted'), window.toastOptions);
            }else{
                toast.success(t('item deleted'), window.toastOptions);
            }
            triggerdataload(null);
        });
    }


    return (
        <div style={{width:"100%"}}>
            {/*<Paper style={{overflowY:'auto',maxHeight:'600px',padding:'20px',borderRadius:'23px'}} >*/}
            {/*<Paper style={{padding:'20px',borderRadius:'23px',width:"100%"}} >*/}
            {/*    <List component="nav" className={classes.root}  style={{width:'100%',maxWidth:"unset"}} fullwidth>*/}
            {/*        {props.map((row) => (*/}
            {/*            <div>*/}
            {/*            <Divider />*/}
            {/*            <ListItem button fullwidth>*/}

            {/*                <ListItemText primary={row.title}/>*/}
            {/*                /!*<ListItemText style={{marginLeft:"25px"}} primary={new Intl.DateTimeFormat('en-US', {hour: '2-digit', minute: '2-digit'}).format(row.startTime*1000)} secondary={row.startDate} />*!/*/}
            {/*                <ListItemText style={{marginLeft:"25px"}} primary={row.startTime} secondary={row.startDate} />*/}

            {/*                <Button  className="edit-button" size="medium" onClick={triggerdrawer(true,row.id)} sponsor-id={row.id}  height="50" color="secondary"> <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.icon} /></Button>*/}
            {/*                <Button  className="delete-button" size="medium" onClick={()=>deleteRow(row.id)} height="50" color="secondary">*/}

            {/*                    <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.delete} />*/}
            {/*                </Button>*/}
            {/*            </ListItem>*/}
            {/*            </div>*/}
            {/*        ))}*/}
            {/*    </List>*/}
            {/*</Paper>*/}
            <TableContainer id="basic_table_panel" component={Paper} style={{padding: '20px', borderRadius: '23px', height: "auto"}}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell width={"50%"} style={{fontSize:"18px",fontWeight:"bold"}} >{t('Title')}</TableCell>
                            <TableCell width={"15%"} align="left" style={{fontSize:"18px",fontWeight:"bold"}} >{t('Time')}</TableCell>
                            <TableCell width={"15%"} align="left" style={{fontSize:"18px",fontWeight:"bold"}} >{t('Date')}</TableCell>
                            <TableCell width={"20%"} align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {props.map((row) => (

                            <TableRow key={"list"+row.id}>
                                <TableCell component="th" scope="row">
                                    <ListItemText style={{fontSize:"16px"}} primary={row.title}/>
                                </TableCell>
                                <TableCell align="left" ><ListItemText style={{fontSize:"16px"}} primary={row.startTime} /></TableCell>
                                <TableCell align="left" ><ListItemText style={{fontSize:"16px"}} primary={row.startDate} /></TableCell>
                                <TableCell align="right" className="list_buttons_container">
                                    <BoldTooltip title={t("Edit")} arrow>
                                    <Button  className="edit-button" size="medium" onClick={triggerdrawer(true,row.id)} sponsor-id={row.id}  height="50" color="secondary">
                                        <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.icon} />
                                    </Button>
                                    </BoldTooltip>
                                    <BoldTooltip title={t("Delete")} arrow>
                                    <Button  className="delete-button" size="medium" onClick={()=>deleteRow(row.id)} height="50" color="secondary">
                                        <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.delete} />
                                    </Button>
                                    </BoldTooltip>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
