import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FileUploadComponent from "../../../common/FileUploadComponent";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as constants from "../../../common/Constants"
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CTextField from "../../../common/CTextField";
import Editor from "../../../common/Editor";
import DraggableSimplestList from "../../../../common/DraggableSimplestList";
import {useTranslation} from "react-i18next";
import FormHelperText from "@material-ui/core/FormHelperText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import AutoCompleteField from "../../../../common/AutoCompleteField";
import SponsorsHook from "../SponsorsHook";
import CSelectBox from "../../../common/CSelectBox";


const useStyles = makeStyles({
    list: {
        // width: '800px',
        // padding: '40px'
    },
    fullList: {
        width: 'auto',
    },
    select: {
        width: '100%'
    }
});


export default function NewSponsor(props) {

    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const {
        getImageWideBanner,
        getImageLogo,
        sponsor_sizes,
        video_types,
        sponsorcategory,
        sponsorid,
        holdFilename,
        sponsorcategories,
        sponsorFiles,
        showSponsorFiles,
        showSponsorFilesMenu,
        addFileHandler,
        handleDescription,
        executives,
        onExecutivesChange,
        handleExecutivesOrder,
        filename,
        onInputchangeSocials,
        editorDescription,
        sponsor,
        updatePostsHandler,
        showSponsorFilesMenuFunc,
        onInputchange,
        onSubmit, hasError, errorMessage
    } = SponsorsHook(props, 'edit');

    if ((executives && Object.keys(executives).length == 0) || (sponsor && Object.keys(sponsor).length == 0)) {
        return <Loader/>
    }

    return (
        <div className="bottom_bar">


            <Typography variant="h3" style={{textAlign: 'center'}} gutterBottom>
                {t('New Sponsor')}
            </Typography>
            <Divider style={{backgroundColor: "#39BB0F", marginBottom: "15px"}}/>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <React.Fragment key={'right'}>
                    <Grid container spacing={4} style={{padding: '20px', maxWidth: '800px'}}>

                        <Grid item xs={12}>
                            <Typography xs={12} variant="h3" style={{textAlign: 'left'}} gutterBottom>
                                {t('Sponsor Details')}
                            </Typography>
                            <Divider light/>
                        </Grid>

                        <Grid item xs={6}>
                            <CTextField handler={onInputchange} name='title' label={t('Title')} required
                                        error={hasError('title')} helperText={errorMessage('title')}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Editor handler={editorDescription} value="" name="description" label={t('Description')}/>
                        </Grid>

                        <Grid item xs={6}>
                            <CSelectBox
                                title={t('Sponsor Category')}
                                required={true}
                                name="category_id"
                                onChange={onInputchange}
                                data={sponsorcategories}
                                value={sponsor.category_id}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <CSelectBox
                                title={t('Sponsor_Type')}
                                required={true}
                                name="typos"
                                onChange={onInputchange}
                                data={sponsor_sizes}
                                value={sponsor.typos ? sponsor.typos : 'Small'}
                            />
                        </Grid>

                        <Grid item xs={6} className="panel_sponsor logo_images">
                            <Typography variant="h6"
                                        style={{display: 'block', marginBottom: "15px", marginLeft: "20px"}}
                                        gutterBottom>
                                {t('Sponsor Logo')}
                                <span
                                    className="image_text_description">{t('upload_dimensions', {text: '300px * 180px'})}</span>
                            </Typography>
                            <FileUploadComponent id="1" notautoprocess={true} getImage={getImageLogo}
                                                 extra={{action: constants.LocalUrl + '/data/addSponsorLogo'}} fullwidth
                                                 accept={constants.acceptedUploadMimeGroups.images}
                                                 maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                                 maxWidth={300} maxHeight={180}
                            />

                        </Grid>

                        <Grid item xs={6} className="panel_sponsor logo_images">
                            <Typography variant="h6"
                                        style={{display: 'block', marginBottom: "15px", marginLeft: "20px"}}
                                        gutterBottom>
                                {t('Sponsor Banner')}
                                <span
                                    className="image_text_description">{t('upload_dimensions', {text: '720px * 392px'})}</span>
                            </Typography>
                            <FileUploadComponent id="2" notautoprocess={true} getImage={getImageWideBanner}
                                                 extra={{action: constants.LocalUrl + '/data/addSponsorTopwidebanner '}}
                                                 fullwidth
                                                 accept={constants.acceptedUploadMimeGroups.images}
                                                 maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                                 maxWidth={720} maxHeight={392}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl variant="outlined" className={classes.formControl} style={{width: '100%'}}>
                                <Typography variant="h6" style={{
                                    display: 'block',
                                    marginBottom: "15px",
                                    marginLeft: "20px",
                                    padding: "0"
                                }} gutterBottom>
                                    {t('Video')}
                                </Typography>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {/*<InputLabel id="demo-simple-select-outlined-label">Video Recording</InputLabel>*/}
                                    <Select
                                        style={{marginRight: "15px", width: "140px"}}
                                        labelId="demo-simple-select-outlined-label"
                                        IconComponent={ExpandMoreIcon}
                                        id="grouped-native-select"
                                        value={sponsor.type_video}
                                        onChange={onInputchange}
                                        name="type_video"
                                    >
                                        {video_types.map((row) => (
                                            <MenuItem key={"qq"+row.id} value={row.id}>{row.title}</MenuItem>
                                        ))}

                                    </Select>
                                    <TextField id="outlined-basic" label="" variant="outlined" onChange={onInputchange}
                                               name='video'/>
                                </div>
                            </FormControl>
                        </Grid>


                        <Grid item xs={6}>
                            <CTextField handler={onInputchange} name='website' label={t('Website')}/>
                        </Grid>

                        <Grid item xs={6}>
                            <CTextField handler={onInputchange} name='email' label={t('Email')}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography xs={12} variant="h3" style={{textAlign: 'left'}} gutterBottom>
                                {t('Executives')}
                            </Typography>
                            <Divider light/>
                        </Grid>
                        <Grid item xs={6}>
                            <AutoCompleteField
                                placeholder={t("Choose_an_executive")}
                                model={executives}
                                data={sponsor.executives}
                                onChange={onExecutivesChange}
                                handleItemsOrder={handleExecutivesOrder}
                            />

                        </Grid>


                        <br/>
                        <Grid item xs={12}>
                            <Typography xs={12} variant="h3" style={{textAlign: 'left'}} gutterBottom>
                                {t("Settings Social links")}:
                            </Typography>
                            <Divider/>
                            <br/>
                        </Grid>

                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Facebook")}
                                            name="Facebook"/>
                            </Grid>
                            <Grid item xs={6}>
                                <CTextField handler={onInputchangeSocials} label={t("Sponsor Social LinkedIn")}
                                            name="LinkedIn"/>
                            </Grid>
                            <Grid item xs={6}>
                                <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Youtube")}
                                            name="Youtube"/>
                            </Grid>
                            <Grid item xs={6}>
                                <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Twitter")}
                                            name="Twitter"/>
                            </Grid>
                        </Grid>


                        {/*<Grid item xs={12}>*/}
                        {/*    <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>*/}
                        {/*        Files*/}
                        {/*    </Typography>*/}
                        {/*    <Divider light />*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={12}>*/}
                        {/*    <FileUploader filePath={"sponsors_files"} sponsor_id={sponsor.id}/>*/}
                        {/*</Grid>*/}
                        <PanelBottomBar close={props.triggerdrawer(false)} submit={onSubmit}/>

                    </Grid>
                </React.Fragment>
            </div>
        </div>

    );

}

