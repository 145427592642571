import React, {useContext, useEffect} from "react";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import Modal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import {createMedia} from "@artsy/fresnel";
import StageMobile from "../Page/content/Stage/StageMobile";
import StageIndividual from "../Page/content/Stage/StageIndividual";
import PageProvider from "../Page/PageContext";


function rand() {
    return Math.round(Math.random() * 20) - 10;
}

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

export default function ModalWithButton(props) {

    const {t, i18n} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const {closeModalWithButton,setCloseModalWithButton} = useContext(PageProvider);

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #fff',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 2, 2),
            width: '1200px',
            height: '800px',
            position: "relative"
        },
    }));

    const useStyles_mobile = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #fff',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 2, 3),
            width: '95%',
            height: '800px',
            position: "relative"
        },
    }));

    const classes = useStyles();
    const classes_mobile = useStyles_mobile();

    const handleOpen = () => {
        setOpen(true);
    };


    useEffect(()=>{

        if(closeModalWithButton == true){
            setOpen(false);
            setCloseModalWithButton(false)
        }

    },[closeModalWithButton])

    const handleClose = (event,reason) => {
        if (reason && (reason == "backdropClick" || "escapeKeyDown")){
            return;
        }else{
            if (props.triggerdataload != undefined) {
                props.triggerdataload();
            }
            setOpen(false);
        }
    };

    return (
        <>
            {
                (props.button_type??'primary') == 'primary'
                ? <Button size="medium" onClick={handleOpen} variant="contained" height="50" color="secondary">{props.button_text}</Button>
                :
                    // (props.button_type??'primary') == 'secondary'
                     <Button onClick={handleOpen} classname="login_register_button_wrapper" as="span" variant="outlined" className="item" style={{display:"block",marginTop:"10px",padding:"5px 40px",borderRadius:"20px",borderColor:"rgba(0,0,0,0.87)"}}>
                            <Typography  className="login_register_button" align="center" variant="h6" style={{fontWeight:"500",fontSize:"15px",marginBottom:0}} color="rgba(0,0,0,0.87)" gutterBottom>
                                {props.button_text}
                            </Typography>
                        </Button>

            }

            <Modal
                // ref={props.ref}
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <MediaContextProvider>

                    <Media at='mobile'>
                        <div className={classes_mobile.paper}>
                            <CloseIcon className="modalbutton_close" onClick={handleClose} style={{position:'absolute',zIndex:"1",right:'30px',top:'21px',cursor:'pointer'}}/>
                            {props.children}
                        </div>
                    </Media>

                    <Media at='computer'>
                        <div className={classes.paper}>
                            <CloseIcon onClick={handleClose} style={{position:'absolute',zIndex:"1",right:'12px',top:'21px',cursor:'pointer'}}/>
                            {props.children}
                        </div>
                    </Media>

                </MediaContextProvider>

            </Modal>
        </>
    );
}