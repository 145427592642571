import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 0,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select : {
        width: '100%'
    }
}));

export default function CSelectBox(props){

    const classes = useStyles();

    return(
        <>
            <Typography variant="h6" style={{marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                {props.title}{props.required?" *":null}
            </Typography>
            <FormControl variant="outlined" className={classes.select}>

                <Select
                    labelId="grouped-native-select"
                    IconComponent={ExpandMoreIcon}
                    id="grouped-native-select"
                    value={props.value?props.value:''}
                    onChange={props.onChange}
                    name={props.name}
                    style={props.style?props.style:null}
                >
                    {/*<MenuItem value="">*/}
                    {/*    <em>None</em>*/}
                    {/*</MenuItem>*/}
                    {props.data && props.data.map((row) => (
                        <MenuItem key={row.title+"_"+row.id} value={row.id}>{row.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}