import React, {useEffect, useState} from 'react';
import Loader from "../../../common/Loader";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import * as constants from "../../../Panel/common/Constants";


export default function StageVideo (props){

    const [stage,setStage] = useState(null);
    const [streams,setStreams] = useState(null);
    const [video,setVideo] = useState(0);
    const [listens, setListens] = useState(false);

    const trackUserAtStage = () => {
        window.stageTrackInterval = setInterval(() => {
            // console.log('sending notification every second!');
            axios.post(constants.LocalUrl + '/data/trackStageUser', {lang: streams?video:null})
        }, 6000);
    }

    useEffect(() => {
        const loadPost = () => {
            setStage(props.stage)
            setStreams(props.streams)
        }
        // Call the function
        loadPost();

        if (!listens) {
            window.channel_stage.listen('.stage.change_status', (data) => {
                // console.log('listened .stage.change_status');
                clearInterval(window.stageTrackInterval);
                if (data.all.stage.status == 1) {
                    trackUserAtStage()
                }
                setStage(data.all.stage);
            });
            setListens(true);
        }

        if (stage != null) {
        }

        return () => {
            // Anything in here is fired on component unmount.
            clearInterval(window.stageTrackInterval);
            clearInterval(window.stageTrackInterval);
        }

    }, []);

    if(props.video !== video){
        setVideo(props.video);
    }

    if (stage == null) {
        return <Loader/>
    }

    if (stage.status == 1) {
        clearInterval(window.stageTrackInterval);
        trackUserAtStage();
    }

    return <>
        {
            stage.status == 0
                ? <div className={'stage-player-container'}>
                    <img src={stage.image} alt="" style={{maxWidth: '100%'}}/>
                </div>
                :<></>
        }
        {
            stage.status == 1
                ?
                <>
                    {
                        stage.isZoom == 1
                            ? <div className={'stage-player-container embed-responsive embed-responsive-16by9'}>
                                <iframe src={"/zoomframe/"+stage.id} frameborder="0" scrolling='no' seamless='seamless' sandbox='allow-modals allow-top-navigation-by-user-activation allow-forms allow-scripts allow-same-origin' allow='camera; microphone; fullscreen; sharescreen'></iframe>
                            </div>
                            : <div className={'stage-player-container embed-responsive embed-responsive-16by9'} dangerouslySetInnerHTML={ {__html:  streams?streams[video].code:'Loading'}} />
                    }
                </>
                :<></>
        }
        {
            stage.status == 2
                ? <div className={'stage-player-container'}>
                    <img src={stage.postevent_image} alt="" style={{"max-width": '100%'}}/>
                </div>
                :<></>
        }
        {
            stage.status == 3
                ? <div className={'stage-player-container'}>
                    <img src={stage.eventerror_image} alt="" style={{"max-width": '100%'}}/>
                </div>
                :<></>
        }
        {
            stage.status == 9 && props.demandSession
                ? <div className={'stage-player-container embed-responsive embed-responsive-16by9'}>
                    <iframe src={props.demandSession} frameBorder="0" ></iframe>
                </div>
                :<></>
        }
    </>


}
