import {NavLink} from "react-router-dom";
import * as constants from "../Panel/common/Constants";
import React from "react";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import LanguageChangerForPanel from "./Language/LanguageChangerForPanel";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {createMedia} from "@artsy/fresnel";
import StageMobile from "../Page/content/Stage/StageMobile";
import StageIndividual from "../Page/content/Stage/StageIndividual";
import {ReactSVG} from "react-svg";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const close_buttonz = makeStyles((theme) => ({
    root: {
        // position: 'absolute',
        // right: '9px',
        // top: '0px'
    },
}));

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})
let icons = {
    back:"/images/back_mobile_lite.svg",
    save:"/images/save_mobile_lite.svg",
    preview:"/images/preview_mobile_lite.png",
}

export default function PanelBottomBar(props){

    const {t, i18n} = useTranslation();
    const close_button = close_buttonz();

    return(


                <Grid id="panel_utilities_element" style={props.style} className={props.classes?props.classes:"panel_utilities_element"} item xs={12}>
                    <MediaContextProvider>
                        <Media at='mobile' style={props.style?props.style:{justifyContent:"space-between"}}>
                            {props.close?<div className="back_button_mobile"><Button variant="contained" color="secondary" onClick={props.close} className={close_button.root}><ReactSVG beforeInjection={(svg) => {
                                svg.classList.add('svg-class-name');
                                svg.setAttribute('style', 'width:25px');
                            }} src={icons.back}/></Button></div>:null}

                            {props.preview?<Button onClick={props.preview} className="login_register_button_wrapper" as="span" variant="outlined" className="item" style={{display:"block",marginTop:"10px",padding:"5px 5px",borderRadius:"20px",border:"none"}}>
                                <Typography  className="login_register_button" align="center" variant="h6" style={{fontWeight:"500",fontSize:"15px",marginBottom:0,color:"rgba(0,0,0,0.87)"}}  gutterBottom>
                                    <img src={icons.preview} alt=""/>
                                </Typography>
                            </Button>:null}

                            {props.switchhandler?<FormControlLabel style={{display:'flex',flexDirection:"column-reverse",marginBottom:"0",textAlign:"center"}}
                                control={<Switch checked={props.switch} onChange={props.switchhandler} />}
                                label={t("Preview")}
                            />:null}

                            <div className="right_part">
                                {props.language?<LanguageChangerForPanel description="no" onChange={props.language}/>:null}
                                {props.submit?<Button id="close_button" variant="contained" color="primary" onClick={props.submit} component="span"><ReactSVG beforeInjection={(svg) => {
                                    svg.classList.add('svg-class-name');
                                    svg.setAttribute('style', 'width:25px');
                                }} src={icons.save}/></Button>:null}
                            </div>
                        </Media>
                        <Media greaterThanOrEqual='computer' style={props.style?props.style:{justifyContent:"space-between"}}>
                            {props.close?<Button variant="contained" color="secondary" onClick={props.close} className={close_button.root} component="span">{t("go_back")}</Button>:null}
                            {props.preview?<Button onClick={props.preview} className="login_register_button_wrapper" as="span" variant="outlined" className="item" style={{display:"block",marginTop:"10px",padding:"5px 40px",borderRadius:"20px",borderColor:"rgba(0,0,0,0.87)"}}>
                                <Typography  className="login_register_button" align="center" variant="h6" style={{fontWeight:"500",fontSize:"15px",marginBottom:0,color:"rgba(0,0,0,0.87"}} gutterBottom>
                                    {t("Preview")}
                                </Typography>
                            </Button>:null}

                            {props.switchhandler?<FormControlLabel style={{marginBottom:"0"}}
                                control={<Switch checked={props.switch} onChange={props.switchhandler} />}
                                label={t("Preview")}
                            />:null}

                            <div className="right_part">
                                {props.language?<LanguageChangerForPanel onChange={props.language}/>:null}
                                {props.submit?<Button id="close_button" variant="contained" color="primary" onClick={props.submit} component="span">{t('Save')}</Button>:null}
                            </div>
                        </Media>
                    </MediaContextProvider>
                </Grid>



            //     <Grid id="panel_utilities_element" style={props.style} className={props.classes?props.classes:"panel_utilities_element"} item xs={12}>
            //         {props.close?<Button variant="contained" color="secondary" onClick={props.close} className={close_button.root}>{t("go_back")}</Button>:null}
            //         {props.preview?<Button onClick={props.preview} classname="login_register_button_wrapper" as="span" variant="outlined" className="item" style={{display:"block",marginTop:"10px",padding:"5px 40px",borderRadius:"20px",borderColor:"rgba(0,0,0,0.87)"}}>
            //             <Typography  className="login_register_button" align="center" variant="h6" style={{fontWeight:"500",fontSize:"15px",marginBottom:0}} color="rgba(0,0,0,0.87)" gutterBottom>
            //                 {t("Preview")}
            //             </Typography>
            //         </Button>:null}
            //         <div className="right_part">
            //             {props.language?<LanguageChangerForPanel onChange={props.language}/>:null}
            //             {props.submit?<Button id="close_button" variant="contained" color="primary" onClick={props.submit} component="span">{t('Save')}</Button>:null}
            //         </div>
            //     </Grid>
            // </Media>



    )
}