import React, {useContext, useEffect, useState} from "react";
import "./styles.css";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UpdateFile from "../../Panel/tabs/Sponsors/components/files/UpdateFile";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import * as constants from "../../Panel/common/Constants";
import {toast} from "react-toastify";
import {Button} from "semantic-ui-react";
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";
import ToastProvider from "../../../Providers/ToastProvider";


export default function App(props) {

    const {notify} = useContext(ToastProvider);
    const {t, i18n} = useTranslation();
    const [itemList, setItemList] = useState([]);
    const edit_icon = {
        icon: "/images/edit.svg",
        delete: "/images/trash.svg",
    }

    useEffect(() => {

        setItemList(props.data?props.data:'');

    }, [props.data]);


    // React state to track order of items
    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList);
        props.save(updatedList);
    };
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    }));
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const deleteRow = (id) => {
        const response = axios.post(constants.LocalUrl + "/data/delete_object", {object: 'Sponsorfiles',id:id}).then(response => {
            if(response.data.status == "error"){
                toast.error(t('item not deleted'), window.toastOptions);
            }else{
                notify(t('item deleted'));
                // toast.success(t('item deleted'), window.toastOptions);
                // console.log("itemList",itemList)
                let listItems = [...itemList]
                if(listItems.length > 0){
                    listItems.map((item,index) => {
                        if(item.id == id){
                            listItems.splice(index,1)
                        }
                    })
                }
                setItemList(listItems);
            }
            props.triggerdataload();
        });
    }

    return (

        <Paper style={{overflowY:'auto',maxHeight:'600px',padding:'20px', borderRadius:'23px'}}>
            <List component="nav" style={{width:'100%',maxWidth:"unset", borderRadius:'23px'}}>


                {/*{console.log(itemList)}*/}

                <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                        {(provided) => (
                            <div
                                className="list-container"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{width:"100%"}}
                            >
                                {itemList && itemList.map((item, index) => (

                                    <Draggable key={item.title} draggableId={item.title} index={index}>
                                        {(provided) => (

                                            <div
                                                className="item-container"
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                            >
                                                <Divider light />
                                                <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header"
                                                    >
                                                        {/*<DragIndicatorIcon style={{fill:"#d5d5d5",position:"relative",left:"-8px"}}/>*/}
                                                        <ImportExportIcon style={{fill:"#818181",position:"relative",left:"-8px",top:"6px"}}/>
                                                        <ListItemText primary={item.label?item.label:item.title} />
                                                        {/*<Button className="edit-button"  size="medium" onClick={props.edit(true,item.id)} link-id={item.id}  height="50" color="secondary"> <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.icon} /></Button>*/}
                                                    </AccordionSummary>

                                                    <AccordionDetails style={{justifyContent:"space-between",alignItems:"center"}}>
                                                        <UpdateFile  data={item} edit={props.edit} save={props.save} filename={props.filename} holdFilename={props.holdFilename} triggerdataload={props.triggerdataload} sponsor_id={props.sponsor_id} triggerdrawer={props.triggerdrawer} triggerdrawerfunc={props.triggerdrawerfunc} OnClick={props.OnClick}/>
                                                        {/*<ListItem button fullwidth key={item.id}>*/}
                                                        {/*    <DragIndicatorIcon style={{fill:"#d5d5d5",position:"relative",left:"-8px"}}/>*/}
                                                        {/*    <ListItemText primary={item.title} />*/}
                                                        <Button className="edit-button"  size="medium" onClick={() => deleteRow(item.id)} link-id={item.id}  height="50"> <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.delete} /></Button>
                                                        {/*</ListItem>*/}
                                                    </AccordionDetails>
                                                </Accordion>

                                            </div>


                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>


            </List>
        </Paper>
    );
}