import React, {useContext, useEffect, useState} from "react";
import "./styles.css";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Paper from "@material-ui/core/Paper";
import {ListItem} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PageProvider from "../../Page/PageContext";
import {ReactSVG} from "react-svg";
import TooltipProvider from "../../../Providers/TooltipProvider";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: '100%',
//         maxWidth: 360,
//         backgroundColor: theme.palette.background.paper,
//     },
// }));

export default function App(props) {

    const {BoldTooltip} = useContext(TooltipProvider);
    const {category_icons} = useContext(PageProvider);
    const defaultList = ["A", "B", "C", "D", "E"];
    const [itemList, setItemList] = useState([]);
    const edit_icon = {
        icon: "/images/edit.svg",
    }
    useEffect(() => {
        // const loadSchedule = async () => {
        setItemList(props.data?props.data:'');
        // }
        // console.log(props.data);
        // console.log("asd");

        // Call the function
        // loadSchedule();

    }, [props.data]);
    // React state to track order of items


    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList);
        props.handleItemsOrder?props.handleItemsOrder(updatedList):null;
        props.save?props.save(updatedList):null;
    };

    // dd(props)
    // console.log("itemList")
    // console.log(itemList)
    return (
        // <div className="App">
        <Paper className="draggable_automplete" elevation={0} style={{overflowY:'auto',maxHeight:'600px',padding:'20px'}}>
            <List component="nav"   style={{width:'100%',maxWidth:"unset", borderRadius:'23px'}}>


                <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                        {(provided) => (
                            <div
                                className="list-container"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{width:"100%"}}
                            >
                                {itemList && Object.values(itemList).map((item, index) => (

                                    <Draggable key={item.title} draggableId={item.title} index={index}>
                                        {(provided) => (

                                            <div
                                                className="item-container"
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                            >
                                                <Divider light />
                                                <ListItem button key={item.id}>
                                                    <ImportExportIcon style={{fill:"#818181",position:"relative",left:"-8px"}}/>
                                                    {/*<CSwitchField state={props.states?props.states[item.name]:""} handler={props.handler} label={item.label.en} name={item.name}/>*/}
                                                    {/*{props.data && props.data.map((row) => (*/}
                                                    {/*    <div>*/}
                                                    {/*        <Divider light />*/}
                                                    {/*        <ListItem button fullwidth>*/}
                                                    <ListItemText primary={item.title}  />
                                                    {item.category_icon?<ListItemText style={{textAlign:"right",float:"right",flex:"0 0 auto"}} primary={
                                                        <BoldTooltip title={item.category_name} arrow>
                                                        <ReactSVG beforeInjection={(svg) => {
                                                        svg.classList.add('svg-class-name');
                                                        svg.setAttribute('style', 'width:31px');
                                                        svg.setAttribute('fill', item.category_color);
                                                    }} src={category_icons[item.category_icon]}/>
                                                        </BoldTooltip>
                                                    } />:null}
                                                    {/*<Button className="edit-button"  size="medium" onClick={props.edit(true,item.id)} link-id={item.id}  height="50" color="secondary"> <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.icon} /></Button>*/}
                                                    {/*</ListItem>*/}
                                                    {/*</div>*/}
                                                    {/*))}*/}
                                                </ListItem>
                                            </div>


                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </List>
        </Paper>
        // </div>
    );
}