import React, {useContext, useEffect, useState} from "react";
import {Container, Typography} from "@material-ui/core";
import "../../login.css"
import PageProvider from "../Page/PageContext"
import UserFields from "../Panel/common/UserFields"
import Grid from "@material-ui/core/Grid";
import Logo from "../common/Logo";
import * as constants from "../Panel/common/Constants";
import Button from "@material-ui/core/Button";
import {NavLink} from "react-router-dom";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";


export default function Onboarding(props) {

    const {settings, setReloadData} = useContext(PageProvider);
    const [user, setUser] = useState([]);
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation();


    useEffect(() => {
        const loadUser = async () => {
            setLoading(true);
            window.axios.post(constants.LocalUrl + '/data/get_onboarding_user', {}).then(response => {
                if (response.data.status == 'ok') {
                    // console.log('response', response);
                    setUser(response.data.data.user);
                }
                else {
                    document.location.href = "/";
                }
                setLoading(false);
            });
        }
        loadUser();
        // if (settings.user && props.type != "registration_backend") {
        //     document.location.href = "/";
        // }
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        setError([]);
        axios.post(
            constants.LocalUrl + '/data/update_user', user).then(response => {
            // console.log("asdad", response.data)
            if (response.data.status == "error") {
                // console.log("ddddd", response.data.data.errors)
                setError(response.data.data.errors);
            } else {
                notify();
                document.location.href = "/app/login";
            }
        });
    }

    const notify = () => toast.success(t('Succesfully created account'), {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const onTagsChange = (event, values) => {
        event.persist();
        setUser(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                roler: values      // update the value of specific key

            }))
    }

    const onInputchange = (event) => {

        event.persist();

        // console.log(event);

        setUser((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
        // console.log("Edituser", user)
    };

    const onCountryChange = (event, newValue) => {
        setUser((prevState) => ({
            ...prevState,
            ['Country']: newValue ? newValue.code : null,
        }));
    };

    const onTimezoneChange = (event, newValue) => {
        setUser((prevState) => ({
            ...prevState,
            ['timezone']: newValue ? newValue.area : null,
        }));
    };

    const onEditorCVchange = (event) => {

        setUser((prevState) => ({
            ...prevState,
            cv: event,
        }));

    };


    const handleCheckbox = (event) => {
        event.persist();

        setUser((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.checked,
        }));

    }

    const getImage = (data) => {
        setUser((prevState) => ({
            ...prevState,
            file: data,
        }));
    }

    let classes = "";
    if (props.type == "registration_backend") {
        classes = "registration_container registration_backend"
    } else {
        classes = "registration_container"
    }


    return (
        <Container maxWidth={"xl"} className={classes}>

            <Grid container spacing={4} style={{padding: '20px', paddingTop: "0"}}>
                <Grid className="register_header" item xs={12} style={{borderBottom: "2px solid var(--divider-color)"}}>
                    <div className="register_logo_part">
                        <NavLink className="item" to={constants.LocalUrl + "/"}>
                            <Logo src={settings.logo} alt={t('alt_Logo')} height="75px"/>
                        </NavLink>
                    </div>
                    <div className="register_info_part">
                        <span className="register_event_title">
                            {settings.EVENT_NAME}
                        </span>
                        <span className="register_event_date">
                            {settings.EVENT_DAY} {settings.EVENT_DATE_STR}
                        </span>
                        <span className="register_event_time">
                            {settings.EVENT_TIME_STRING}
                        </span>

                        {props.type == "registration_backend" ?
                            <Button style={{borderRadius: "20px"}} style={{float: "right"}} variant="outlined"
                                    color="secondary" onClick={props.setDrawer(false)}>
                                {t("Close Preview")}
                            </Button> : ""}

                    </div>

                </Grid>
                <Grid item xs={12} style={{width: "90%", margin: "0 auto", flexBasis: "unset", paddingBottom: "20px"}}>
                    <Typography variant="h5" style={{
                        textAlign: 'center',
                        position: "relative",
                        top: "0",
                        color: "#212529",
                        fontSize: "24px"
                    }} gutterBottom>
                        {t('User Onboarding Title')}
                    </Typography>
                    <hr/>
                </Grid>
                <Grid item xs={12} style={{
                    marginBottom: "30px",
                    width: "89%",
                    paddingTop: 0,
                    flexBasis: "unset",
                    margin: "0 auto",
                    paddingBottom: "20px"
                }}>
                    <div className="registration_event_description"
                         dangerouslySetInnerHTML={{__html: settings.registration_header_text ? settings.registration_header_text[i18n.language] : ""}}/>
                </Grid>
                <Grid item xs={12} style={{width: "80%",display:"flex"}}>
                    <UserFields handleRegisterPageInstant={props.handleRegisterPageInstant} type="register_page"
                                loadingAction={props.loadingAction} handleUser={onInputchange}
                                handleCountry={onCountryChange} handleTimezone={onTimezoneChange}
                                handleEditor={onEditorCVchange} handleCheckbox={handleCheckbox} user={user}
                                error={error}/>
                </Grid>
                <Grid item xs={12}
                      style={{textAlign: "center", paddingRight: "119px", display: "flex", justifyContent: "flex-end"}}>
                    <div className="register_buttons_register">
                        <Button style={{width: "150px"}} id="close_button" onClick={handleSubmit} variant="contained"
                                color="primary" component="span">{t("Register")}</Button>&nbsp;&nbsp;&nbsp;&nbsp; <span
                        className="orregister just_or">{t("or")}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink className="url_button" to={constants.LocalUrl + "/app/login"}><Button id="close_button"
                                                                                                       variant="outlined"
                                                                                                       color="primary"
                                                                                                       component="span"
                                                                                                       style={{
                                                                                                           padding: "5px 52px",
                                                                                                           borderRadius: "20px",
                                                                                                           borderColor: "rgba(0,0,0,0.87)"
                                                                                                       }}>
                            <Typography align="center" variant="h6" style={{
                                fontWeight: "500",
                                fontSize: "15px",
                                marginBottom: 0,
                                color: "rgba(0,0,0,0.87)"
                            }} gutterBottom>
                                {t("Login")}
                            </Typography>
                        </Button></NavLink>
                    </div>
                    {/*<div><br/> or <NavLink to={constants.LocalUrl + "/app/login"}><Button id="close_button" variant="contained" color="primary" component="span">{t("Login")}</Button></NavLink></div>*/}
                </Grid>
            </Grid>
        </Container>
    )
}

