import React, {useEffect, useState} from 'react';
import axios from "axios";
import * as constants from "../../../Panel/common/Constants";

export default function StageSessions(props){

    const [stage,setStage] = useState(null);
    const [currentSession,setCurrentSession] = useState(null);
    const [listens, setListens] = useState(false);

    useEffect(() => {
        const loadPost = () => {
            axios.post(constants.LocalUrl + "/data/get_stage_current_session").then((response) => {
                setStage(response.data.stage);
                setCurrentSession(response.data.current_session);
            });
        }
        // Call the function
        loadPost();
        if (!listens) {
            window.channel_stage.listen('.stage.change_card', (data) => {
                // console.log('listened .stage.change_card');
                setStage(data.stage);
                setCurrentSession(data.current_session);
            });
            setListens(true);
        }
    }, []);

    return(
        <div className="card left-top stage" style={{borderRadius:'20px',marginBottom:'20px'}}>
            {/*<div className="card-header" style={{borderBottom: '1px solid var(--divider-color)', backgroundColor: '#ffffff',borderRadius:'16px 16px 0 0'}}>*/}
            <div className="card-header" style={{backgroundColor: '#ffffff',borderRadius:'16px'}}>
                <ul className="nav nav-tabs card-header-tabs left-top-header" style={{backgroundColor: '#ffffff'}}>
                    {props.stage && Object.values(props.stage['stream_code']).map((stream,key) => (
                        stream['name']?<li className={key==0?'nav-item active':'nav-item'}><a className="nav-link" stream={key} onClick={props.changeStream} href="#" style={{color: 'black', fontWeight: 700, margin: '0px 10px 10px 0px', borderBottom: 'initial', borderLeft: 'none', borderTop: 'none', backgroundColor: '#ffffff',fontSize:'18px'}}>{stream['name']}</a></li>:""
                    ))}

                </ul>
            </div>
        </div>
    )
}