import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import {ReactSVG} from 'react-svg'
import * as constants from "../../../common/Constants";
import DeleteIcon from "@material-ui/icons/Delete";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import TooltipProvider from "../../../../../Providers/TooltipProvider"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const edit_icon = {
    icon: "/images/edit.svg",
    SendIcon: "/images/list.svg",
    delete: "/images/trash.svg",
}

export default function ListDividers({props, triggerdrawer, triggerdrawerSend, triggerdrawerfunc, triggerdataload}) {

    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const {BoldTooltip} = useContext(TooltipProvider);
    // const posts = posts.posts;


    // const [id, setId] = React.useState(null);
    // const [idstate, setIdstate] = React.useState({
    //     id: null
    // });
    // const [state, setState] = React.useState({
    //     top: false,
    //     left: false,
    //     bottom: false,
    //     right: false,
    // });

    const deleteRow = (id) => {
        const response = axios.post(constants.LocalUrl + "/data/delete_object", {object: 'Invitation',id:id}).then(response => {
            if(response.data.status == "error"){
                toast.error(t('item not deleted'), window.toastOptions);
            }else{
                toast.success(t('item deleted'), window.toastOptions);
            }
            triggerdataload(null);
        });
    }

    // );
// console.log(state['id'])
    return (
        <div>
            <Paper id="draggable_simple_list" style={{padding:'10px 20px 20px 20px',borderRadius:'23px'}}>

                {/*<ListItem  fullwidth>*/}
                {/*        <ListItemText className="panel_table_list_title" primary={t("Marketing_campaigns")} />*/}
                {/*</ListItem>*/}
                <List component="nav" className={classes.root}  style={{width:'100%',maxWidth:"unset"}}>
                    {props.map((row) => (
                        <div key={"rr"+row.id}>
                        <Divider light />
                        <ListItem button>
                            <ListItemText primary={row.title} />
                            <BoldTooltip title={t("Edit")} arrow>
                            <Button className="edit-button" size="medium" onClick={triggerdrawer(true,row.id)} sponsor-id={row.id}  height="50" color="secondary">
                                <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.icon} />
                            </Button>
                            </BoldTooltip>
                            <BoldTooltip title={t("create_send_list")} arrow>
                            <Button className="send-button" size="medium" onClick={triggerdrawerSend(true,row)} sponsor-id={row.id}  height="50" color="secondary">
                                <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.SendIcon} />
                            </Button>
                            </BoldTooltip>
                            <BoldTooltip title={t("Delete")} arrow>
                            <Button  className="delete-button" size="medium" onClick={()=>deleteRow(row.id)} height="50" color="secondary">
                                <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.delete} />
                            </Button>
                            </BoldTooltip>
                        </ListItem>
                        </div>
                    ))}
                </List>
            </Paper>
        </div>
    );
}
