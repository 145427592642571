import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PageProvider from "../../../Page/PageContext";
import * as constants from "../../common/Constants";
import {InputTextHandler} from "../../common/InputTextHandler";
import {SetLocaleData} from "../../../common/SetLocaleData";
import {FormErrors} from "../../../common/FormErrors";
import ToastProvider from "../../../../Providers/ToastProvider"
import Sponsor from "../../../../api/services/Panel/sponsor";
// import Users from "../../../../../api/services/Panel/users"
// import Agenda from "../../../../../api/services/Panel/agenda"


const SponsorHook = (props) => {

    const {settings} = useContext(PageProvider);
    const {t, i18n} = useTranslation();
    let non_translate = []
    const {hasError, errorMessage, setFormErrors} = FormErrors();
    const {notify} = useContext(ToastProvider);


    const [sponsorid, setSponsorid] = useState(null);
    const [sponsor, setSponsor] = useState([]);
    const [sponsorcategories, setSponsorcategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sponsorcategory, setSponsorcategory] = useState('');
    const [sponsorCategoryLocale, setSponsorCategoryLocale] = useState([]);
    const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
    const [validationErrors, setValidationErrors] = useState(null);
    let category_icons = {
        1: "/app/award-1.svg",
        2: "/app/award-2.svg",
        3: "/app/award-3.svg",
        4: "/app/award-4.svg",
        5: "/app/award-5.svg",
    };

    const {
        handleColor,
        onTextchange
    }
        = InputTextHandler({state: sponsor, setState: setSponsor, lang: localeLanguage});
    const {setLocaleDataFunction} = SetLocaleData(non_translate);
    let correct_end_time = {end_time: [t('set_correct_end_time')]}

    useEffect(() => {
        setFormErrors(validationErrors);
    }, [validationErrors])

    const setLocaleData = (data) => {
        setLocaleDataFunction(data ? data : sponsor, setSponsorCategoryLocale, localeLanguage);
    }

    useEffect(() => {
        setLocaleData();
    }, [sponsor, localeLanguage])

    // useEffect(() => {
    //     if (Object.keys(sponsor).length > 0) {
    //         setEditorDescription(sponsor.description[localeLanguage]);
    //     }
    // }, [localeLanguage])

    const changeLocale = (lang) => {
        setLocaleLanguage(lang);
    }



    useEffect(() => {
        if (props.sponsor_category_id) {
            loadSponsor();
        } else {
            loadSponsorNew();
        }
        // Call the function
    }, []);

    const loadSponsorNew = () => {

        setSponsor({
            name: "newsponsor"
        })


    }

    const loadSponsor = async () => {

        setSponsorid(props.sponsor_category_id);
        // Till the data is fetch using API
        // the Loading page will show.
        // setLoading(true);

        // Sponsor.get(props.sponsor_category_id,"panel").then((response) => {
        //     // setLoading(false);
        //     setSponsor(response.data);
        // });

        // Await make wait until that
        // promise settles and return its reult
        const response = await axios.post(constants.LocalUrl + '/data/sponsor_category_full_data', {
            id: props.sponsor_category_id,
            type: "panel"
        }).then(response => {
            // console.log('response', response);
            // After fetching data stored it in posts state.
            setSponsor(response.data);
            // Closed the loading page
            setLoading(false);
        });

    }

    const create_sponsor_category = async () => {

        await axios.post(
            constants.LocalUrl + '/data/create_sponsor_category',sponsor).then(response => {

            if (response.data.status == 'error') {
                setFormErrors(response.data.data.errors);
            }else {
                // console.log(response.data);

                props.triggerdataload(response.data[0]);
                props.triggerdrawerfunc(false);
            }
        });

    }

    const update_sponsor_category = async () => {

        await axios.post(
            constants.LocalUrl + '/data/update_sponsor_category', {
                ...sponsorCategoryLocale,
                lang: localeLanguage
            }).then(response => {

            if (response.data.status == 'error') {
                setFormErrors(response.data.data.errors);
            } else {
                let index_id;
                const i = props.posts.map((single_post, index) => {
                        if (single_post.id === response.data.data.id) {
                            index_id = index;
                            return true;
                        }
                    }
                );

                let items = [...props.posts];
                let item = {...items[index_id]};
                item.title = response.data.data.title;
                items[index_id] = item;

                props.triggerdataload(items);
                props.triggerdrawerfunc(false);
            }
        });

    }

    const onSubmit = () => {
        if (props.sponsor_category_id) {
            // Call the function
            update_sponsor_category();
        } else {
            create_sponsor_category();
        }
    }


    // event, state, type, extra (κλειδιά από keys σε τα arrays)
    const onInputchange = (event) => {

        if (event.target.name === "category_id") {
            setSponsorcategory(event.target.value);
        }

        onTextchange(event);
    }

    const handleCategoryColor = (data) => {
        handleColor(data,"category_color")
    }


    return {
        sponsorCategoryLocale:sponsorCategoryLocale,
        category_icons:category_icons,
        sponsorid: sponsorid,
        sponsorcategories: sponsorcategories,
        sponsorcategory: sponsorcategory,
        settings: settings,
        sponsor: sponsor,
        localeLanguage: localeLanguage,
        loading: loading,
        changeLocale,
        handleCategoryColor,
        onInputchange,
        onSubmit, hasError, errorMessage, setFormErrors
    };

}

export default SponsorHook;