import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import * as constants from "../../Panel/common/Constants";
import PageProvider from "../../Page/PageContext";
import {CircularProgress} from "@material-ui/core";
import {ReactSVG} from 'react-svg';
import LiveonChatDropzone from "./LiveonChatDropzone";
import {useTranslation} from "react-i18next";


const chat_icons = {
    CloseIcon: "/images/panel/close_icon.svg",
    CameraIcon: "/images/panel/camera_icon.svg",
    MinimizeIcon: "/images/panel/minimize_icon.svg",
    MaximizeIcon: "/images/panel/maximize_window_icon.svg",
    SendMessageIcon: "/images/panel/send_message_icon.svg",
}

export default function LiveonChatWindow(props) {

    const {t, i18n} = useTranslation();
    const {settings} = useContext(PageProvider);
    // console.log(props.user_id, "ppppppp")
    const messagesEndRef = useRef(null)
    // const channel_id = useRef("channel." + props.room_id)
    // const room_id = useRef(props.room_id)
    // let channel_id = "channel." + props.room_id;
    // let room_id = props.room_id;
// console.log(props,room_id,"dddddd")
    const [myuser, setMyuser] = useState(null);
    const [show, setShow] = useState(true);
    const [hasNewMessage, setHasNewMessage] = useState(false);
    const [messageList, setMessageList] = useState([]);
    const [channel, setChannel] = useState(null);
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {

        if (!channel) {
            console.warn('Connecting to ws channel ' + props.room_id);
            let ch = window.Echo.join("videochat." + props.room_id)
                .here(users => {
                    // this.channelUsers = users;
                    // console.log('here');
                    // this.channel.whisper('typing', {
                    //     typing: true
                    // });
                })
                .joining(user => {
                    // this.channelUsers.push(user);
                })
                .leaving(user => {
                    // console.log('leaving');
                    // this.channelUsers = this.channelUsers.filter(u => u.id !== user.id);
                })
                .listenForWhisper('typing', ({typingData}) => {
                    console.log('listenForWhisper::typing=' + typingData);

                    /*this.users.forEach((user, index) => {
                        if (user.id === id) {
                            user.typing = true;
                            this.$set(this.users, index, user);
                        }
                    });*/
                })
                .listen('.meeting-chat.message-sent', (data) => {
                    setMessageList((messageList) => [...messageList, data.message]);
                    notify();
                });
            setChannel(ch);
        }

        setMyuser(settings.user);
        axios.post(constants.LocalUrl + '/data/chat_get_user_conversation_messages', {room_id: props.room_id})
            .then(res => {
                const messageList = res.data;
                setMessageList(messageList);
            })
        // setHasNewMessage( props.conversation.last_message[window.temp_user_id] && props.conversation.last_message[window.temp_user_id].status == 'New' );
    }, []);

    useEffect(() => {
        // console.log(messageList);
        let incomingMessages = messageList.filter((e) => {
            return e.to_user.id == window.temp_user_id
        })
        if (incomingMessages && incomingMessages[incomingMessages.length - 1]) {
            setHasNewMessage(incomingMessages[incomingMessages.length - 1].status == 'New');
        }
        scrollToBottom();
    }, [messageList])

    const toggleWindow = () => {
        setShow(!show);
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
    }

    const handleNewMessage = (e) => {
        setNewMessage(e.target.value);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            sendMessage(newMessage);
        }
    }

    const sendMessage = (answer, stripHtml = true) => {
        // var answer = newMessage;
        setNewMessage('');

        if (stripHtml == true) {
            answer = strip(answer).trim();
        }

        if (answer != '') {

            var mode = "/chat/send";
            mode = mode.replace("0", "");
            axios.post(constants.LocalUrl + mode, {
                msg: answer,
                from: myuser.id,
                to: props.to_user_id,
                room_id: props.room_id,
                channel_id: "videochat." + props.room_id,
                broadcast: true
            }).then((response) => {
                // liveOn.track('Networking','Chat message sent');
                setMessageList((messageList) => [...messageList, response.data]);
            });
        }
    }

    const sendFileMessage = (fileMessage) => {
        sendMessage(fileMessage, false);
    }

    const strip = (html) => {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    const markAllRead = () => {
        // if (hasNewMessage) {
        /*axios.post(constants.LocalUrl + '/data/chat_mark_user_conversation_messages_read' , {
            from: props.user_id,
        }).then((response) => {
            // liveOn.track('Networking','Chat message sent');
            setMessageList(response.data);
        });*/
        // }
    }

    const notify = () => {
        // if (document.getElementById('chatNotification') != null) {
        //     document.getElementById('chatNotification').play()
        // }
        console.warn('NEW MESSAGE!');
    }

    function get_date(timestamp) {
        var start = new Date(timestamp * 1000);
        var start_time = ('0' + start.getHours()).slice(-2) + ':' + ('0' + start.getMinutes()).slice(-2);
        return start_time;
    }

    if (myuser)
        return (
            <div className="videochat_wrapper">
                {/*<div>{"videochat."+props.room_id}</div>*/}
                <div className={(show ? 'chat-window' : 'chat-window minimized') + (hasNewMessage ? ' new-badge' : '')}
                     data-status={hasNewMessage ? 'New' : 'Opened'}
                >
                    {/*<div className="chat-window-header">*/}
                    {/*    <div className="chat-window-recipient">*/}
                    {/*        <div className="chat-window-recipient-img">*/}
                    {/*            /!*<img src={props.conversation.other_user ? props.conversation.other_user.user_img : ""}*!/*/}
                    {/*            /!*     className="face" alt="L"/>*!/*/}
                    {/*            <span className="person-status  person-offline "></span>*/}
                    {/*        </div>*/}
                    {/*        <div className="chat-window-recipient-info">*/}
                    {/*            /!*<b>{props.conversation.other_user.name} <i className="">▼</i></b>*!/*/}
                    {/*            <span title=""></span>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="chat-window-tools">*/}
                    {/*        <a className="chat-window-minimize" onClick={toggleWindow}>*/}
                    {/*            <ReactSVG beforeInjection={(svg) => {*/}
                    {/*                svg.classList.add('svg-class-name');*/}
                    {/*            }} src={chat_icons.MinimizeIcon}/>*/}
                    {/*            <ReactSVG beforeInjection={(svg) => {*/}
                    {/*                svg.classList.add('svg-class-name');*/}
                    {/*            }} src={chat_icons.MaximizeIcon}/>*/}
                    {/*        </a>*/}
                    {/*        /!*<a className="chat-window-close" onClick={() => props.closeHandler(props.user_id)}>*!/*/}
                    {/*        <a className="chat-window-close">*/}
                    {/*            <ReactSVG beforeInjection={(svg) => {*/}
                    {/*                svg.classList.add('svg-class-name');*/}
                    {/*                // svg.setAttribute('style', 'width:55px');*/}
                    {/*            }} src={chat_icons.CloseIcon}/>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="chat-window-body dz-clickable" id={"body" + props.user_id}>
                        {
                            messageList.map(message => <>
                                <div key={message.id}
                                     className={myuser.id == message.from_user.id ? 'bubble bg-teal pull-right' : 'bubble bg-teal pull-left'}>
                                    <em>{message.from_user.name} @ {get_date(message.sent_at)}
                                    </em>
                                    <div
                                        className={myuser.id == message.from_user.id ? "d-flex  justify-content-end  align-items-end" : "d-flex  justify-content-start  align-items-end"}>
                                        {myuser.id == message.from_user.id ? <div>
                                                <span dangerouslySetInnerHTML={{__html: message.message}}/>
                                                <img className="face"
                                                     src={message.from_user.user_img}
                                                     style={{marginLeft: "10px"}}
                                                />
                                            </div>
                                            : <div>
                                                <img className="face"
                                                     src={message.from_user.user_img}
                                                     style={{marginRight: "10px"}}
                                                />
                                                <span dangerouslySetInnerHTML={{__html: message.message}}/>
                                            </div>}

                                    </div>
                                </div>
                            </>)
                        }
                        <div className="pull-right" ref={messagesEndRef}/>
                    </div>
                    <div className="chat-window-footer">
                        <input onChange={handleNewMessage}
                            // onKeyPress={() => {
                            //     channel.whisper('typing', {typing: true});
                            // }}
                               onClick={() => {
                                   markAllRead();
                               }}
                               className="chat-window-user-msg" value={newMessage}/>
                        <LiveonChatDropzone forFileUpload={sendFileMessage}
                                            room_id={props.room_id}></LiveonChatDropzone>
                        <a className="chat-window-send-msg" onClick={() => sendMessage(newMessage, true)}>
                            <ReactSVG beforeInjection={(svg) => {
                                svg.classList.add('svg-class-name');
                                // svg.setAttribute('style', 'width:55px');
                            }} src={chat_icons.SendMessageIcon}/>
                        </a>
                    </div>
                    <div className="chat-window-user-menu" style={{display: "none"}}>
                        <div className="text-center mb-2 d-flex justify-content-center">
                            <img
                                 src="https://panorama.liveon.tech/uploads/register/CK1tDZ70v4EE6S8pMaOSS1HxnvYE87EC0nSdKDDh.png"
                                 className="face"/>
                        </div>
                        <div className="clearfix mb-1">
                            user info
                        </div>
                        <div className="text-muted small mt-2 mb-3">

                        </div>
                        <div className="text-center">
                            {/*<a href={"https://panorama.liveon.tech/social/profile/" + props.user_id}*/}
                            {/*   className="btn btn-block btn-info">Προφίλ</a>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    else return <CircularProgress/>;
}
