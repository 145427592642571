import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import * as constants from "../../../common/Constants"
import CTextField from "../../../common/CTextField";
import Editor from "../../../common/Editor";
import {useTranslation} from "react-i18next";
import Divider from "@material-ui/core/Divider";
import ModalWithButton from "../../../../common/ModalWithButton";
import SelectPredefinedEmail from "./SelectPredefinedEmail";
import InvitationPreview from "./InvitationPreview";
import PanelBottomBar from "../../../../common/PanelBottomBar";

export default function NewInvitation(props) {
    const {t, i18n} = useTranslation();

    const [editorContent, setEditorContent] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [formData, setformData] = useState({
        name: "newinvitation",
        approve: 0
    });
    const [formErrors, setFormErrors] = useState(null);

    const [loading, setLoading] = useState(false);

    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            right: '9px',
            top: '0px'
        },
    }));

    const handleChange = (event) => {
        setformData({...formData, [event.target.name]: event.target.checked});
    };

    const onInputchange = (event) => {
        event.persist();
        setformData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const onSubmitForm = () => {
        const loadItems = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(
                constants.LocalUrl + '/data/create_invitation', formData).then(response => {
                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                } else if (response.data.status == 'ok') {
                    setLoading(false);
                    props.triggerdataload(response.data.data);
                    props.triggerdrawerfunc(false);
                }
            });
        }

        // Call the function
        loadItems();
    };

    const handleEditor = (data) => {
        setformData((prevState) => ({
            ...prevState,
            message: data,
        }));
    }

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    const handlerCallback = (content) => {
        setEditorContent(content);
        setformData((prevState) => ({
            ...prevState,
            message: content
        }));
    }

    return (
        <div>

            <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('New Invitation')}
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>

            <Grid item xs={12}>
                <CTextField handler={onInputchange} name='title' label={t('Title')} required error={hasError('title')}
                            helperText={errorMessage('title')}/>
            </Grid>
            <br/>

            <ModalWithButton button_text={t('Load Email Template')} button_type='secondary'>
                <SelectPredefinedEmail email_list='predefined_emails' handlerCallback={(content) => handlerCallback(content)}></SelectPredefinedEmail>
            </ModalWithButton>

            <br/>
            <Grid item xs={12}>
                <Editor handler={handleEditor} value={editorContent??""} label={t("Content")} name="message"/>
            </Grid>

            {
                showPreview
                    ?
                    <Grid item xs={12}>
                        <br/>
                        <InvitationPreview previewData={formData}></InvitationPreview>
                        <br/>
                    </Grid>
                    : null
            }

            <PanelBottomBar close={props.triggerdrawer(false)}  submit={onSubmitForm} switch={showPreview} switchhandler={() => setShowPreview(!showPreview)}/>

            {/*<Grid className="panel_utilities_element" item xs={12}>*/}
            {/*    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)} className={close_button.root}>{t("go_back")}</Button>*/}
            {/*    <FormControlLabel*/}
            {/*        control={<Switch checked={showPreview} onChange={()=>setShowPreview(!showPreview)} />}*/}
            {/*        label={t("Preview")}*/}
            {/*    />*/}
            {/*    <div className="right_part">*/}
            {/*        <Button id="close_button" variant="contained" onClick={onSubmitForm} color="primary"*/}
            {/*                component="span">{t('SAVE')}</Button>*/}
            {/*    </div>*/}
            {/*</Grid>*/}

        </div>
    )

}