import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import axios from 'axios';
import CircularProgress from "@material-ui/core/CircularProgress";
import * as constants from "../../common/Constants";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CSwitchField from "../../common/CSwitchField";
import {useTranslation} from "react-i18next";
import PageProvider from "../../../Page/PageContext";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";
import {ReactSVG} from "react-svg";
import Grid from "@material-ui/core/Grid";
import Loader from "../../../common/Loader";

const styledBy = (property, mapping) => (props) => mapping[props[property]];

const StateButtonGreenVertical = withStyles({
    root: {
        width: '48%',
        background: styledBy('active', {
            default: '#e0dfe0',
            false: '#e0dfe0',
            true: '#39bb0f',
        }),
        borderRadius: 10,
        border: 0,
        color: 'white',
        height: 48,
        padding: '40px 20px',
        marginBottom: 10,
        marginRight: '2%',
        '&:hover': {
            backgroundColor: styledBy('active', {
                default: '#e0dfe0',
                false: '#e0dfe0',
                true: '#39bb0f',
            }),
        },
    },
    label: {
        textTransform: 'capitalize',
        textAlign: "left",
        justifyContent: "left",
        fontSize: '14px',
        color: styledBy('active', {
            default: '#8d8d8d',
            false: '#8d8d8d',
            true: '#ffffff',
        }),
    },
    startIcon: {
        background: "white",
        color: styledBy('active', {
            default: '#e0dfe0',
            false: '#e0dfe0',
            true: '#39bb0f',
        }),
        borderRadius: '50%',
        width: 45,
        height: 45,
        textAlign: "center",
        padding: '10px 12px',
        display: "inline",
        margin: 0,
        position: "relative",
        left: -8
    },
})(Button);

const StateButtonGreenHorizontal = withStyles({
    root: {
        display: "inline",
        width: 130,
        background: styledBy('active', {
            default: '#e0dfe0',
            false: '#e0dfe0',
            true: '#39bb0f',
        }),
        borderRadius: 10,
        border: 0,
        color: 'white',
        height: 120,
        padding: 20,
        marginBottom: 10,
        marginRight: '2%',
        '&:hover': {
            backgroundColor: styledBy('active', {
                default: '#e0dfe0',
                false: '#e0dfe0',
                true: '#39bb0f',
            }),
        },
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        textTransform: 'capitalize',
        textAlign: "center",
        justifyContent: "center",
        fontSize: '14px',
        color: styledBy('active', {
            default: '#8d8d8d',
            false: '#8d8d8d',
            true: '#ffffff',
        }),
    },
    startIcon: {
        background: "white",
        color: styledBy('active', {
            default: '#e0dfe0',
            false: '#e0dfe0',
            true: '#39bb0f',
        }),
        borderRadius: '50%',
        padding: 10,
        width: 45,
        height: 45,
        textAlign: "center",
        display: "inline"
    },
})(Button);

const StateButtonOrangeVertical = withStyles({
    root: {
        width: '48%',
        background: styledBy('active', {
            default: '#e0dfe0',
            false: '#e0dfe0',
            true: '#e48d10',
        }),
        borderRadius: 10,
        border: 0,
        color: 'white',
        height: 48,
        padding: '40px 20px',
        marginBottom: 10,
        marginRight: '2%',
        '&:hover': {
            backgroundColor: styledBy('active', {
                default: '#e0dfe0',
                false: '#e0dfe0',
                true: '#e48d10',
            }),
        },
    },
    label: {
        textTransform: 'capitalize',
        textAlign: "left",
        justifyContent: "left",
        fontSize: '14px',
        color: styledBy('active', {
            default: '#8d8d8d',
            false: '#8d8d8d',
            true: '#ffffff',
        }),
    },
    startIcon: {
        background: "white",
        color: styledBy('active', {
            default: '#e0dfe0',
            false: '#e0dfe0',
            true: '#e48d10',
        }),
        borderRadius: '50%',
        width: 45,
        height: 45,
        textAlign: "center",
        padding: 3,
        display: "inline",
        margin: 0,
        position: "relative",
        left: -8
    },
})(Button);

const StateButtonOrangeHorizontal = withStyles({
    root: {
        display: "inline",
        width: 130,
        background: styledBy('active', {
            default: '#e0dfe0',
            false: '#e0dfe0',
            true: '#e48d10',
        }),
        borderRadius: 10,
        border: 0,
        color: 'white',
        height: 120,
        padding: 20,
        marginBottom: 10,
        marginRight: '2%',
        '&:hover': {
            backgroundColor: styledBy('active', {
                default: '#e0dfe0',
                false: '#e0dfe0',
                true: '#e48d10',
            }),
        },
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
        textTransform: 'capitalize',
        textAlign: "center",
        justifyContent: "center",
        fontSize: '14px',
        color: styledBy('active', {
            default: '#8d8d8d',
            false: '#8d8d8d',
            true: '#ffffff',
        }),
    },
    startIcon: {
        background: "white",
        color: styledBy('active', {
            default: '#e0dfe0',
            false: '#e0dfe0',
            true: '#e48d10',
        }),
        borderRadius: '50%',
        width: 45,
        height: 45,
        textAlign: "center",
        padding: 3,
        display: "inline",
        margin: 0,
        position: "relative"
    },
})(Button);

export default function LiveStageAdmin({orientation}) {

    const {t, i18n} = useTranslation();
    const {setReloadData, settings} = useContext(PageProvider);

    const useStyles = makeStyles({
        root: {
            width: '100%',
            boxShadow: "none"
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        }
    });
    const classes = useStyles();
    const [stageStatus, setStageStatus] = useState(null);
    const [schedules, setSchedules] = useState([]);
    const [activeSchedule, setActiveSchedule] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ui, setUi] = useState(1);
    const [alignment, setAlignment] = React.useState('left');

    useEffect(() => {
        const loadPost = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(constants.LocalUrl + "/data/get_all_schedules");

            // After fetching data stored it in posts state.
            setSchedules(response.data);

            let the_active = response.data.find((session)=>session.started == 1);
            if (the_active != null) {
                setActiveSchedule('session'+the_active.id);
            }

            const response2 = await axios.post(constants.LocalUrl + "/data/get_stage_data", {type:"panel"});
            setStageStatus(`${response2.data[0].status}`);

            // Closed the loading page
            setLoading(false);
        }
        // Call the function
        loadPost();
    }, []);




    const handleSpeakerCardChange = (event) => {
        // event.persist();
        // setformData((prevState) => ({
        //     ...prevState,
        //     [event.target.name]: event.target.checked,
        // }));

        let active = (activeSchedule == event.target.name) ? null : event.target.name;
        setActiveSchedule(active);
        axios.post(constants.LocalUrl + "/data/set_stage_current_session", {session: active}).then((response) => {});
    };

    const handleStatusChange = (event, newValue) => {
        newValue = event.currentTarget.value;
        if (!newValue) {
            newValue = stageStatus;
        }
        let check = true;
        //  if is going live, confirm
        if (stageStatus != 1 && newValue == 1) {
            check = confirm(t('are you sure you want to go live?'));
        }
        //  if is leaving live, confirm
        else if (stageStatus == 1 && newValue != 1 && newValue != 2) {
            check = confirm(t('are you sure you want to stop the live?'));
        }

        //  if clicked after the event button
        if (settings.EVENT_COMPLETED == 0 && newValue == 2) {
            check = confirm(t('are you sure you want to finish the event?'));
        }
        if (check) {
            axios.post(constants.LocalUrl + "/data/set_stage_status", {new_status: newValue}).then((response) => {
                setReloadData(true);
                axios.post(constants.LocalUrl + "/data/get_all_schedules").then((response) => {
                    setSchedules(response.data);
                    let the_active = response.data.find((session)=>session.started == 1);
                    if (the_active != null) {
                        setActiveSchedule('session'+the_active.id);
                    }
                    else setActiveSchedule(null);
                    axios.post(constants.LocalUrl + "/data/set_stage_current_session", {session: the_active}).then((response) => {});
                });
            });
            setStageStatus(newValue);
        }
    };

    if (loading === true || Object.keys(settings).length == 0) {
        return <Loader/>
    }

    return (
        <Card className={classes.root}>
            {/*<CardContent>*/}
                <br/>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {t('live_stage_admin_pre_text')}
                </Typography>
                <br/>
                {
                    orientation == 'vertical'
                        ? <>
                            <StateButtonGreenVertical value="0" active={stageStatus==0} disabled={stageStatus==0 || settings.EVENT_COMPLETED == 1} onClick={handleStatusChange} startIcon={"01"}>
                                {t('BEFORE THE EVENT')}
                            </StateButtonGreenVertical>
                            <StateButtonGreenVertical value="1" active={stageStatus==1} disabled={stageStatus==1 || settings.EVENT_COMPLETED == 1} onClick={handleStatusChange} startIcon={"02"}>
                                {t('GO LIVE')}
                            </StateButtonGreenVertical>
                            <StateButtonOrangeVertical value="3" active={stageStatus==3} disabled={stageStatus==3 || settings.EVENT_COMPLETED == 1} onClick={handleStatusChange} startIcon={
                                <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('fill', (stageStatus==3) ? '#e48d10' : '#e0dfe0'); svg.setAttribute('style', 'width:40px;height:40px;');}} src={constants.LocalUrl + "/images/panel/problem.svg"} />
                            }>
                                {t('PROBLEM DURING LIVE')}
                            </StateButtonOrangeVertical>

                            <hr/>

                            <p>{t('LIVE STAGE ADMIN complete info')}</p>
                            <StateButtonGreenVertical value="2" active={stageStatus==2} disabled={stageStatus==2 || settings.EVENT_STARTED == 0} onClick={handleStatusChange} startIcon={"03"}>
                                {t('AFTER THE EVENT')}
                            </StateButtonGreenVertical>
                            {
                                settings.setup_object.On_Demand
                                    ? <StateButtonGreenVertical value="9" active={stageStatus==9} disabled={stageStatus==9 || settings.EVENT_COMPLETED == 0} onClick={handleStatusChange} startIcon={"04"}>
                                        {t('ON DEMAND')}
                                    </StateButtonGreenVertical>
                                    : null
                            }

                            <br/>
                            <br/>

                        </>
                        : <>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={9}>
                                    <StateButtonGreenHorizontal value="0" disabled={stageStatus==0} onClick={handleStatusChange} startIcon={"01"}>
                                        {t('BEFORE THE EVENT')}
                                    </StateButtonGreenHorizontal>
                                    <StateButtonGreenHorizontal value="1" disabled={stageStatus==1} onClick={handleStatusChange} startIcon={"02"}>
                                        {t('GO LIVE')}
                                    </StateButtonGreenHorizontal>
                                    <StateButtonGreenHorizontal value="2" disabled={stageStatus==2} onClick={handleStatusChange} startIcon={"03"}>
                                        {t('AFTER THE EVENT')}
                                    </StateButtonGreenHorizontal>
                                    {
                                        settings.setup_object.On_Demand
                                            ? <StateButtonGreenHorizontal value="9" disabled={stageStatus==9} onClick={handleStatusChange} startIcon={"04"}>
                                                {t('ON DEMAND')}
                                            </StateButtonGreenHorizontal>
                                            : null
                                    }
                                </Grid>
                                <Grid item>
                                    <StateButtonOrangeHorizontal value="3" disabled={stageStatus==3} onClick={handleStatusChange} startIcon={
                                        <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('fill', (stageStatus==3) ? '#e48d10' : '#e0dfe0'); svg.setAttribute('style', 'width:40px;height:40px;');}} src={constants.LocalUrl + "/images/panel/problem.svg"} />
                                    }>
                                        {t('PROBLEM DURING LIVE')}
                                    </StateButtonOrangeHorizontal>
                                </Grid>
                            </Grid>
                        </>
                }

                {/*<ToggleButtonGroup size="medium" value={stageStatus} exclusive onChange={handleStatusChange} orientation={orientation??'horizontal' }>
                    <ToggleButton value="0">
                        <div>01</div>
                        <div>{t('BEFORE THE EVENT')}</div>
                    </ToggleButton>
                    <ToggleButton value="1">
                        {t('GO LIVE')}
                    </ToggleButton>
                    <ToggleButton value="2">
                        {t('AFTER THE EVENT')}
                    </ToggleButton>
                    <ToggleButton value="3">
                        {t('PROBLEM DURING LIVE')}
                    </ToggleButton>
                    {
                        settings.setup_object.On_Demand
                            ? <ToggleButton value="9">
                                {t('ON DEMAND')}
                            </ToggleButton>
                            : null
                    }
                </ToggleButtonGroup>*/}
                {
                    stageStatus == 1
                        ? <>
                            <br/><br/>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                {t('live_stage_admin_sessions_pre_text')}
                            </Typography>
                            <List component="nav"  style={{width: '100%', maxWidth: "unset", borderRadius: '23px'}}>
                                {schedules.map((schedule) => (
                                    <CSwitchField key={"sch"+schedule.id} label={t("Activate")+' '+schedule.title} handler={handleSpeakerCardChange} name={"session"+schedule.id} disabled={stageStatus != 1} state={activeSchedule == "session"+schedule.id} />
                                ))}
                            </List>
                        </>
                        : null
                }
            {/*</CardContent>*/}
            {/*<CardActions>*/}
            {/*    <Button size="small">Learn More</Button>*/}
            {/*</CardActions>*/}
        </Card>
    );

}

