import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as constants from "../../../common/Constants";
import {useTranslation} from "react-i18next";
import {Loader} from "semantic-ui-react";
import {ListItemIcon, ListItemSecondaryAction} from "@material-ui/core";
import InboxIcon from '@material-ui/icons/Inbox';
import CTabsAutoScroll from "../../../../common/CTabsAutoScroll";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {ReactSVG} from "react-svg";
import PageProvider from "../../../../Page/PageContext";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const flags = {el: "/app/greece-flag-round.png", en: "/app/united-kingdom-flag-round.png"}

export default function SelectPredefinedEmail(props) {

    const {setCloseModalWithButton} = useContext(PageProvider);
    const {t, i18n} = useTranslation();
    const [emails, setEmails] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [drawerstate, setDrawerstate] = useState({
            open: false,
            row: null
        }
    );

    const classes = useStyles();

    const loadPost = async () => {
        setLoading(true);
        loadPostNoLoading();
        setLoading(false);
    }

    const loadPostNoLoading = async () => {
        const response = await axios.post(constants.LocalUrl + "/data/get_predefined_emails", {email_list: props.email_list});
        const response2 = await axios.post(constants.LocalUrl + "/data/get_all_invitations");
        setEmails(response.data);
        setCampaigns(response2.data);
    }

    useEffect(() => {
        // Call the function
        loadPost();
    }, []);

    const toggleChildMenu = (attached, row) => () => {
        setDrawerstate({...drawerstate, open: attached, row: row});
    };

    const handleSelectTemplate = (content, lang) => () => {
        // console.log("handleSelectTemplate1")
        props.handlerCallback(content);
        // console.log("handleSelectTemplate")
        setCloseModalWithButton(true)
        //  not good, do it with react
        // jQuery('div[aria-labelledby="simple-modal-title"]  svg').click();
    };


    function listItem(row, lang) {
        return (
            <ListItem button key={lang + row.id} onClick={handleSelectTemplate(row.content[lang], lang)}>
                {/*<ListItemIcon>*/}
                {/*    <InboxIcon/>*/}
                {/*</ListItemIcon>*/}
                <ListItemText primary={row.subject[lang]}/>
                {/*<ListItemSecondaryAction>*/}
                {/*    <img src={flags[lang]} alt=""/>*/}
                {/*</ListItemSecondaryAction>*/}
            </ListItem>
        )
    }

    if (loading) {
        return <Loader></Loader>;
    }

    const tabs = {
        titles: [ <><img src={flags['el']} alt=""/>&nbsp;{t('TEMPLATES')}</> , <><img src={flags['en']} alt=""/>&nbsp;{t('TEMPLATES')}</>, <>{t('PREVIOUS CAMPAIGNS')}</>],
        content: [
            <List component="nav" >
                {emails.map((row,index) => (
                    <div key={"ii"+index}>
                        {listItem(row, 'el')}
                    </div>
                ))}
            </List>,
            <List component="nav" >
                {emails.map((row,index) => (
                    <div key={"io"+index}>
                        {listItem(row, 'en')}
                    </div>
                ))}
            </List>,
            <List component="nav" >
                {campaigns.map((row,index) => (
                    <div key={"in"+index}>
                        <ListItem button key={row.id} onClick={handleSelectTemplate(row.message, null)}>
                            <ListItemText primary={row.title}/>
                        </ListItem>
                    </div>
                ))}
            </List>
        ]
    };

    return (
        <div>
            <CTabsAutoScroll titles={tabs.titles} contents={tabs.content}></CTabsAutoScroll>
        </div>
    );
}