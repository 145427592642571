import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ControlPanel from "./index";
import Wizard from "../Wizard";
import React, {useContext, useEffect} from "react";
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from "@material-ui/icons/Close";
import PageProvider from "../Page/PageContext";
import * as constants from "./common/Constants";

export default function SettingsPanel(props){

    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #fff',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 0, 2),
            width: '1200px',
            height: '800px',
        },
    }));

    const useStyles_mobile = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #fff',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 0, 3),
            width: '95%',
            height: '800px',
        },
    }));



    const [open, setOpen] = React.useState(false);
    const [tabSelection, setTabSelection] = React.useState(false);
    const {settings,setReloadData,settingsWindow} = useContext(PageProvider);
    // console.log("wizard_run",settings.wizard_run)

    useEffect(() => {
        setOpen(settingsWindow.activate)
        setTabSelection(settingsWindow.tab?settingsWindow.tab:0)
    },[settingsWindow])

    useEffect(() => {
        if(settings.wizard_run == 1){
            setOpen(true);
        }
    },[settings])

    const handleOpen = (e) => {
        e.preventDefault();
        setOpen(true);
        setTabSelection(0)
    };

    const handleClose = (event,reason) => {
        if (reason && (reason == "backdropClick" || "escapeKeyDown")){
            return;
        }else{
            if(settings.wizard_run == true){
                window.axios.post(constants.LocalUrl + '/data/update_wizard_state',{value:0}).then((response) => {
                    if(response.data.status == "ok"){
                        // notify();
                        document.location.href="/";
                    }
                })
            }
            setOpen(false);
        }
    };

    const classes = props.type=="horizontal"?useStyles_mobile():useStyles();

    return (
        <div>
            <div className={props.classes} onClick={handleOpen} style={{cursor:"pointer"}}>
                {props.children}
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                // disableBackdropClick
                // disableEscapeKeyDown
            >

                <Fade in={open}>
                    <div className={classes.paper}>
                        {/*<Grid container spacing={3}>*/}
                        {/*    <Grid item xs={12}>*/}

                            {/*</Grid>*/}
                        {/*</Grid>*/}
                        {settings.wizard_run == true?<Wizard handleClose={handleClose} type={props.type}/>:<ControlPanel tabSelection={tabSelection} handleClose={handleClose} type={props.type}/>}

                    </div>
                </Fade>
            </Modal>
        </div>
    )
}