import React, {useContext, useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FileUploadComponent from "../../../Panel/common/FileUploadComponent";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as constants from "../../../Panel/common/Constants"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import NewPageLink from "./components/PageLinks/NewPageLink"
import EditPageLink from "./components/PageLinks/EditPageLink"
import WizardContext from "../../WizardContext"
import Pages from './components/Pages'
import DrawerWindow from "../../../Panel/common/DrawerWindow"
import CTextField from "../../../Panel/common/CTextField";
import CSwitchField from "../../../Panel/common/CSwitchField";
import Editor from '../../../Panel/common/Editor';
import {toast} from "react-toastify";
import DraggableSimpleList from "../../../common/DraggableSimpleList";
import PageProvider from "../../../Page/PageContext";
import {useTranslation} from "react-i18next";
import {ChromePicker} from 'react-color';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

export default function SwitchesGroup() {

  const {saveSettings,setSaveSettings} = useContext(WizardContext);
  const {settings,setReloadData} = useContext(PageProvider);
  const {t, i18n} = useTranslation();
  const [state, setState] = useState({});
  const [links, setLinks] = useState([]);
  const [commonPages, setCommonPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fieldsState,setFieldsState] = useState(null);
  const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
  const [stateLocale, setStateLocale] = useState({});
  const [drawerstate, setDrawerstate] = useState({
        open: false,
        open_cat: false,
        id: null,
        id_cat: null,
      }
  );
  const [drawerstatePage, setDrawerstatePage] = useState({
        open: false,
        open_cat: false,
        id: null,
        id_cat: null,
      }
  );


  useEffect(() => {
    setLocaleData(state);
  },[localeLanguage])

  const setLocaleData = (data) => {
    let localeData = {};
    Object.keys(data).map(value => {

      if(value == "footer_contact_text" || value == "about_text_homepage" || value == "about_text_title"){
        // console.log("a",value,data[value])
        localeData[value] = data[value][localeLanguage]?data[value][localeLanguage]:" ";
        // console.log("aa",value,localeData[value],data[value],data[value][localeLanguage],localeLanguage)
      }
      else{
        // console.log("d",value,data[value])
        localeData[value] = data[value];
      }
    })

    setStateLocale(localeData)
    // console.log("localeData",localeData,localeLanguage)
  }

  const notify = () => toast.success(t('Succesfully saved'), {
    position: "top-right",
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  // const {settings} = useContext(PageProvider);

  const loadCommonPages = async () => {
    await axios.post(constants.LocalUrl + '/data/common_pages_get').then(response => {
      // After fetching data stored it in posts state.
      // console.log("////common_pages");
      // console.log(response.data);

      setCommonPages(response.data.data);

      setLoading(false);
    });
  }

  useEffect(() => {
    const loadItems = async () => {

      // Till the data is fetch using API
      // the Loading page will show.
      setLoading(true);

      // Await make wait until that
      // promise settles and return its reult

      await axios.post(constants.LocalUrl + '/data/get_settings').then(response => {
        // After fetching data stored it in posts state.
        // console.log("////");
        // console.log(response.data);

        setState(response.data);
        setLocaleData(response.data)
        setLoading(false);
      });

      axios.post(constants.LocalUrl + '/data/footer_links_get').then(response => {
        setLinks(response.data);
      });


    }
    loadCommonPages();
    // Call the function
    loadItems();
  }, []);

  const handleSettingsAbout = (data) => {

    setState((prevState) => ({
      ...prevState,
      ['about_text_homepage']:{
        ...prevState['about_text_homepage'],
        [localeLanguage]:data,
      }
    }));
    // setState((prevState) => ({
    //   ...prevState,
    //   about_text_homepage: data,
    // }));

    // setState((prevState) => ({
    //   ...prevState,
    //   about_text_homepagez: data,
    // }));

  }


  const handleSettingsFunc = (data) => {

    setState((prevState) => ({
      ...prevState,
      ['footer_contact_text']:{
        ...prevState['footer_contact_text'],
        [localeLanguage]:data,
      }
    }));
    // setState((prevState) => ({
    //   ...prevState,
    //   ['footer_contact_text']:{
    //     ...prevState['footer_contact_text'],
    //     [localeLanguage]:data,
    //   }
    // }));
    // setStateLocale((prevState) => ({
    //   ...prevState,
    //   footer_contact_text: data,
    // }));
    // setState((prevState) => ({
    //   ...prevState,
    //   footer_contact_textz: data,
    // }));

    // console.log("fff_text")
    // console.log(state)
    // console.log(stateLocale)
    // console.log("fff_text__")

  }

  const handleSettings = (event) => {
    event.persist();

    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

    //change
    // console.log("handleSettings");
    // console.log(event.target.name,event.target.value,state);
    // console.log("handleSettings");
  }

  const [selectedDate, handleDateChange] = useState(new Date());
  const handleTime = (event) => {

    handleDateChange(event)
    setState((prevState) => ({
      ...prevState,
      time: selectedDate,
    }));

    //change
    // console.log("handleSettings");
    // console.log(event.target.name,event.target.value,state);
    // console.log("handleSettings");
  }

  const handleMainColor = (data) => {
    setState((prevState) => ({
      ...prevState,
      font_color: data.hex,
    }));
  }

  const handleSecondaryColor = (data) => {
    setState((prevState) => ({
      ...prevState,
      divider_color: data.hex,
    }));
  }

  const handleChange = (event) => {

    event.persist();

    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));

    let setting_change = {
      [event.target.name]: event.target.checked
    }

  };

  const handleAboutTextTitle = (event) => {

    event.persist();
    setState((prevState) => ({
      ...prevState,
      ['about_text_title']: {
        ...prevState['about_text_title'],
        [localeLanguage]: event.target.value,
      }
    }));

    setStateLocale((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

    // console.log("handleAboutTextTitle",state)

  }

  useEffect(() => {

    if(saveSettings === true){
      let end_state = {...state};
      if(end_state.footer_contact_textz){
        end_state.footer_contact_text = end_state.footer_contact_textz;
      }
      if(end_state.about_text_homepagez){
        end_state.about_text_homepage = end_state.about_text_homepagez;
      }

      delete end_state.EVENT_DATE;
      axios.post(
          constants.LocalUrl + '/data/saveSettings',end_state).then(response => {
        if(response.data.status == "ok"){
          setReloadData(true);
          // notify();
        }
      });
      setSaveSettings(false)
    }

    // saveState(state);
    // console.log(state);

  },[saveSettings])

  // const onSubmitForm = () => {
  //   let end_state = {...state};
  //   if(end_state.footer_contact_textz){
  //     end_state.footer_contact_text = end_state.footer_contact_textz;
  //   }
  //   if(end_state.about_text_homepagez){
  //     end_state.about_text_homepage = end_state.about_text_homepagez;
  //   }
  //
  //     axios.post(
  //         constants.LocalUrl + '/data/saveSettings',end_state).then(response => {
  //           if(response.data.status == "ok"){
  //             setReloadData(true);
  //             notify();
  //           }
  //     });
  // }

  const saveFieldsOrder = (event) => {

    const response = axios.post(
        constants.LocalUrl + "/data/saveFooterUrlsOrder",{data:event});

  }

  const toggleChildMenuPage = (attached,page_id) => () => {
    setDrawerstatePage({...drawerstatePage, open: attached,id:page_id});
    // console.log(drawerstate);
  };

  const toggleChildMenuPageFunc = (attached,page_id) => {
    setDrawerstatePage({...drawerstatePage, open: attached,id:page_id});
    // console.log(drawerstate);
  };

  const toggleChildMenu = (attached,sponsor_id) => () => {
    setDrawerstate({...drawerstate, open: attached,id:sponsor_id});
    // console.log(drawerstate);
  };

  const toggleChildMenuFunc = (attached,sponsor_id) =>  {
    setDrawerstate({...drawerstate, open: attached,id:sponsor_id});
  };

  const newPostsHandler = (data) => {
    // console.log("newPostsHandler",data,links);
    setLinks((links) => [data, ...links]);
  };

  const updatePostsHandler = (data) => {
    axios.post(constants.LocalUrl + '/data/footer_links_get').then(response => {
      setLinks(response.data);
    });
  };

  const updatePagesStatusesHandler = (event) => {

    let value = event.target.parentElement.getAttribute("data-value");
    let name = event.target.parentElement.getAttribute("data-name");
    let item_index = event.target.parentElement.getAttribute("data-index");
    let value_name = event.target.parentElement.getAttribute("data-value_name");
    // console.log("updatePagesStatusesHandler",value, name, item_index, value_name)
    axios.post(constants.LocalUrl + '/data/updatePageStatus',).then(response => {

    });

  };


  const updateCommonPages = (data) => {
    // setPosts((posts) => data);
    loadCommonPages();
  };



  let activeLabel =  (value,id,value_name,index) => {
    // console.log("value,id,value_name,index",value,id,value_name,index);
    return(

        <Button className={value == 1?"registration_switch active":"registration_switch inactive"}
                style={value === 1?{fontSize:"0.875rem",borderRadius:"20px",marginRight:"7px",borderColor:"#39BB0F",color:"#39BB0F"}:{fontSize:"0.875rem",borderRadius:"20px",marginRight:"7px"}}
                data-id={id}
                data-index={index}
                data-value={value}
                data-value_name={value_name}
                data-name="in_registration"
            // onClick={handleItemListValues}
                button-value={value} variant="outlined" color="secondary" >{value === 1?t("Active_field"):t("Inactive_field")}</Button>
    )
  };

  if(loading === true || Object.keys(state).length == 0 || Object.keys(commonPages).length == 0 || Object.keys(stateLocale).length == 0){
    return <CircularProgress />
  }
// console.log("aaaa",state)
  return (
      <SimpleBar forceVisible="y" autoHide={false} style={{maxHeight:"528px", height:"528px", overflowX: "hidden"}}>
      {/*<div style={{overflowY:'auto',height:'600px',padding:'0 20px 20px 20px',maxWidth:'unset'}}>*/}
    <Grid container spacing={4} style={{padding:'20px',paddingTop:"0",maxWidth:'unset'}}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} className="main_settings_section_title" gutterBottom>
            {t('Main_Site_Colors')}
          </Typography>
          <Divider />
        </Grid>
        {/*<Typography variant="h5" style={{textAlign:'left'}} gutterBottom>*/}
        {/*  {t('Main_Site_Colors')}:*/}
        {/*</Typography>*/}
        <Grid container spacing xs={12}>

          <Grid item xs={6}>
            <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
              {t('Main_color')}:
            </Typography>
            <ChromePicker color={state.font_color} onChange={handleMainColor} disableAlpha />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
              {t('Secondary_color')}:
            </Typography>
            <ChromePicker color={state.divider_color} onChange={handleSecondaryColor} disableAlpha/>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography className="main_settings_title" variant="h3" style={{textAlign:'left',marginTop:"20px"}} className="main_settings_section_title" gutterBottom>
          {t('Settings Homepage')}
        </Typography>
        <Divider />
      </Grid>
      {/*{changeLanguage()}*/}
      <Grid item xs={6} className="logo_images">
        <Typography alignLeft variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px", marginTop:0}} gutterBottom>
          {t('Homepage intro image')}
          <span className="image_text_description">{t('upload_dimensions', {text: '1920px * 790px'})}</span>
        </Typography>
        <FileUploadComponent id="2" image={state && state.lobby_bg?state.lobby_bg:''} extra={{action:"/data/addSettingsMainBanner"}}
                             accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                             maxWidth={50} maxHeight={80}/>
      </Grid>

      <Grid item xs={12}>
        <fieldset disabled={true}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
            {t('Settings Show in main banner')}:
          </Typography>
          <Divider />
        </Grid>

        <Paper style={{overflowY:'auto',height:'auto',padding:'20px', borderRadius:'23px'}}>
          <List component="nav"  style={{width:'100%',maxWidth:"unset", borderRadius:'23px'}} fullwidth>

            <Divider light />
            <ListItem button fullwidth>
            <CSwitchField label={t("Settings Event name")} state={state.event_name_mainbanner} handler={handleChange} name="event_name_mainbanner"/>
            </ListItem>
            <Divider light />
            <ListItem button fullwidth>
              <CSwitchField label={t("Settings Event date")} state={state.date_mainbanner} handler={handleChange} name="date_mainbanner"/>
            </ListItem>
            <Divider light />
            <ListItem button fullwidth>
              <CSwitchField label={t("Settings Countdown")} state={state.countdown_mainbanner} handler={handleChange} name="countdown_mainbanner"/>
            </ListItem>
            <Divider light />
            <ListItem button fullwidth>
              <CSwitchField label={t("Settings Register button")} state={state.register_mainbanner} handler={handleChange} name="register_mainbanner"/>
            </ListItem>
            <Divider light />
            <ListItem button fullwidth>
              <CSwitchField label={t("Settings Watch")} state={state.watch_mainbanner} handler={handleChange} name="watch_mainbanner"/>
            </ListItem>
            <Divider light />
          </List>
        </Paper>
        <br/>
        </fieldset>
        <Grid item xs={6} style={{marginBottom:"29px"}}>
          <CTextField handler={handleAboutTextTitle} label={t("Settings About text title")}
                      value={stateLocale.about_text_title} name="about_text_title"/>
        </Grid>

        <Editor lang={localeLanguage} handler={handleSettingsAbout} label={t("Settings About text")+':'} value={stateLocale.about_text_homepage} name="about_text_homepage"/>
        <fieldset disabled={true}>
        <Grid item xs={12}>
          <div>
            <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px",marginTop:"20px"}} gutterBottom>
              {t('Settings Access')}:
            </Typography>
            <Paper style={{overflowY:'auto',height:'auto',padding:'20px', borderRadius:'23px'}}>
              <List component="nav"  style={{width:'100%',maxWidth:"unset", borderRadius:'23px'}} fullwidth>

                <Divider light />
                      <ListItem button fullwidth>
                        <CSwitchField label={t("Settings Access Stage")} state={state.menu_stage_visible} handler={handleChange} name="menu_stage_visible"/>
                      </ListItem>
                      <Divider light />
                      <ListItem button fullwidth>
                        <CSwitchField label={t("Settings Access Agenda")} state={state.menu_agenda_visible} handler={handleChange} name="menu_agenda_visible"/>
                      </ListItem>
                <Divider light />
                      <ListItem button fullwidth>
                        <CSwitchField label={t("Settings Access Speakers")} state={state.menu_speakers_visible} handler={handleChange} name="menu_speakers_visible"/>
                      </ListItem>
                  <Divider light />
                      <ListItem button fullwidth>
                            <CSwitchField label={t("Settings Access Sponsors")} state={state.menu_sponsors_visible} handler={handleChange} name="menu_sponsors_visible"/>
                      </ListItem>
                  <Divider light />
              </List>
            </Paper>
          </div>
        </Grid>
        </fieldset>
      </Grid>
      <fieldset disabled={true}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left',marginTop:"20px"}} className="main_settings_section_title" gutterBottom>
            {t("Settings Footer")}
          </Typography>
          <Divider />
        </Grid>
        <br/>
        <Grid item xs={12}>
          <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
            {t("Settings Footer logo")}:
            <span className="image_text_description">{t('upload_dimensions', {text: '190px * 150px'})}</span>
          </Typography>
          <Grid item xs={6} className="logo_images">
          <FileUploadComponent image={state && state.settings_footer_logo?state.settings_footer_logo:''} extra={{action:"/data/addSettingsFooterLogo"}}
                               accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                               maxWidth={50} maxHeight={80}
          />
          </Grid>
          <br/><br/>
          <Editor header="h6" lang={localeLanguage} handler={handleSettingsFunc} label={t("Settings Contact text")+':'} value={stateLocale.footer_contact_text} name="footer_contact_text"/>
          <br/><br/>


          <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"13px"}} gutterBottom>
            {t("Settings Page links")}:
          </Typography>
          {/*<Typography variant="h5" style={{textAlign:'left'}} gutterBottom>*/}
          {/*  {t("Settings Page links")}:*/}
          {/*</Typography>*/}
          {/*<ListPageLink OnClick={toggleChildMenu} data={links}/>*/}
          <Button size="medium" onClick={toggleChildMenu(true)} variant="contained" height="50" color="secondary">{t("Settings + New URL")}</Button>

          {links.length == 0 ? <p>{t('No footer links created')}</p> : <DraggableSimpleList object_type="FooterLink" data={links} edit={toggleChildMenu} save={saveFieldsOrder} triggerdataload={updatePostsHandler}/> }
          <br/><br/>
          <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"13px"}} gutterBottom>
            {t("Common_Pages")}:
          </Typography>
          <Button size="medium" onClick={toggleChildMenuPage(true)} variant="contained" height="50" color="secondary">{t("Settings + New Page")}</Button>
          <DraggableSimpleList object_type="Pages" data={commonPages} buttonAction={updatePagesStatusesHandler} edit={toggleChildMenuPage} />

          <br/><br/>
          <Grid item xs={12}>
            <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left',marginTop:"20px"}} className="main_settings_section_title" gutterBottom>
              {t("Settings Social links")}
            </Typography>
            <Divider />
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <CTextField handler={handleSettings} label={t("Settings Social LinkedIn")} value={state.footer_linkedin_social} name="footer_linkedin_social" />
            </Grid>
            <Grid item xs={6}>
              <CTextField handler={handleSettings} label={t("Settings Social Facebook")} value={state.footer_facebook_social} name="footer_facebook_social" />
            </Grid>
            <Grid item xs={6}>
              <CTextField handler={handleSettings} label={t("Settings Social Twitter")} value={state.footer_twitter_social} name="footer_twitter_social" />
            </Grid>
            <Grid item xs={6}>
              <CTextField handler={handleSettings} label={t("Settings Social Instagram")} value={state.footer_instagram_social} name="footer_instagram_social" />
            </Grid>
            <Grid item xs={6}>
            <CTextField handler={handleSettings} label={t("Settings Social YouTube")} value={state.footer_youtube_social} name="footer_youtube_social" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </fieldset>

      {/*<Grid className="panel_utilities_element basicinfo" style={{justifyContent:"flex-end"}} item xs={12}>*/}

      {/*  <div className="right_part">*/}
      {/*    {changeLanguage()}*/}
      {/*    <Button id="close_button" variant="contained" color="primary" onClick={onSubmitForm} component="span">{t('SAVE')}</Button>*/}
      {/*  </div>*/}
      {/*</Grid>*/}
      </Grid>

      <DrawerWindow open={drawerstate.open}>
        {drawerstate.id?<EditPageLink link_id={drawerstate.id} posts={links} triggerdrawer={toggleChildMenu} triggerdrawerfunc={toggleChildMenuFunc} triggerdataload={updatePostsHandler} />:<NewPageLink posts={links} triggerdrawer={toggleChildMenu} triggerdrawerfunc={toggleChildMenuFunc} triggerdataload={newPostsHandler}/>}
      </DrawerWindow>
      <DrawerWindow open={drawerstatePage.open}>
        <Pages posts={commonPages} page_id={drawerstatePage.id} triggerdrawer={toggleChildMenuPage} triggerdrawerfunc={toggleChildMenuPageFunc} triggerdataload={updateCommonPages}/>
      </DrawerWindow>
      </SimpleBar>

  );
}
