import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import * as constants from "../../../common/Constants"
import CTextField from "../../../common/CTextField";
import Editor from "../../../common/Editor";
import {useTranslation} from "react-i18next";
import Divider from "@material-ui/core/Divider";
import ModalWithButton from "../../../../common/ModalWithButton";
import SelectPredefinedEmail from "./SelectPredefinedEmail";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import InvitationPreview from "./InvitationPreview";
import PageProvider from "../../../../Page/PageContext";

export default function LoadedInvitation(props) {

    const {t, i18n} = useTranslation();
    const [editorContent, setEditorContent] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [formData, setformData] = useState({
        name: "loadedinvitation", approve: 0
    });
    const [formErrors, setFormErrors] = useState(null);

    const [loading, setLoading] = useState(false);

    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute', right: '9px', top: '0px'
        },
    }));

    const handleChange = (event) => {
        setformData({...formData, [event.target.name]: event.target.checked});
    };

    const onInputchange = (event) => {
        event.persist();
        setformData((prevState) => ({
            ...prevState, [event.target.name]: event.target.value,
        }));
    };

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ?
            <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    const handleEditor = (data) => {
        setformData((prevState) => ({
            ...prevState, message: data,
        }));
    }

    const onSubmitForm = () => {
        const loadItems = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            let updateData = formData;
            updateData.id = props.props;

            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(constants.LocalUrl + '/data/update_invitation', updateData).then(response => {
                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                } else if (response.data.status == 'ok') {
                    setLoading(false);

                    props.triggerdataload(response.data.data);
                    props.triggerdrawerfunc(false);
                }
            });
        }

        // Call the function
        loadItems();
    };

    useEffect(() => {
        const loadPost = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            const wert = await axios.post(constants.LocalUrl + '/data/get_invitation_data', {id: props.props}).then(response => {

                // After fetching data stored it in posts state.
                // console.log(response.data);

                setformData(response.data);
                setEditorContent(response.data.message);
                // setCopies({ copies: response.data.copies });
                // console.log(response.data['copies']);
                // console.log(copies);

                // console.log(response.data);
                // console.log("|||//||");
                // console.log(response.data[0]);
                // Closed the loading page
                setLoading(false);
            });


            // Closed the loading page
            setLoading(false);
        }

        // Call the function
        loadPost();
    }, []);


    if (Object.keys(formData).length === 2) {
        return <Loader/>;
    }


    const handlerCallback = (content) => {
        setEditorContent(content);
        setformData((prevState) => ({
            ...prevState, message: content,
        }));

    }

    return (
        <div>
            <Typography variant="h3" style={{textAlign: 'center'}} gutterBottom>
                {t('Edit Invitation')}
            </Typography>
            <Divider style={{backgroundColor: "#39BB0F", marginBottom: "15px"}}/>

            <Grid item xs={12}>
                <CTextField handler={onInputchange} name='title' value={formData.title} label={t('Title')} required
                            error={hasError('title')} helperText={errorMessage('title')}/>
            </Grid>
            <br/>

            <ModalWithButton button_text={t('Load Email Template')} button_type='secondary'>
                <SelectPredefinedEmail email_list='predefined_emails'
                                       handlerCallback={(content) => handlerCallback(content)}></SelectPredefinedEmail>
            </ModalWithButton>

            <br/>
            <Grid item xs={12}>
                <Editor handler={handleEditor} value={editorContent??""} label={t("Content")} name="message"/>
            </Grid>

            {showPreview ? <Grid item xs={12}>
                <br/>
                <InvitationPreview previewData={formData}></InvitationPreview>
                <br/>
            </Grid> : null}

            <PanelBottomBar close={props.triggerdrawer(false)}  submit={onSubmitForm} switch={showPreview} switchhandler={() => setShowPreview(!showPreview)}/>

            {/*<Grid className="panel_utilities_element" item xs={12}>*/}
            {/*    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)}*/}
            {/*            className={close_button.root}>{t("go_back")}</Button>*/}
            {/*    <FormControlLabel*/}
            {/*        control={<Switch checked={showPreview} onChange={() => setShowPreview(!showPreview)}/>}*/}
            {/*        label={t("Preview")}*/}
            {/*    />*/}
            {/*    <div className="right_part">*/}
            {/*        <Button id="close_button" variant="contained" onClick={onSubmitForm} color="primary"*/}
            {/*                component="span">{t('SAVE')}</Button>*/}
            {/*    </div>*/}
            {/*</Grid>*/}
        </div>
    )

}