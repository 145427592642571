import React, {createRef, useContext, useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Repeatable from "../../common/RepeatableTable";
import * as constants from "../../common/Constants"
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {makeStyles} from '@material-ui/core/styles';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Loader from "../../../common/Loader";
import PanelBottomBar from "../../../common/PanelBottomBar";
import FileUploadComponentTranslatable from "../../common/FileUploadComponentTranslatable";
import DateFnsUtils from "@date-io/date-fns";
import {
    datepickerCancelLabelLocales,
    datepickerClearLabelLocales,
    datepickerLocales
} from "../../../common/Language/DatepickerLocales";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import PageProvider from "../../../Page/PageContext";
import AutoCompleteField from "../../../common/AutoCompleteField";
import {InputTextHandler} from "../../common/InputTextHandler";
import HelpIcon from "@material-ui/icons/Help";
import HelpDrawer from "../../common/HelpDrawer";

const useStyles = makeStyles(theme => ({
    popupIndicator: {
        IconComponent: {ExpandMoreIcon},
        backgroundColor: "red",
        color: "red",
        background: "red",
    }
}));

const useedStyles = makeStyles({
    root: {
        color: "#39BB0F",
        "&$checked": {
            color: "#39BB0F"
        }
    }
});

export default function Stage() {

    const {settings, setSettingsWindow} = useContext(PageProvider);
    const {t, i18n} = useTranslation();
    const [zones, setZones] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [reloadStage, setReloadStage] = useState(false);
    const [stage, setStage] = useState([]);
    const [users, setUsers] = useState([]);
    const [copies, setCopies] = useState(0);
    const [loading, setLoading] = useState(0);
    const [localLanguage, setlocalLanguage] = useState(i18n.language);
    const [stageLocale, setStageLocale] = useState([]);
    const classes = useStyles();
    const checkboxes_style = useedStyles();
    const helpDrawerRef = createRef();
    const {handleEndDateTimePicker,onTextchange,handleDraggableListItemInState,handleDraggableOrder,handleEditor,handleStartDateTimePicker} = InputTextHandler({state:stage,setState:setStage,lang:localLanguage});

    const onHostsChange = (event, values) => {
        handleDraggableListItemInState(event, values, 'hosts')
    }
    const handleHostsOrder = (event) => {
        handleDraggableOrder(event, 'hosts')
    }

    const onSponsorsChange = (event, values) => {
        handleDraggableListItemInState(event, values, 'sponsors')
    }
    const handleSponsorsOrder = (event) => {
        handleDraggableOrder(event, 'sponsors')
    }

    useEffect(() => {
        setLocaleData(stage);
    }, [localLanguage])

    const repeat = (index) => (
        <Grid container direction="column" spacing={4} xs={12}>
            <Grid item xs={3} style={{fontWeight: '600'}}>
                <TextareaAutosize list="datalist" data-name="item.email" minRows={3}
                                  placeholder={t('textarea_min_rows', {rows: 3})}/>
            </Grid>
            <Grid item xs={4} style={{fontWeight: '600'}}>
                <TextareaAutosize list="datalist" data-name="item.lang_from" minRows={3}
                                  placeholder={t('textarea_min_rows', {rows: 3})}/>
            </Grid>
            <Grid item xs={3} style={{fontWeight: '600'}}>
                <TextareaAutosize list="datalist" data-name="item.lang_to" minRows={3}
                                  placeholder={t('textarea_min_rows', {rows: 3})}/>
            </Grid>
        </Grid>
    )

    const onAdd = (index) => {
        // console.log('added replicant', index);
        setCopies(copies + 1);
    }

    const onRemove = (index) => {
        // console.log('removed replicant', index);
        setCopies(copies - 1);
    }

    useEffect(() => {

        if (reloadStage === true) {
            // console.log("reloadStage", reloadStage)
            window.axios.post(constants.LocalUrl + '/data/get_stage_data', {type: "panel"}).then((response) => {
                // console.log("reloadStage_response", response.data[0])
                let stage_copy = {...stage}
                stage_copy.logo = response.data[0].logo
                stage_copy.postevent_image = response.data[0].postevent_image
                stage_copy.eventerror_image = response.data[0].eventerror_image
                // console.log("stage_copy", stage_copy, stage, response.data[0].logo)
                setStage(stage_copy);
                setLocaleData(stage_copy);
            })
            setReloadStage(false)
        } else {

            window.axios.post(constants.LocalUrl + '/data/get_all_sponsors').then((response) => {
                setSponsors(response.data);
            })

            loadStageData()

            window.axios.post(constants.LocalUrl + '/data/get_all_users_obj').then((response) => {

                setUsers(response.data);

            })
        }

    }, [reloadStage])

    let message = () => {
        return(
            <div>  {t('please_create_at_least_one_sponsor_profile')}
                <span className="set_pointer url_alike" onClick={()=>setSettingsWindow({activate:true,tab:4})}> <b>{t("Sponsor")}</b>
                                                            </span> {t('profile')}
            </div>
        )
    }

    function loadStageData() {
        window.axios.post(constants.LocalUrl + '/data/get_stage_data', {type: "panel"}).then((response) => {

            setStage(response.data[0]);
            setLoading(false);
            setLocaleData(response.data[0]);
            // console.log(response.data[0])

        })
    }

    const notify_saved = () => toast.success(t('Succesfully saved!'), {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    function onSubmitForm() {
        // this.setState({ loading: true});
        window.axios.post(constants.LocalUrl + '/data/save_stage', {stage: stage}).then((response) => {
            // this.setState({ zones: response.data});
            loadStageData();
            notify_saved();
        })
        // this.setState({ loading: false});
    }

    const handleChange = (event) => {
        event.persist();
        setStage((prevState) => ({
            // stage: {                   // object that we want to update
            ...prevState,    // keep all other key-value pairs
            [event.target.name]: event.target.checked ? 1 : 0      // update the value of specific key
            // }
        }))
        setStageLocale((prevState) => ({
            // stageLocale: {
            ...prevState,
            [event.target.name]: event.target.checked ? 1 : 0
            // }
        }));
        // console.log(stage)
    };



    const onInputchange = (event) => {

        event.persist();

        if (typeof stage[event.target.name] === 'object' && stage[event.target.name] !== null && event.target.name != 'zoom_datetime' && event.target.name != 'zoom_duration' && event.target.name != 'zoom_type') {
            // console.log('object')

            setStage((prevState) => ({
                ...prevState,
                [event.target.name]: {
                    ...prevState[event.target.name],
                    [localLanguage]: event.target.value,
                }
            }));
        } else {
            // console.log('nope')
            setStage((prevState) => ({
                // stage: {
                ...prevState,
                [event.target.name]: event.target.value,
                // }
            }));

        }
        setStageLocale((prevState) => ({
            // stageLocale: {
            ...prevState,
            [event.target.name]: event.target.value,
            // }
        }));


        // console.log(this.state.stage)
    };

    const handleStartDate = (event) => {

        // let date = new Date(state.EVENT_DATE);
        // date.setHours(event.getHours(), event.getMinutes(), 0, 0);


        setStage((prevState) => ({
            ...prevState,
            zoom_datetime: event.getTime(),
        }));

        setStageLocale((prevState) => ({
            ...prevState,
            zoom_datetime: event,
        }));
        // console.log("handleStartDate",state)
    }

    const onZoomRedirectSelectchange = (event) => {
        if (event.target.value == 'default') {
            setStage((prevState) => ({
                // stage: {
                ...prevState,
                ['zoom_redirect_url_select']: event.target.value,
                ['zoom_redirect_url']: '',
                // }
            }));
            setStageLocale((prevState) => ({
                ...prevState,
                ['zoom_redirect_url_select']: event.target.value,
                ['zoom_redirect_url']: '',
            }));
        }
        else if (event.target.value == 'home') {
            setStageLocale((prevState) => ({
                ...prevState,
                ['zoom_redirect_url_select']: event.target.value,
                ['zoom_redirect_url']: '/',
            }));
            setStage((prevState) => ({
                ...prevState,
                ['zoom_redirect_url_select']: event.target.value,
                ['zoom_redirect_url']: '/',
            }));
        }
        else if (event.target.value == 'other') {
            setStage((prevState) => ({
                ...prevState,
                ['zoom_redirect_url_select']: event.target.value,
                ['zoom_redirect_url']: '',
            }));
            setStageLocale((prevState) => ({
                ...prevState,
                ['zoom_redirect_url_select']: event.target.value,
                ['zoom_redirect_url']: '',
            }));
        }
        // console.log(this.state.stage)
    };

    const onStreamCodeChange = (event) => {
        event.persist();
        let temp = {...stage};
        temp[event.target.getAttribute('data-scname')][localLanguage][event.target.getAttribute('data-scindex')] = {
            ...temp[event.target.getAttribute('data-scname')][localLanguage][event.target.getAttribute('data-scindex')],    // keep all other key-value pairs
            [event.target.getAttribute('data-scname2')]: event.target.value       // update the value of specific key
        }

        setStage(temp);
        setLocaleData(temp)
        // console.log("stage", stage)
    };



    const setLocaleData = (data) => {
        let localeData = {};
        Object.keys(data).map(value => {

            if (typeof data[value] === 'object' && data[value] !== null && value != "extras" && value != "draggable_sponsors" && value != "sponsors"
                && value != 'zoom_datetime' && value != 'zoom_duration' && value != 'zoom_type' && value != 'hosts' && value != 'addhosts') {
                // console.log("a", value, data[value])
                localeData[value] = data[value][localLanguage] ? data[value][localLanguage] : "";
            } else {
                // setSponsorLocale([value]:data[value])
                // console.log("b", value, data[value])
                localeData[value] = data[value] ? data[value] : "";
            }

            if (value == 'zoom_type' && !['meetings','webinars'].includes(localeData[value])) {
                localeData.zoom_type = 'meetings';
            }
            // if (value == 'zoom_datetime' && localeData[value] == '') {
            //     localeData.zoom_datetime = new Date();
            // }
            if (value == 'zoom_duration' && localeData[value] == '') {
                localeData.zoom_duration = 30;
            }
        })

        if (Object.values(localeData).length > 0) {
            if (localeData.extras.zoom_redirect_url == '') {
                localeData.zoom_redirect_url_select = 'default';
            }
            else if (localeData.extras.zoom_redirect_url == '/') {
                localeData.zoom_redirect_url_select = 'home';
            }
            else {
                localeData.zoom_redirect_url_select = 'other';
            }
        }

        // console.log("localeData", localeData, localLanguage)
        setStageLocale(localeData)
    }


    if (
        loading === true
        // || Object.keys(sponsors).length === 0 ||
        || Object.keys(stage).length === 0
        || Object.keys(stageLocale).length === 0
        // || Object.keys(users) === 0
    ) {
        return <Loader/>;
    }

    // console.log("stageLocale", stageLocale)


    const isStageEditable = () => {
        return (stage.status != 0 && stage.status != 3);
    }


    return (
        <SimpleBar forceVisible="y" autoHide={false} className="bottom_bar"
                   style={{maxHeight: "658px", height: "658px", overflowX: "hidden"}}>
            <div style={{height: '658px', padding: '2px'}}>

                {
                    isStageEditable()
                        ? <div className="payer-info"
                               style={{display: 'flex', padding: '23px 36px', backgroundColor: "transparent"}}><img
                            style={{height: '42px', marginRight: '10px'}} src={constants.LocalUrl + "/hand.png"}/>
                            <p style={{fontSize: '12px', lineHeight: '16px', color: '#000000', fontWeight: 500}}>
                                {t('You are not allowed to make changes to the stage if you are not in the preevent status or the event error status.')}
                            </p>
                        </div>
                        : <></>
                }

                <fieldset disabled={isStageEditable()?true:false} style={{paddingBottom: "10px"}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography xs={12} variant="h3" style={{textAlign: 'left'}}
                                        className="main_settings_section_title" gutterBottom>
                                {t('Thumbnails')}
                            </Typography>
                            <Divider/>
                        </Grid>
                        <Grid item xs={4} className="stage_image_upload">
                            <Typography variant="h6"
                                        style={{display: 'block', marginBottom: "15px", marginLeft: "20px"}}
                                        gutterBottom>
                                {t('Pre Event Thumbnail')}:
                                <span
                                    className="image_text_description">{t('upload_dimensions', {text: '1077px * 636px'})}</span>
                            </Typography>
                            <FileUploadComponentTranslatable id="1"
                                                             reloadStage={setReloadStage}
                                                             image={stageLocale && stageLocale.logo ? stageLocale.logo : ''}
                                                             extra={{
                                                                 action: "/data/addStageLogo",
                                                                 'imagefield': 'image',
                                                                 lang: localLanguage
                                                             }}
                                                             accept={constants.acceptedUploadMimeGroups.images}
                                                             maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                                             maxWidth={1077} maxHeight={636}
                            />
                        </Grid>
                        <Grid item xs={4} className="stage_image_upload">
                            <Typography variant="h6"
                                        style={{display: 'block', marginBottom: "15px", marginLeft: "20px"}}
                                        gutterBottom>
                                {t('Post Event Thumbnail')}:
                                <span
                                    className="image_text_description">{t('upload_dimensions', {text: '1077px * 636px'})}</span>
                            </Typography>
                            <FileUploadComponentTranslatable id="1"
                                                             reloadStage={setReloadStage}
                                                             image={stageLocale && stageLocale.postevent_image ? stageLocale.postevent_image : ''}
                                                             extra={{
                                                                 action: "/data/addStageLogo",
                                                                 'imagefield': 'postevent_image'
                                                             }}
                                                             accept={constants.acceptedUploadMimeGroups.images}
                                                             maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                                             maxWidth={1077} maxHeight={636}
                            />
                        </Grid>
                        <Grid item xs={4} className="stage_image_upload">
                            <Typography variant="h6"
                                        style={{display: 'block', marginBottom: "15px", marginLeft: "20px"}}
                                        gutterBottom>
                                {t('Event Error  Thumbnail')}:
                                <span
                                    className="image_text_description">{t('upload_dimensions', {text: '1077px * 636px'})}</span>
                            </Typography>
                            <FileUploadComponentTranslatable id="1"
                                                             reloadStage={setReloadStage}
                                                             image={stageLocale && stageLocale.eventerror_image ? stageLocale.eventerror_image : ''}
                                                             extra={{
                                                                 action: "/data/addStageLogo",
                                                                 'imagefield': 'eventerror_image'
                                                             }}
                                                             accept={constants.acceptedUploadMimeGroups.images}
                                                             maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                                             maxWidth={1077} maxHeight={636}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography xs={12} variant="h3" style={{textAlign: 'left', marginTop: "35px"}}
                                        className="main_settings_section_title" gutterBottom>
                                {t('Stream_messages')}
                            </Typography>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6"
                                        style={{display: 'block', marginBottom: "15px", marginLeft: "20px"}}
                                        gutterBottom>
                                {t('Stream live message')}
                            </Typography>
                            <TextField
                                id="outlined-multiline-flexible"
                                maxRows={4}
                                placeholder={t('textarea_max_rows', {rows: 4})}
                                style={{width: '100%'}}
                                name='msg_live'
                                // label="Stream live message"
                                value={stageLocale.msg_live ? stageLocale.msg_live : ''}
                                onChange={onInputchange}
                                variant="outlined" multiline
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6"
                                        style={{display: 'block', marginBottom: "15px", marginLeft: "20px"}}
                                        gutterBottom>
                                {t('Stream stopped message')}
                            </Typography>
                            <TextField
                                id="outlined-multiline-flexible"
                                maxRows={15}

                                placeholder={t('textarea_max_rows', {rows: 4})}
                                style={{width: '100%'}}
                                name='msg_ended'
                                // label="Stream stopped message"
                                value={stageLocale.msg_ended ? stageLocale.msg_ended : ''}
                                onChange={onInputchange}
                                variant="outlined" multiline
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography xs={12} variant="h3" style={{textAlign: 'left', marginTop: "35px"}}
                                        className="main_settings_section_title" gutterBottom>
                                {t('Stream_codes')}
                            </Typography>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>

                            {stageLocale && Object.values(stageLocale.stream_code).map((data, index) => (
                                <div key={"scode"+index+"_"+data.id}>
                                    <div className="streams_wrapper"
                                         style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
                                        <Grid item xs={6}>
                                            <Typography variant="h6" style={{
                                                display: 'block',
                                                marginBottom: "15px",
                                                marginLeft: "20px"
                                            }} gutterBottom>
                                                {t('Name')}
                                            </Typography>
                                            <TextField
                                                id="outlined-textarea"
                                                maxRows={15}

                                                style={{width: '100%', borderRadius: "20px"}}
                                                value={data.name}
                                                onChange={onStreamCodeChange}
                                                variant="outlined"
                                                inputProps={{
                                                    'data-scname': 'stream_code',
                                                    'data-scindex': index,
                                                    'data-scname2': 'name'
                                                }}
                                            />
                                        </Grid>

                                        <Grid item style={{marginLeft: '20px'}} xs={6}>
                                            <Typography variant="h6" style={{
                                                display: 'block',
                                                marginBottom: "15px",
                                                marginLeft: "20px"
                                            }} gutterBottom>
                                                {t('Stream')}
                                            </Typography>
                                            <TextField
                                                id="outlined-textarea"
                                                maxRows={15}

                                                placeholder="Maximum 4 rows"
                                                // defaultValue=""
                                                style={{width: '100%', borderRadius: "20px"}}
                                                // label="Code"
                                                value={data.code}
                                                onChange={onStreamCodeChange}
                                                variant="outlined" multiline
                                                inputProps={{
                                                    'data-scname': 'stream_code',
                                                    'data-scindex': index,
                                                    'data-scname2': 'code'
                                                }}
                                                disabled={stageLocale.isZoom?true:false}
                                            />
                                        </Grid>


                                        <br/><br/>
                                    </div>
                                    <Divider style={{marginBottom: "20px"}}/>
                                </div>
                            ))}

                        </Grid>

                        <Grid item xs={12}>
                            <Typography xs={12} variant="h3" style={{textAlign: 'left', marginTop: "35px"}}
                                        className="main_settings_section_title" gutterBottom>
                                {t('Preferences')}
                            </Typography>
                            <Divider/>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper style={{overflowY: 'auto', height: 'auto', padding: '20px', borderRadius: '23px'}}>
                                <List component="nav"
                                      style={{width: '100%', maxWidth: "unset", borderRadius: '23px'}}>
                                    {/*<Divider light/>*/}
                                    {/*<ListItem button fullwidth>*/}
                                    {/*    <FormLabel component="legend">{t('Autoplay')}</FormLabel>*/}
                                    {/*    <FormGroup row>*/}

                                    {/*        <FormControlLabel*/}
                                    {/*            control={<Switch checked={stageLocale.autoplay}*/}
                                    {/*                             onChange={handleChange} name="autoplay"/>}*/}
                                    {/*            label=""*/}
                                    {/*        />*/}
                                    {/*    </FormGroup>*/}
                                    {/*</ListItem>*/}

                                    <Divider light/>
                                    <ListItem button>
                                        <FormLabel component="legend">{t('Attendee ask questions')}</FormLabel>
                                        <FormGroup row>

                                            <FormControlLabel
                                                control={<Switch value={stageLocale.has_questions_form}
                                                                 onChange={handleChange} name="has_questions_form"/>}
                                                label=""
                                            />
                                        </FormGroup>
                                    </ListItem>
                                    <Divider light/>
                                    <ListItem button>
                                        <FormLabel component="legend">{t('Zoom powered')}</FormLabel>
                                        <FormGroup row>

                                            <FormControlLabel
                                                control={<Switch id='zoom_powered_toggle' value={stageLocale.isZoom}
                                                                 onChange={handleChange} name="isZoom"/>}
                                                label=""
                                            />
                                        </FormGroup>
                                    </ListItem>
                                    <ListItem style={{padding: 0}}>
                                        <Grid container spacing={0}
                                              style={{display: stageLocale.isZoom ? 'block' : 'none'}}
                                              id='zoom_extended_contents' direction="column">
                                            <Grid item xs={12}>
                                            </Grid>
                                            <Grid item xs={12} style={{marginBottom: "15px", marginTop: "15px"}}
                                                  >

                                                <Typography variant="h6" style={{
                                                    display: 'block',
                                                    marginBottom: "15px",
                                                    marginLeft: "20px"
                                                }} gutterBottom>
                                                    {t('Zoom type')}
                                                </Typography>
                                                <Select
                                                    labelId="zoom-type-label"
                                                    variant="outlined"
                                                    IconComponent={ExpandMoreIcon}
                                                    id="zoom-type-select"
                                                    value={stageLocale.zoom_type}
                                                    onChange={onInputchange}
                                                    name="zoom_type"
                                                >
                                                    <MenuItem value={'meetings'}>{t('zoom_type MEETING')}</MenuItem>
                                                    <MenuItem value={'webinars'}>{t('zoom_type WEBINAR')}</MenuItem>
                                                </Select>
                                            </Grid>

                                            <Grid item xs={12} style={{marginBottom: "15px", marginTop: "15px"}}>
                                                <Typography variant="h6" style={{
                                                    display: 'block',
                                                    marginBottom: "15px",
                                                    marginLeft: "20px"
                                                }} gutterBottom>
                                                    {t('Zoom Redirect URL')}
                                                </Typography>
                                                <Select
                                                    labelId="zoom-type-label"
                                                    variant="outlined"
                                                    IconComponent={ExpandMoreIcon}
                                                    id="zoom-type-select"
                                                    value={stageLocale.zoom_redirect_url_select}
                                                    onChange={onZoomRedirectSelectchange}
                                                    name="zoom_redirect_url_select"
                                                >
                                                    <MenuItem value={'default'}>{t('Zoom Redirect URL default')}</MenuItem>
                                                    <MenuItem value={'home'}>{t('Zoom Redirect URL home')}</MenuItem>
                                                    <MenuItem value={'other'}>{t('Zoom Redirect URL other')}</MenuItem>
                                                </Select>

                                            </Grid>
                                            {
                                                stageLocale.zoom_redirect_url_select == 'other'
                                                    ? <Grid item xs={12} style={{marginBottom: "15px", marginTop: "15px"}}>
                                                        <Typography variant="h6" style={{
                                                            display: 'block',
                                                            marginBottom: "15px",
                                                            marginLeft: "20px"
                                                        }} gutterBottom>
                                                            {t('Zoom Redirect URL Please input a custom url')}
                                                        </Typography>
                                                        <TextField id="filled-basic" label="" variant="outlined"
                                                                   name={'zoom_redirect_url'}
                                                                   value={stageLocale.zoom_redirect_url??stageLocale.extras.zoom_redirect_url}
                                                                   onChange={onInputchange}/>
                                                    </Grid>
                                                    : null
                                            }

                                            <Grid item xs={12} style={{marginBottom: "15px", marginTop: "15px"}}>
                                                <Typography variant="h6" style={{
                                                    display: 'block',
                                                    marginBottom: "15px",
                                                    marginLeft: "20px"
                                                }} gutterBottom>
                                                    {t('Zoom date and time')}
                                                </Typography>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={datepickerLocales[i18n.language]}>
                                                    <DateTimePicker
                                                        id="outlined-textarea"
                                                        inputVariant="outlined"
                                                        ampm={true}
                                                        label=""
                                                        value={stageLocale.zoom_datetime?stageLocale.zoom_datetime:new Date(settings.EVENT_DATE_TIMESTAMP)}
                                                        // required error={hasError('start_date')}
                                                        // helperText={errorMessage('start_date')}
                                                        minDate={new Date(settings.EVENT_DATE_TIMESTAMP)}
                                                        maxDate={settings.EVENT_DATE_TO}
                                                        onChange={handleStartDate}
                                                        onError={console.log}
                                                        format={settings.EVENT_DATETIME_FORMAT_UI}
                                                        cancelLabel={datepickerCancelLabelLocales[i18n.language]}
                                                        clearLabel={datepickerClearLabelLocales[i18n.language]}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                {/*<TextField onChange={onInputchange} style={{borderRadius: '23px'}}*/}
                                                {/*           id="zoom-datetime"*/}
                                                {/*           variant="outlined" type="datetime-local"*/}
                                                {/*           fullWidth*/}
                                                {/*           InputLabelProps={{shrink: stageLocale.zoom_datetime ? true : false}}*/}
                                                {/*           name="zoom_datetime" value={stageLocale.zoom_datetime}/>*/}
                                            </Grid>

                                            <Grid item xs={12} style={{marginBottom: "15px", marginTop: "15px"}}>
                                                <Typography variant="h6" style={{
                                                    display: 'block',
                                                    marginBottom: "15px",
                                                    marginLeft: "20px"
                                                }} gutterBottom>
                                                    {t('Zoom duration')}
                                                </Typography>
                                                <TextField onChange={onInputchange} style={{borderRadius: '23px'}}
                                                           id="zoom-duration"
                                                           variant="outlined" type="number"
                                                           name="zoom_duration"
                                                           value={stageLocale.zoom_duration}/>
                                            </Grid>

                                            <Grid item xs={12} style={{marginBottom: "15px", marginTop: "15px"}}>
                                                <AutoCompleteField
                                                    label={t('Meeting Hosts')}
                                                    placeholder={t("Choose a host...")}
                                                    model={users}
                                                    data={stage.hosts}
                                                    onChange={onHostsChange}
                                                    handleItemsOrder={handleHostsOrder}
                                                    type="single"
                                                />
                                            </Grid>

                                            <br/>
                                            <Grid item xs={12}>
                                                <ListItem button>
                                                    <FormLabel
                                                        component="legend">
                                                        {t('Use custom Zoom API keys')}
                                                        <HelpIcon style={{color:"rgba(0, 0, 0, 0.87)",marginLeft:"8px",cursor:'pointer'}} onClick={()=>helpDrawerRef.current.toggleDrawer(true)}/>
                                                        <HelpDrawer id="custom-zoom-api-keys" ref={helpDrawerRef} />
                                                    </FormLabel>
                                                    <FormGroup row>

                                                        <FormControlLabel
                                                            control={<Switch id='zoom_custom_api'
                                                                             onChange={onInputchange}/>}
                                                            label=""
                                                        />
                                                    </FormGroup>
                                                </ListItem>

                                                <Paper elevation={0} style={{display: 'none'}}
                                                       id='zoom_custom_keys'>
                                                    <Grid container spacing={4} style={{maxWidth: '100%'}}>

                                                        <br/>
                                                        <Grid item xs={12} style={{paddingLeft:0,paddingRight:0,paddingTop:"7.5px",paddingBottom:"7.5px"}}>
                                                            <Typography variant="h6" style={{
                                                                display: 'block',
                                                                marginBottom: "15px",
                                                                marginLeft: "20px"
                                                            }} gutterBottom>
                                                                {t('API KEY')}
                                                            </Typography>
                                                            <TextField id="outlined-textarea" variant="outlined"
                                                                       name="zoom_api_key"/>
                                                        </Grid>
                                                        <Grid item xs={12} style={{paddingLeft:0,paddingRight:0,paddingTop:"7.5px",paddingBottom:"7.5px"}}>
                                                            <Typography variant="h6" style={{
                                                                display: 'block',
                                                                marginBottom: "15px",
                                                                marginLeft: "20px"
                                                            }} gutterBottom>
                                                                {t('API SECRET')}
                                                            </Typography>
                                                            <TextField id="outlined-textarea" variant="outlined"
                                                                       name="zoom_api_secret"/>
                                                        </Grid>
                                                        <Grid item xs={12} style={{paddingLeft:0,paddingRight:0,paddingTop:"7.5px",paddingBottom:"7.5px"}}>
                                                            <Typography variant="h6" style={{
                                                                display: 'block',
                                                                marginBottom: "15px",
                                                                marginLeft: "20px"
                                                            }} gutterBottom>
                                                                {t('API E-mail')}
                                                            </Typography>
                                                            <TextField id="outlined-textarea" variant="outlined"
                                                                       name="zoom_api_user"/>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <br/>
                                            <Grid item xs={12} style={{display: "none"}}>
                                                <Paper style={{
                                                    padding: 8, marginRight: '10px'
                                                }}>
                                                    <FormLabel
                                                        component="legend">{t('Language Interpretation')}</FormLabel>
                                                    <FormGroup row>

                                                        <FormControlLabel
                                                            control={<Switch id='isZoom_interpretation'/>}
                                                            label=""
                                                        />
                                                    </FormGroup>
                                                </Paper>
                                                <br/>
                                                <br/>
                                                <Grid container spacing={4} id='isZoom_interpretation_content'
                                                      item
                                                      xs={12}>

                                                    <Grid item xs={3} style={{fontWeight: '600'}}>
                                                        {t('E-mail')}
                                                    </Grid>
                                                    <Grid item xs={4} style={{fontWeight: '600'}}>
                                                        {t('From Language')}
                                                    </Grid>
                                                    <Grid item xs={3} style={{fontWeight: '600'}}>
                                                        {t('To Language')}
                                                    </Grid>

                                                    <Repeatable
                                                        initialCopies={copies}
                                                        childrenRenderer={repeat}
                                                        onAdd={onAdd}
                                                        onRemove={onRemove}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <Divider light/>
                                </List>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography xs={12} variant="h3" style={{textAlign: 'left', marginTop: "35px"}}
                                        className="main_settings_section_title" gutterBottom>
                                {t('Sponsors_panel')}
                            </Typography>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>

                            {Object.keys(sponsors).length == 0?
                            <Paper className={"draggable_autocomplete_wrapper"} elevation={0} style={{
                            padding: 8,display:'inline-block',marginRight: '10px',width:'100%'}}>
                            <Typography variant="h6" style={{display: 'block',margin:"40px",textAlign:"center"}} gutterBottom>
                                {/*{console.log("props.noDataMessage()",message())}*/}
                                {message()}
                            </Typography>
                            </Paper>:
                            <AutoCompleteField
                                // label={t('Sponsors')}
                                // noDataMessage={}
                                placeholder={t("Choose a sponsor...")}
                                model={sponsors}
                                data={stage.sponsors}
                                onChange={onSponsorsChange}
                                handleItemsOrder={handleSponsorsOrder}
                            />
                            }
                        </Grid>


                    </Grid>


                    {/*<Grid container spacing={3} direction="column">*/}
                    {/*</Grid>*/}
                </fieldset>

                <PanelBottomBar classes={"panel_utilities_element panel_stage"} style={{justifyContent: "flex-end"}}
                                submit={onSubmitForm} language={setlocalLanguage}/>

                {/*<Grid className="panel_utilities_element panel_stage" style={{justifyContent:"flex-end"}} item xs={12}>*/}

                {/*    {loading == true ? <Loader/> : ''}*/}
                {/*    <div className="right_part">*/}
                {/*        /!*{changeLanguage()}*!/*/}
                {/*        <LanguageChangerForPanel onChange={setlocalLanguage}/>*/}
                {/*        <Button id="close_button" onClick={onSubmitForm} variant="contained" color="primary"*/}
                {/*                component="span">{t('SAVE')}</Button>*/}
                {/*    </div>*/}
                {/*</Grid>*/}
            </div>
        </SimpleBar>
    );
    // }
}

// export default Stage;

// $(document).on("click", "#zoom_powered_toggle", function () {
//     $("#zoom_extended_contents").toggle("slow", function () {
//         // Animation complete.
//     });
// });

$(document).on("click", "#isZoom_interpretation", function () {
    $("#isZoom_interpretation_content").toggle("slow", function () {
        // Animation complete.
    });
});

$(document).on("click", "#zoom_custom_api", function () {
    $("#zoom_custom_keys").toggle("slow", function () {
        // Animation complete.
    });
});
