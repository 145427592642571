import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Container, CssBaseline, TextField, Typography} from "@material-ui/core";
import {NavLink, useNavigate} from 'react-router-dom';
import "../../login.css"
import PageProvider from "../Page/PageContext"
import * as constants from "../Panel/common/Constants";
import Logo from "../common/Logo"
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import user from "../../auth/user";


export default function Login(){

    const {t, i18n} = useTranslation();
    const {settings,setReloadData} = useContext(PageProvider);
    const [form,setForm] = useState([]);
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState(null);

    const handleReceiveCallToast = () => toast.success(msg, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notify = () => toast.success(t('Succesfully logged in'), {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const enotify = (string) => toast.warn(string, {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const opener = (event) => {
        // console.log("edo")
        handleReceiveCallToast();
    }

    const handleReceiveCall = (event) => {
        // console.log("Ola kala")
    }

    useEffect(() => {
        // console.log(putVideoCall,"preloadVideoRequest")
        if (settings.user && settings.user.id != 0) {
            // console.log("loadVideoRequest")
            // navigate('/')
            document.location.href="/";
        }
    }, [settings])

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    const handleChange = (event) => {
        event.persist();

        setForm((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        // const formData = new FormData($("#login_form"));
        // const loginCredentials = {
        //     email: formData.get('email'),
        //     password: formData.get('password')
        // }

        setFormErrors(null)
    const loginCredentials = {
        email: form.email,
        password: form.password,
        // _token:$("[name='csrf-token']").attr("content")
    }

        // console.log("asddddd")
    // function authenticatedCallback() {
    //     let {from} = location.state || {from: {pathname: '/'}}
    //     history.replace(from)
    // }

        // console.log("responseee");
        axios.post(constants.LocalUrl + '/panel/login', loginCredentials).then((response) => {


            // console.log("response");
            // console.log(response);

            if(response.status == 200 || response.status == 204){
                user.authenticated("t","t");
                notify();
                setReloadData(true);

            }else{
                // console.log("asddddd")
                enotify();
            }
        }).catch(error => {
            // console.log("error",error)
            enotify(error.response.data.message)
            if (Object.keys(error.response.data.errors).length > 0) {
                setFormErrors(error.response.data.errors);
            }
        })
    }

    return (
        <Container maxWidth={"xs"} className="login_container">

            <CssBaseline/>
            <Box align="center">
                <NavLink className="item" to={constants.LocalUrl + "/"} >
                    <Logo src={settings.logo} alt={t('alt_Logo')} width="100%" height="75px"/>
                </NavLink>
            </Box>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>

                <Box id="login_form" component={"form"} style={{width:"100%"}} onSubmit={handleSubmit}>
                    <Typography variant="h6" style={{textAlign:'left',marginLeft:"20px",marginBottom:"15px",marginTop:"14px"}} gutterBottom>
                        E-mail
                    </Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder=""
                        type="text"
                        style={{ width: '100%' }}
                        name="email"
                        // label=""
                        // value={props.value}
                        // onChange={props.handler}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        error={hasError('email')} helperText={errorMessage('email')}
                    />
                    <Typography variant="h6" style={{textAlign:'left',marginTop:"15px",marginLeft:"20px",marginBottom:"15px"}} gutterBottom>
                        {t('Password')}
                    </Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder=""
                        type="password"
                        style={{ width: '100%'}}
                        name="password"
                        // label=""
                        // value={props.value}
                        // onChange={props.handler}
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={handleChange}
                        fullWidth
                        error={hasError('password')} helperText={errorMessage('password')}
                    />

                    <Box align="center">
                        <br/>
                        <NavLink as="span" className="item" to={constants.LocalUrl + "/app/remember_password"} >
                            <Typography align="center" variant="h6" style={{fontWeight:"300",fontSize:"15px"}} gutterBottom>
                                {t("Forgot_Password")}
                            </Typography>
                        </NavLink>
                        <Button style={{marginBottom:"10px",marginTop:"10px",width:"150px"}} id="close_button" variant="contained" type="submit" color="primary">{t("Login")}</Button>
                        <br/><span className="just_or">{t("or")}</span><br/>
                        <NavLink className="url_button" to={constants.LocalUrl + "/app/register"}>
                        <Button className="login_register_button_wrapper" as="span" variant="outlined" className="item" style={{display:"block",marginTop:"10px",padding:"5px 40px",borderRadius:"20px",borderColor:"rgba(0,0,0,0.87)"}}>
                            <Typography  className="login_register_button" align="center" variant="h6" style={{fontWeight:"500",fontSize:"15px",marginBottom:0}} gutterBottom>
                                {t("Register")}
                            </Typography>
                        </Button>
                        </NavLink>
                    </Box>
                    <br />
                </Box>
            </Box>
        </Container>
    )
}

