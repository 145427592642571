import request from '../../../utils/request'

function get(locale) {
    return request({
        url:    `/data/get_basic_info`,
        method: 'POST',
        data: {lang:locale}
    });
}

function timezones() {

    let timezones = request({
        url:    `/data/get_all_timezones_autocomplete`,
        method: 'POST'
    });

    return timezones;
}

function settings() {
    return request({
        url:    `/data/get_all_timezones_autocomplete`,
        method: 'POST'
    });
}

function create({subject, content}) {
    return request({
        url:    '/message/create',
        method: 'POST',
        data:   {
            subject,
            content
        }
    });
}

const MessageService = {
    get, timezones //, update, delete, etc. ...
}

export default MessageService;