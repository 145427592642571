import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import MyInfo from "./components/MyInfo"
import MyDownloads from "./components/MyDownloads"
import {ReactSVG} from "react-svg";
import * as constants from "../../../Panel/common/Constants";
import {useTranslation} from "react-i18next";


const tabs_icons = {
    DashboardIcon: "/images/panel/dashboard.svg",
    InfoIcon: "/images/panel/info.svg",
    SettingsIcon: "/images/panel/settings.svg",
    SponsorsIcon: "/images/panel/sponsors.svg",
    StageIcon: "/images/panel/stage.svg",
    StatisticsIcon: "/images/panel/statistics.svg",
    UsersIcon: "/images/panel/users.svg",
    InvitationIcon: "/images/panel/invitation.svg",
    ScheduleIcon: "/images/panel/schedule.svg",
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Profile() {
    const {t, i18n} = useTranslation();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tab_headers = [{tab_title: t('MY INFO'), icon: tabs_icons.DashboardIcon}, {
        tab_title: t('MY DOWNLOADS'),
        icon: tabs_icons.InfoIcon
    }]

    return (
        <div style={{width:"100%",backgroundColor:"rgb(250, 250, 250)",height:"100vh"}}>
        <div className="container" id="profile-individual" style={{maxWidth:"1473px",width:"100%"}}>
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}  >


                {tab_headers.map((tab, i) => {
                    return (<Tab key={"tab"+i} icon={<ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name');
                        svg.setAttribute('style', 'width:55px');
                    }} src={constants.LocalUrl + tab.icon}/>} label={tab.tab_title} {...a11yProps(i)} />)
                })}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <MyInfo />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MyDownloads />
            </TabPanel>
            {/*<TabPanel value={value} index={2}>*/}
            {/*    Item Three*/}
            {/*</TabPanel>*/}
        </Box>
        </div>
        </div>
    );
}

