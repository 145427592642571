import React, {useContext, useEffect, createRef} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import BasicInfo from './components/BasicInfo';
// import Dashboard from './tabs/Dashboard';
// import Stage from './tabs/Stage';
// import Statistics from './tabs/Statistics';
import Sponsors from './components/Sponsors';
import Settings from './components/Settings';
// import Settings from './tabs/Settings';
import Schedule from './components/Schedule';
import ChooseLanguage from './components/ChooseLanguage';
// import Users from './tabs/Users';
// import Invitations from './tabs/Invitations';
import DrawerWindow from "./../Panel/common/DrawerWindow";
import HelpValue from "./../Panel/common/HelpValue";

import {ReactSVG} from 'react-svg'
import Divider from '@material-ui/core/Divider';
// import PanelAppbar from './components/PanelAppbar';
import './Wizard.css';
import * as constants from "./../Panel/common/Constants";
import WizardContext from "./WizardContext";

import {useTranslation} from "react-i18next";
import Complete from "./components/Complete";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Button from "@material-ui/core/Button";
import HelpIcon from "@material-ui/icons/Help";
import Welcome from "./components/Welcome";
import HelpDrawer from "../Panel/common/HelpDrawer";
import TooltipProvider from "../../Providers/TooltipProvider";
import CloseIcon from "@material-ui/icons/Close";

const tabs_icons = {
    DashboardIcon: "/images/panel/dashboard.svg",
    InfoIcon: "/images/panel/INFO.svg",
    SettingsIcon: "/images/panel/settings.svg",
    SponsorsIcon: "/images/panel/SPONSORS.svg",
    StageIcon: "/images/panel/STAGE.svg",
    StatisticsIcon: "/images/panel/statistics.svg",
    UsersIcon: "/images/panel/users.svg",
    InvitationIcon: "/images/panel/invitation.svg",
    ScheduleIcon: "/images/panel/schedule.svg",
    RegistrationIcon: "/images/panel/REGISTER.svg",
    OndemandIcon: "/images/panel/ONDEMAND.svg",
    MarketingIcon: "/images/panel/MARKETING.svg",
}


function TabPanel(props) {
    const {children, value, index, ...other} = props;
    const {t, i18n} = useTranslation();

    return (

        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                 </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const container_styles = makeStyles((theme) => ({
    styles: {
        display: 'flex',
    }
}));

const container_styles_mobile = makeStyles((theme) => ({
    styles: {
        display: 'block',
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',

        // backgroundImage:'<AcUnitIcon />'
    },
    tabs: {
        borderRight: `9px solid ${theme.palette.divider}`,
        width: '21%',
        borderRadius: '4px',
        overflow: 'visible'
    },
    tabs_content: {
        width: '79%',
    },
}));

const useStylesMobile = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'block',
        height: '90%',

        // backgroundImage:'<AcUnitIcon />'
    },
    tabs: {
        borderRight: `9px solid ${theme.palette.divider}`,
        width: '100%',
        borderRadius: '4px',
        overflow: 'visible'
    },
    tabs_content: {
        width: '100%',
    },
}));

function getSteps() {
    return ['Choose Language', 'Basic Info', 'Setup Website', 'Create a Session', 'Create a Sponsor','Completed'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <ChooseLanguage/>;
        case 1:
            return <BasicInfo/>;
        case 2:
            return <Settings/>;
        case 3:
            return <Sponsors/>;
        case 4:
            return <Schedule/>;
        case 5:
            return <Schedule/>;
        default:
            return <Complete/>;
    }
}

const container_help_button = makeStyles((theme) => ({
    styles: {
        color: '#39BB0F',
        opacity: '.55',
        '&:hover': {
            opacity: 1
        },
        marginLeft: '11px'
    }
}));

export default function VerticalTabs(props) {

    const {t, i18n} = useTranslation();
    const helpDrawerRef = createRef();

    const container_style = props.type == "horizontal"?container_styles_mobile():container_styles();
    const classes = props.type == "horizontal"?useStylesMobile():useStyles();
    const [value, setValue] = React.useState(0);
    const [enableButton, setEnableButton] = React.useState(false);
    const [disableCategory, setDisableCategory] = React.useState(false);
    const [completeState, setCompleteState] = React.useState(false);
    const [welcomeState, setWelcomeState] = React.useState(true);
    const [activeStep, setActiveStep] = React.useState(0);
    const {setSaveLanguage,stepButtonState,setStepButtonState,activatedSteps,setActivatedSteps,setSaveBasicInfo,setSaveSettings} = useContext(WizardContext);
    const steps = getSteps();
    console.log(activatedSteps,activeStep)
    const helpbutton_styles = container_help_button();
    const {BoldTooltip} = useContext(TooltipProvider);

    const test = () => {
        // console.log("aaa");
        setCompleteState(true);
        // console.log("bbb");
    };
    const handleNext = () => {

        if(activatedSteps.includes(activeStep+1)){


            setActiveStep((prevActiveStep) => prevActiveStep + 1);

            if(value == 1){

                setTimeout(function() {setValue(10)}, 1000);

            }else if (value == 0){
                setStepButtonState(true);
                setSaveLanguage(true)
                setTimeout(function() {setValue(2)}, 1000);
                setStepButtonState(false);
            }else if (value == 2){
                setStepButtonState(true);
                setSaveBasicInfo(true)
                setTimeout(function() {setValue(11)}, 1000);
                setStepButtonState(false);
            }else if (value == 11){
                setStepButtonState(true);
                setSaveSettings(true)
                setTimeout(function() {setValue(4)}, 1000);
                setStepButtonState(false);
            }else if (value == 4){
                setValue(5);
            }else if (value == 5){
                // setValue(5);
                setCompleteState(true);
            }

            if(activatedSteps.includes(activeStep+2)){
                changeNextButtonState(false);
            }else{
                changeNextButtonState(true);
            }

        }else{

            changeNextButtonState(true);
        }


    };

    const handleBack = () => {

        if(activatedSteps.includes(activeStep)) {
            changeNextButtonState(false);
            setActiveStep((prevActiveStep) => prevActiveStep>0?prevActiveStep - 1:0);
            if (value == 11) {
                setValue(2);
            } else if (value == 4) {
                setValue(11);
            } else if (value == 5) {
                setValue(4);
            } else if (value == 2) {
                setValue(0);
            } else if (value == 6) {
                setValue(5);
            }
        }

    };

    const handleReset = () => {
        setActiveStep(0);
    };


    // classes.root.backgroundImage = <AcUnitIcon />;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tab_headers = [
        {tab_title: t("CHOOSE_LANGUAGE"), icon: tabs_icons.DashboardIcon},
        {tab_title: t("DASHBOARD"), icon: tabs_icons.DashboardIcon},
        {tab_title: t("BASIC INFO"),icon: tabs_icons.InfoIcon},
        {tab_title: t("STAGE"), icon: tabs_icons.StageIcon},
        {tab_title: t("AGENDA"),icon: tabs_icons.ScheduleIcon},
        {tab_title: t("SPONSORS"), icon: tabs_icons.SponsorsIcon},
        {tab_title: t("USERS"),icon: tabs_icons.UsersIcon},
        {tab_title: t("REGISTRATION"), icon: tabs_icons.RegistrationIcon},
        {tab_title: t("MARKETING"), icon: tabs_icons.MarketingIcon},
        {tab_title: t("ON DEMAND"),icon: tabs_icons.OndemandIcon},
        {tab_title: t("STATISTICS"),icon: tabs_icons.StatisticsIcon},
        {tab_title: t("WEBSITE_SETTINGS"), icon: tabs_icons.SettingsIcon},
    ]


    const changeLanguage = (language) => {
        i18n.changeLanguage(language)
    }

    useEffect(() => {


        const loadSponsor = async () => {
            setValue(0);
        };

        // Call the function
        loadSponsor();
    }, []);

    // let disabled_categories = [2,5,6,7,8]
    //
    // const disable_tabs = (index) => {
    //
    //         if(disabled_categories.includes(index)){
    //             return true;
    //             // setDisableCategory(true)
    //         }else{
    //             return false;
    //             // setDisableCategory(false)
    //         }
    // }

    const changeNextButtonState = (state) => {
        setStepButtonState(state);
    }

    return (

        <div id="settings_panel" className={classes.root + " settings_panel wizard"} style={{position:"relative"}}>
            <CloseIcon className="close_icon" onClick={props.handleClose}
                       style={{position: 'absolute', right: '10px', top: '0px',zIndex:1}}/>
            {/*<CloseIcon onClick={props.handleClose} style={{position:'absolute',zIndex:"1",right:'3px',top:'-9px'}}/>*/}
            <Container maxWidth="lg" className={container_style.styles}>
                {/*<DialogTitle id="id">*/}
                {/*    <Box display="flex" alignItems="center">*/}
                {/*        <Box flexGrow={1} >{title}</Box>*/}
                {/*        <Box>*/}
                {/*            <IconButton onClick={onClose}>*/}
                {/*                <CloseIcon />*/}
                {/*            </IconButton>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</DialogTitle>*/}

                <Tabs
                    orientation={props.type?props.type:"vertical"}
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                    TabsProps={{
                        style: {
                            overflow: "visible",
                        }
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#39BB0F",
                            width: "9px",
                            borderRadius: "4px",
                            right: "0",
                            zIndex: "1"
                        }
                    }}
                >

                    {tab_headers.map((tab, i) => {
                        // let ddd = disable_tabs(i);
                        // console.log(ddd)
                        // return (<Tab disable={disableCategory} icon={<ReactSVG beforeInjection={(svg) => {
                        // return (<Tab style={{lineHeight:"20px"}} disabled={i == 1 || i == 3 || i == 6 || i == 7 || i == 8 || i == 9 || i == 10?true:''} icon={<ReactSVG beforeInjection={(svg) => {
                        return (<Tab style={{lineHeight:"20px"}} disabled={i == value?false:true} icon={<ReactSVG beforeInjection={(svg) => {
                            // return (<Tab disabled={dd}  icon={<ReactSVG beforeInjection={(svg) => {
                            svg.classList.add('svg-class-name');
                            svg.setAttribute('style', 'width:55px');
                        }} src={constants.LocalUrl + tab.icon}/>} label={tab.tab_title} {...a11yProps(i)} />)

                    })}
                </Tabs>


                {/*<div className="stepper_steps_wrapper" style={{width:"100%", height:"658px",maxHeight:"658px",overflow:"hidden"}}>*/}
                <div className="stepper_steps_wrapper" style={{width:"100%"}}>

                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={"0"+label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                        <div className="steps_button_wrapper">
                            <div className="steps_button">

                                {activeStep != 0?<Button
                                    // disabled={activeStep === 0}
                                    // disabled={stepButtonState}
                                    onClick={handleBack}
                                    className={classes.backButton}
                                >
                                    {t('go_back')}
                                </Button>:""}
                                {/*{console.log("stepButtonState",stepButtonState)}*/}
                                <Button disabled={stepButtonState} variant="contained" color="primary" onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </div>
                        </div>
                    </Stepper>

                    <TabPanel className={classes.tabs_content} value={value} index={0}>
                        <Typography xs={12} alignLeft variant="h5" style={{
                            textAlign: 'left',
                            fontWeight: 600,
                            display: "flex",
                            lineHeight: "20px",
                            justifyContent: "flex-start"
                        }} gutterBottom>
                            {t("CHOOSE_LANGUAGE")}
                        </Typography>


                        <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                        <br/><br/>

                        <ChooseLanguage/>

                    </TabPanel>

                    <TabPanel className={classes.tabs_content} value={value} index={1}>
                        <Typography xs={12} alignLeft variant="h5" style={{
                            textAlign: 'left',
                            fontWeight: 600,
                            display: "flex",
                            justifyContent: "flex-start"
                        }} gutterBottom>
                            {t("DASHBOARD")}
                            <BoldTooltip title={t("Information")} arrow>
                            <HelpIcon className={helpbutton_styles.styles}
                                      onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "dashboard")}
                            />
                            </BoldTooltip>
                        </Typography>


                    <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    {/*<br/><br/>*/}

                    {/*<Dashboard/>*/}

                </TabPanel>

                <TabPanel className={classes.tabs_content} value={value} index={2}>
                    <Typography xs={12} alignLeft variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("BASIC INFO")}
                        <BoldTooltip title={t("Information")} arrow>
                        <HelpIcon className={helpbutton_styles.styles}
                                  onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "basicinfo")}
                        />
                        </BoldTooltip>
                    </Typography>


                    <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>


                    {/*<br/><br/>*/}

                    <BasicInfo/>

                </TabPanel>

                <TabPanel className={classes.tabs_content} value={value} index={3}>
                    <Typography xs={12} alignLeft variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("STAGE")}
                    <BoldTooltip title={t("Information")} arrow>
                        <HelpIcon className={helpbutton_styles.styles}
                                  onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "stage")}
                        />
                    </BoldTooltip>
                    </Typography>


                    <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    {/*<br/><br/>*/}

                    {/*<Stage/>*/}

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} index={4}>
                    <Typography xs={12} alignLeft variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("AGENDA")} <HelpIcon className={helpbutton_styles.styles}
                                                onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "agenda")}
                    />
                    </Typography>


                    <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    {/*<br/><br/>*/}

                    <Schedule/>

                </TabPanel>


                <TabPanel className={classes.tabs_content} value={value} index={5}>

                        <Typography xs={12} alignLeft variant="h5" style={{
                            textAlign: 'left',
                            fontWeight: 600,
                            display: "flex",
                            lineHeight: "20px",
                            justifyContent: "flex-start"
                        }} gutterBottom>
                            {t("SPONSORS")}
                            <BoldTooltip title={t("Information")} arrow>
                                <HelpIcon className={helpbutton_styles.styles}
                                          onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "sponsors")}
                                />
                            </BoldTooltip>
                        </Typography>


                    <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    {/*<br/><br/>*/}

                    <Sponsors/>

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} index={6}>
                    <Typography xs={12} alignLeft variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("USERS")}
                        <BoldTooltip title={t("Information")} arrow>
                        <HelpIcon className={helpbutton_styles.styles}
                                  onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "users")}
                        />
                        </BoldTooltip>
                    </Typography>


                    <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    {/*<br/><br/>*/}

                    {/*<Users/>*/}

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} index={7}>
                    <Typography xs={12} alignLeft variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("REGISTRATION")}
                        <BoldTooltip title={t("Information")} arrow>
                            <HelpIcon className={helpbutton_styles.styles}
                                      onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "registration")}
                            />
                        </BoldTooltip>
                    </Typography>


                    <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    <br/><br/>

                    {/*<RegistrationFields/>*/}

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} index={8}>
                    <Typography xs={12} alignLeft variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("MARKETING")}
                        <BoldTooltip title={t("Information")} arrow>
                            <HelpIcon className={helpbutton_styles.styles}
                                      onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "marketing")}
                            />
                        </BoldTooltip>
                    </Typography>


                    <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    <br/><br/>

                    {/*<Invitations/>*/}

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} index={9}>
                    <Typography xs={12} alignLeft variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("ON DEMAND")}
                        <BoldTooltip title={t("Information")} arrow>
                            <HelpIcon className={helpbutton_styles.styles}
                                      onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "ondemand")}
                            />
                        </BoldTooltip>
                    </Typography>


                    <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    <br/><br/>

                    {/*<OndemandDashboard></OndemandDashboard>*/}

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} index={10}>
                    <Typography xs={12} alignLeft variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("STATISTICS")}
                        <BoldTooltip title={t("Information")} arrow>
                            <HelpIcon className={helpbutton_styles.styles}
                                      onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "statistics")}
                            />
                        </BoldTooltip>
                    </Typography>


                    <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    <br/><br/>

                    {/*<SimpleBar style={{maxHeight:"658px", height:"658px", overflowX: "hidden"}} >*/}
                    {/*<Statistics/>*/}
                    {/*</SimpleBar>*/}

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} index={11}>
                    <Typography xs={12} alignLeft variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("WEBSITE_SETTINGS")}
                        <BoldTooltip title={t("Information")} arrow>
                            <HelpIcon className={helpbutton_styles.styles}
                                      onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "settings")}
                            />
                        </BoldTooltip>
                    </Typography>


                    <Divider bold style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    <br/><br/>

                    <Settings/>

                    </TabPanel>


                </div>
                <DrawerWindow type="contained"  open={welcomeState} direction="bottom">
                    <Welcome closeWindow={setWelcomeState}/>
                </DrawerWindow>
                <DrawerWindow type="contained" open={completeState} direction="bottom">
                    <Complete/>
                </DrawerWindow>
            </Container>

            <HelpDrawer ref={helpDrawerRef} />
        </div>
    );
}

