import {createContext, useEffect, useState} from "react";
import * as constants from "../Panel/common/Constants";
import axios from "axios";

const PageContext = createContext();

export function PageProvider({children}){


    const [reloadData,setReloadData] = useState(true);
    const [guser,setGuser] = useState({});
    const [settings,setSettings] = useState({});
    const [putVideoCall,setPutVideoCall] = useState({activate:false});
    const [eventManagerState,setEventManagerState] = useState({activate:false});
    const [settingsWindow,setSettingsWindow] = useState({activate:false});
    const [closeModalWithButton,setCloseModalWithButton] = useState(false);
    let category_icons = {
        1 : "/app/award-1.svg",
        2 : "/app/award-2.svg",
        3 : "/app/award-3.svg",
        4 : "/app/award-4.svg",
        5 : "/app/award-5.svg",
    };
    useEffect(() => {

        const user = async () => {

            await axios.post(constants.LocalUrl + '/data/get_current_user_data').then(response => {
                setGuser(response);
            });

            await axios.post(constants.LocalUrl + '/data/get_menu_settings').then(response => {
                if (response.data.user.id > 0) {
                    localStorage.setItem('userLoggedIn', 1);
                }
                else {
                    localStorage.removeItem('userLoggedIn');
                }
                setSettings(response.data);
                // console.log("get_menu_settings")
                // console.log(response.data)
                document.documentElement.style.setProperty('--font-color', response.data.font_color);
                document.documentElement.style.setProperty('--divider-color', response.data.divider_color);
            });

            setReloadData(false);
        }

        // Call the function
        if(reloadData === true){
            user();
        }
    }, [reloadData]);

    // const [activatedSteps,setActivatedSteps] = useState([1]);
    // console.log(stepButtonState)
    // const handleStepButtonState = (status = false) => {
    //     setStepButtonState((prevState) => [...prevState,true])
    // }

    return(
        <PageContext.Provider value={{category_icons,guser,settings,putVideoCall,setPutVideoCall,setReloadData,eventManagerState,setEventManagerState,settingsWindow,setSettingsWindow,closeModalWithButton,setCloseModalWithButton}}>
            {children}
        </PageContext.Provider>
    )
}






export default PageContext;