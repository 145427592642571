import React, {useContext, useEffect, useState} from 'react';
import * as constants from "../../../../../../Panel/common/Constants";
import Grid from "@material-ui/core/Grid";
import FileUploadComponent from "../../../../../../Panel/common/FileUploadComponent";
import Button from "@material-ui/core/Button";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CircularProgress from "@material-ui/core/CircularProgress";
import PageProvider from "../../../../../PageContext";
import UserFields from "../../../../../../Panel/common/UserFields"
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Loader from "../../../../../../common/Loader";
import Typography from "@material-ui/core/Typography";

export default function PersonalData (){

    const {t, i18n} = useTranslation();
    const [user,setUser] = useState({});
    const [loading,setLoading] = useState(false);
    const [countries,setCountries] = useState([]);
    const [timezones,setTimezones] = useState([]);
    const [error, setError] = useState([]);

    const {guser} = useContext(PageProvider);

    useEffect(() => {

        const loadUser = async () => {
            setLoading(true);
            // console.log(guser)
            window.axios.post(constants.LocalUrl + '/data/user_full_data', {id: guser.id}).then(response => {
                // console.log('response', response);
                // After fetching data stored it in posts state.
                setUser(response.data[0]);
                setLoading(false);
            });

            window.axios.post(constants.LocalUrl + '/data/get_all_countries', {id: guser.id}).then(response => {
                // console.log('response', response);
                setCountries(response.data);

            });

            window.axios.post(constants.LocalUrl + '/data/get_all_timezones').then((response) => {
                setTimezones(response.data);
            })
        }

        loadUser();

    }, []);

    const onSubmitForm = () => {
        const loadItems = async () => {
            await axios.post(
                constants.LocalUrl + '/data/update_user',user).then(response => {


                if(response.data.status == "error"){
                    setError(response.data.data.errors);
                }else{

                let index_id;
                const i = props.posts.map((single_post,index) =>  {
                        if(single_post.id === response.data.data.id){
                            index_id = index;
                            return true;
                        }
                    }
                );



                let items = [...props.posts];
                let item = {...items[index_id]};

                item.name = response.data.data.name;
                item.email = response.data.data.email;
                item.roles = response.data.data.roles;
                items[index_id] = item;
                // console.log("sss");
                // console.log(items);
                props.triggerdataload(items);
                props.triggerdrawerfunc(false);
                }
            });
        }

        // Call the function
        loadItems();
    };

    const notify = () => toast.success(t('Succesfully created account'), {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const onTagsChange = (event, values) => {
        event.persist();
        setUser(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                roler: values      // update the value of specific key

            }))
    }

    const onInputchange = (event) => {

        event.persist();

        // console.log(event);

        setUser((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
        // console.log("Edituser",user)
    };

    const onEditorCVchange = (event) => {

        setUser((prevState) => ({
            ...prevState,
            cv: event,
        }));

    };


    const handleCheckbox = (event) => {
        event.persist();

        setUser((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.checked,
        }));

    }

    const getImage = (data) => {
        setUser((prevState) => ({
            ...prevState,
            file: data,
        }));
    }



    const options = [
        { title: t('Coordinator'), id: 2 },
        { title: t('Speaker'), id: 4 },
        { title: t('Executive'), id: 5 },
        { title: t('Attendee'), id: 3 },
        { title: t('Hidden'), id: 7 },
    ];

    const onCountryChange = (event, newValue) => {
        setUser((prevState) => ({
            ...prevState,
            ['Country']: newValue ? newValue.code : null,
        }));
    };

    const onTimezoneChange = (event, newValue) => {
        setUser((prevState) => ({
            ...prevState,
            ['timezone']: newValue ? newValue.area : null,
        }));
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    // let decodedHtml;
    // if(values.description){
    // decodedHtml =  values.description.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
    // }
    // const return_options_roles = (saved_roles) => {
    //     console.log(user);
    //     let arr = [];
    //
    //     options.map((e,index) =>{
    //         if(saved_roles.includes(e.id)){
    //             arr.push(options[index]);
    //         }
    //     });
    //     return arr;
    // }

    if(loading === true || user.length === 0){
        return <Loader />
    }

    return (

        <div className="registration_container" style={{display:'flex',justifyContent:'center',background:"#fff",borderRadius:"20px",boxShadow:"-5px 3px 40px #5e5c9a1a"}}>
            <Grid container spacing={3} style={{padding:'20px',maxWidth:'100%'}}>
                <React.Fragment key={'right'}>

                    <Grid container spacing={3} style={{padding:'20px', marginBottom:"40px"}}>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                                {t('Event logo')}
                                <span className="image_text_description">{t('Maximum')} {t('upload_dimensions', {text: '150px * 150px'})}</span>
                            </Typography>
                            <FileUploadComponent image={user.user_img?user.user_img:''} extra={{id: user.id,action:constants.LocalUrl + '/data/addUserImg'}} fullwidth
                                                 accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                                 maxWidth={150} maxHeight={150}
                            />
                        </Grid>
                    </Grid>

                    <UserFields type="user_profile"
                                handleCountry={onCountryChange} handleTimezone={onTimezoneChange}
                                handleUser={onInputchange} handleEditor={onEditorCVchange} handleCheckbox={handleCheckbox} user={user} error={error} />

                    <Grid item xs={12} style={{textAlign:"right"}} className="presonal_data_submit_wrapper">
                        <Button id="close_button" onClick={onSubmitForm} variant="contained" color="primary" component="span">{t('SAVE')}</Button>
                    </Grid>

                </React.Fragment>
            </Grid>
        </div>
    )

}
