import React, {useContext, useEffect, useState} from 'react';
import './LiveonChat.css';
import "react-chat-elements/dist/main.css";
import LiveonChatWindow from "./LiveonChatWindow";
import axios from "axios";
import * as constants from "../../Panel/common/Constants";
import PageProvider from "../../Page/PageContext";
import {CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import ChatProvider from "../../Page/ChatContext";
import FrontEmptyDiv from "../FrontEmptyDiv";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const chat_icons = {
    SidebarChatIcon: "/images/panel/sidebar_chat.svg",
}

export default function LiveonChat(props) {

    const {t, i18n} = useTranslation();
    const {settings} = useContext(PageProvider);
    const [myuser, setMyuser] = useState(null);
    const {conversations, setConversations, setReloadData, chatWindows, setChatWindows} = useContext(ChatProvider);
    const [channel, setChannel] = useState(null);


    function reloadConversations(callback) {
        return axios.post(constants.LocalUrl + '/data/chat_get_user_conversations')
            .then(res => {
                const conversationz = res.data;
                setConversations(conversationz);
            })
    }

    useEffect(() => {
        setMyuser(settings.user);
        function handleStatusChange(status) {
        }
        if (conversations.length == 0) {
            reloadConversations();
        }
        if (!channel) {
            var ch = window.Echo.private('chatlist.' + window.temp_user_id)
                .listen('.reload-conversations', (data) => {
                    reloadConversations();
                });
            setChannel(ch);
        }
        return function cleanup() {
        };

    }, [])

    const openChatWindow = (user_id) => {
        let windows = chatWindows;
        if (windows.filter((e) => {
            return e.user_id == user_id
        }).length < 1) {
            setChatWindows((windows) => {
                windows = [...windows, {user_id: user_id}]
                return unique(windows, 'user_id');
            });
        }
    }

    const unique = (objArray, key) => {
        return [...new Map(objArray.map((item) => [item[key], item])).values()];
    }

    const strip = (html) => {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    if (window.temp_user_id) {
        return (
            <>
                {
                    props.open
                        ?
                        <>
                            <Typography xs={12} alignLeft variant="h5" style={{
                                textAlign: 'left',
                                fontWeight: 600,
                                display: "flex",
                                lineHeight: "20px",
                                justifyContent: "flex-start",
                                marginTop: '20px'
                            }} gutterBottom>
                                {t('my_chats')}
                            </Typography>
                            <Divider bold style={{height: "3px", backgroundColor: "var(--divider-color) "}}/>
                            <div className="chats-nav">
                                {
                                    Object.values(conversations).length > 0
                                        ? Object.values(conversations).map(conversation =>
                                            <div key={"con"+conversation.id} className="chat-div row" data-chatid={conversation.id}
                                                 data-status={(conversation.last_message[window.temp_user_id + ''] != null) ? conversation.last_message[window.temp_user_id + ''].status : 'none'}>
                                                <div
                                                    className={(conversation.last_message[window.temp_user_id + ''] != null && conversation.last_message[window.temp_user_id + ''].status == 'New') ? "col-3 new-badge" : "col-3"}
                                                    onClick={() => {
                                                        openChatWindow(conversation.other_user.id)
                                                    }} data-recipient={conversation.other_user.id}>
                                                    <img alt=""
                                                         src={conversation.other_user.user_img}
                                                         className="face rounded-small bigger"/>
                                                    <span className="person-status  person-offline "></span>
                                                </div>
                                                <div className="col-8" onClick={() => {
                                                    openChatWindow(conversation.other_user.id)
                                                }} data-recipient={conversation.other_user.id}>
                                                    <b>{conversation.other_user.name}</b>
                                                    {
                                                        conversation.last_message.all.message == 'first_message_intro_text'
                                                            ? <p className="text-muted">
                                                                {
                                                                    window.temp_user_id == conversation.last_message.all.from_user.id
                                                                        ? t('new_chat_request_sender')
                                                                        : t('new_chat_request_recipient', {fullname: conversation.last_message.all.from_user.name})
                                                                }
                                                            </p>
                                                            : <p className="text-muted">{strip(conversation.last_message.all.message)}</p>
                                                    }
                                                </div>
                                                <div className="col-1 text-center">
                                                    <i className="fa fa-ellipsis-v chat-more"
                                                       id={"chats-more-" + conversation.other_user.id}></i>
                                                    <ul className="text-left">
                                                        <li><a href={"/social/profile/" + conversation.other_user.id}><i
                                                            className="fa fa-address-card-o"></i> {t('user_profile')}</a></li>
                                                        <li><a onClick={() => {
                                                            openChatWindow(conversation.other_user.id)
                                                        }} className="startchat"
                                                               data-recipient={conversation.other_user.id}><i
                                                            className="fa fa-comment-o"></i> {t('Chat')}</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                        :
                                        <div style={{width: "100%", padding: 30, textAlign: "center"}}>
                                            <div dangerouslySetInnerHTML={{__html: t('front_chat_no_data')}}></div>
                                        </div>
                                }
                            </div>
                        </>
                        : null
                }
            </>
        )
    }

    else return null;

}
