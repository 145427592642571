import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import NewSponsor from "./components/NewSponsor";
import SponsorCreatebutton from "./components/SponsorCreatebutton";
import NewSponsorCategoryButton from "./components/NewSponsorCategoryButton";
import NewSponsorCategory from "./components/NewSponsorCategory";
import LoadedSponsorCategory from "./components/LoadedSponsorCategory";
import LoadedSponsor from "./components/LoadedSponsor";
import DrawerWindow from "../../../Panel/common/DrawerWindow";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as constants from "../../../Panel/common/Constants"

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DraggableSimpleList from "../../../common/DraggableSimpleList";
import {useTranslation} from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const useStyles = makeStyles({
    list: {
        width: '800px',
        padding: '40px'
    },
    fullList: {
        width: 'auto',
    },
});


export default function Sponsors() {

    const {t, i18n} = useTranslation();
    const [drawerstate, setDrawerstate] = useState({
            open: false,
            open_cat: false,
            id: null,
            id_cat: null,
            }
    );
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const loadPost = async () => {

        // Till the data is fetch using API
        // the Loading page will show.
        setLoading(true);

        // Await make wait until that
        // promise settles and return its reult
        const response = await axios.post(
            constants.LocalUrl + "/data/get_all_sponsors");

        // After fetching data stored it in posts state.
        setPosts(response.data);

        const categories_response = await axios.post(
            constants.LocalUrl + "/data/get_sponsor_categories");

        setCategories(categories_response.data);
        // Closed the loading page
        setLoading(false);
    }

    useEffect(() => {
        // Call the function
        loadPost();
    }, []);

    const toggleChildMenu = (attached,sponsor_id) => () => {
        setDrawerstate({...drawerstate, open: attached,id:sponsor_id});
    };

    const toggleChildMenuFunc = (attached,sponsor_id) =>  {
        setDrawerstate({...drawerstate, open: attached,id:sponsor_id});
    };

    const newPostsHandler = (data) => {
        // setPosts((posts) => [data, ...posts]);
        loadPost();
    };

    const updatePostsHandler = (data) => {
        setPosts((posts) => data);
        // loadPost();
    };


    const toggleChildMenuCat = (attached,sponsor_id) => () => {
        setDrawerstate({...drawerstate, open_cat: attached,id_cat:sponsor_id});
        // console.log(drawerstate);
    };

    const toggleChildMenuFuncCat = (attached,sponsor_id) =>  {
        setDrawerstate({...drawerstate, open_cat: attached,id_cat:sponsor_id});
    };

    const newPostsHandlerCat = (data) => {
        // setCategories((categories) => [data, ...categories]);
        loadPost();
    };

    const updatePostsHandlerCat = (data) => {
        // setCategories((categories) => data);
        loadPost();
    };

    const saveCategoriesOrder = (event) => {
        const response = axios.post(
            constants.LocalUrl + "/data/saveSponsorCategoriesOrder",{data:event});
    }
    const saveSponsorsOrder = (event) => {
        const response = axios.post(
            constants.LocalUrl + "/data/saveSponsorsOrder",{data:event});
    }

    if(loading === true){
        return <CircularProgress />
    }

    let table_sponsor_titles = [t("sponsor_title"),""]
    let table_sponsor_category_titles = [t("sponsor_category_title"),""]
    let layout_content;
    let layout_content_cat;

    if (categories !== null && categories.length > 0) {
        // layout_content_cat = <div><TableSponsorCategories props={categories} triggerdrawer={toggleChildMenuCat} /></div>;
        layout_content_cat = <div><DraggableSimpleList table_titles={table_sponsor_category_titles}  object_type="SponsorCategory" data={categories} edit={toggleChildMenuCat} save={saveCategoriesOrder} triggerdataload={updatePostsHandlerCat}/></div>;
    }

    if (posts === null) {
        layout_content = <div><SponsorCreatebutton onClick={toggleChildMenu(true)}/></div>;
    }

    if (posts !== null && posts.length > 0) {
        // layout_content = <div><TableSponsors props={posts} triggerdrawer={toggleChildMenu} /></div>;
        layout_content = <div><DraggableSimpleList table_titles={table_sponsor_titles} object_type="Sponsor" data={posts} edit={toggleChildMenu} save={saveSponsorsOrder} triggerdataload={updatePostsHandlerCat}/></div>;
    }


    return (
        <SimpleBar forceVisible="y" autoHide={false} style={{maxHeight:"528px", height:"528px", overflowX: "hidden"}} >
        <Grid container spacing={3} style={{padding:'0px', width: '100%', margin: 0}}>
                <Grid item xs={12}>
                    <Typography id="sponsor_cateogories" c xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                        {t('SPONSORS_CATEGORIES')} <ExpandMoreIcon /> <NewSponsorCategoryButton type={'small'} onClick={toggleChildMenuCat(true)}/>
                    </Typography>
                    <Divider light />
                </Grid>
                <Grid item xs={12}>
                    <div id="sponsors_categories_hidden">
                    {layout_content_cat}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Typography id="sponsors" xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                        {t('Sponsors_panel')} <SponsorCreatebutton type={'small'} onClick={toggleChildMenu(true)}/>
                    </Typography>
                    <Divider light />
                </Grid>
                <Grid item xs={12}>
                    {layout_content}
                </Grid>
            </Grid>

            <DrawerWindow open={drawerstate.open_cat}>
                {drawerstate.id_cat?<LoadedSponsorCategory props={drawerstate.id_cat} posts={categories} triggerdrawer={toggleChildMenuCat} triggerdrawerfunc={toggleChildMenuFuncCat} triggerdataload={updatePostsHandlerCat} />:<NewSponsorCategory triggerdrawer={toggleChildMenuCat} triggerdrawerfunc={toggleChildMenuFuncCat} triggerdataload={newPostsHandlerCat}/>}
            </DrawerWindow>

            <DrawerWindow  open={drawerstate.open}>
                {drawerstate.id?<LoadedSponsor props={drawerstate.id} posts={posts} triggerdrawer={toggleChildMenu} triggerdrawerfunc={toggleChildMenuFunc} triggerdataload={updatePostsHandler} />:<NewSponsor triggerdrawer={toggleChildMenu} triggerdrawerfunc={toggleChildMenuFunc} triggerdataload={newPostsHandler}/>}
            </DrawerWindow>
        </SimpleBar>
    );

}

