import React, {useEffect, useState} from 'react';
import Loader from '../../../common/Loader'
// import PageProvider from "../PageContext";
// import Loader from "../../common/Loader";
// import MainBannerWidget from "./MainBannerWidget/MainBannerWidget";
// import DateTimer from "./MainBannerWidget/DateTimer";
import * as constants from "../../../Panel/common/Constants";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useParams} from "react-router-dom";

export default function Page() {

    const {t, i18n} = useTranslation();
    // const {settings} = useContext(PageProvider);
    const [page, setPage] = useState({});
    let {page_id} = useParams();

    useEffect(() => {
        axios.post(constants.LocalUrl + '/data/get_page_data', {id: page_id})
            .then(res => {
                setPage(res.data);
                // console.log(res.data);
            })

    }, [page_id])


    if (Object.keys(page).length === 0) {
        return <Loader/>
    }

    return (
        // <Container>
        <div style={{width: "100%", backgroundColor: "#fafafa", height: "100vh"}}>
            <div className="container" id="speakers-individual"
                 style={{maxWidth: "1473px", width: "100%", paddingTop: "35px", paddingBottom: "30px"}}>
                <div className="row">
                    <h3 className="card-title" style={{
                        textAlign: 'center',
                        fontWeight: '700',
                        marginTop: '10px'
                    }}>{page.data.title}</h3>
                    {/*{console.log(page.data)}*/}
                    {/*{(page.data.description).map((value) => (*/}
                    <>
                        {/*<div className="subtitle">*/}
                        {/*    {value.section_title}*/}
                        {/*</div>*/}
                        <div dangerouslySetInnerHTML={{__html: page.data.content}}/>
                    </>
                    {/*))}*/}
                </div>
            </div>
        </div>
        // </Container>
    )
}