import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FileUploadComponent from "../../../common/FileUploadComponent";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import Select from '@material-ui/core/Select';
import Divider from "@material-ui/core/Divider";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import * as constants from "../../../common/Constants"
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CTextField from "../../../common/CTextField";
import Editor from '../../../common/Editor';
import {useTranslation} from "react-i18next";
import ListFiles from './files/ListFiles';
import NewFile from "./files/NewFile";
import UpdateFile from "./files/UpdateFile";
import DraggableSimplestList from "../../../../common/DraggableSimplestList";
import {toast} from "react-toastify";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LanguageChangerForPanel from "../../../../common/Language/LanguageChangerForPanel";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import AutoCompleteField from "../../../../common/AutoCompleteField";
import SponsorsHook from "../SponsorsHook";
import CSelectBox from "../../../common/CSelectBox";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 0,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select : {
        width: '100%'
    }
}));

export default function LoadedSponsor(props)  {
    const classes = useStyles();

    const {t, i18n} = useTranslation();

    const {
        sponsor_sizes,
        video_types,
        changeLocale,
        sponsorid,
        sponsorLocale,
        holdFilename,
        sponsorcategories,
        sponsorFiles,
        handleDescription,
        showSponsorFilesMenu,
        executives,
        onExecutivesChange,
        handleExecutivesOrder,
        filename,
        onInputchangeSocials,
        editorDescription,
        localeLanguage,
        sponsor,
        showSponsorFiles,
        addFileHandler,
        updatePostsHandler,
        showSponsorFilesMenuFunc,
        onInputchange,
        onSubmit,hasError,errorMessage } = SponsorsHook(props,'edit');


    if((executives && Object.keys(executives).length == 0) || (sponsor && Object.keys(sponsor).length == 0)
        // || executives.length == 0
        // || (sponsorFiles && sponsorFiles.length == 0)
    ){
        return <Loader />
    }


    let wrapper_style = {
        display:'flex',justifyContent:'center',transition:'1s all'
    }
    let file_wrapper_style = {
        width:'0px',
        transition: "all 0.5s"
    }
    if(showSponsorFiles.state === true){
        file_wrapper_style = {
            width: "100%",
            boxShadow:"0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            // background: "rgba(225, 224, 225,0.4)",
            marginLeft: "0",
            marginTop: "30px",
            borderRadius:"20px",
            transition: "all 0.5s"
        }
    }

    return (
        <div className="bottom_bar">


            <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('Edit Sponsor')}
            </Typography>

            <Typography variant="subtitle1" style={{textAlign:'center'}} gutterBottom>
                <span style={{fontSize:"21px",fontStyle:"italic"}}>{sponsorLocale.title}</span>
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
        <div id='sponsors_wrapper' style={wrapper_style}>
        <React.Fragment key={'right'}>
            <Grid container spacing={4} style={{padding:'0 20px',maxWidth:'800px'}}>
                <Grid item xs={12}>
                    <Typography xs={12} variant="h3" style={{textAlign:'left'}} gutterBottom>
                        {t('Sponsor Details')}
                    </Typography>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <CTextField value={sponsorLocale.title?sponsorLocale.title:""} handler={onInputchange} name='title' label={t('Title')} required error={hasError('title')} helperText={errorMessage('title')}/>
                </Grid>
                <Grid item xs={12}>
                    <Editor lang={localeLanguage} handler={handleDescription} value={editorDescription} name="description" label={t('Description')}/>
                </Grid>
                <Grid item xs={6}>
                    <CSelectBox
                        title={t('Sponsor Category')}
                        required={true}
                        name="category_id"
                        onChange={onInputchange}
                        data={sponsorcategories}
                        value={sponsorLocale.category_id}
                    />
                </Grid>

                <Grid item xs={6}>
                    <CSelectBox
                        title={t('Sponsor_Type')}
                        required={true}
                        name="typos"
                        onChange={onInputchange}
                        data={sponsor_sizes}
                        value={sponsor.typos?sponsor.typos:'Small'}
                    />

                </Grid>
                {/*<Grid item xs={6}>*/}
                {/*    <CTextField value={sponsor.order?sponsor.order:""} handler={onInputchange} name='order' label="Order"/>*/}
                {/*</Grid>*/}

                <Grid item xs={6} className="panel_sponsor logo_images">
                    <Typography variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                        {t('Sponsor Logo')}
                        <span className="image_text_description">{t('upload_dimensions', {text: '300px * 180px'})}</span>
                    </Typography>
                    <FileUploadComponent id="1" image={sponsorLocale.logo?sponsorLocale.logo:''} extra={{id: sponsorid,action:'/data/addSponsorLogo'}}
                                         accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                         maxWidth={300} maxHeight={180}
                    />

                </Grid>

                <Grid item xs={6} className="panel_sponsor logo_images">
                    <fieldset disabled={ (sponsorLocale.video?sponsorLocale.video:"").trim() != '' }>
                    <Typography variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                        {t('Sponsor Banner')}
                        <span className="image_text_description">{t('upload_dimensions', {text: '720px * 392px'})}</span>
                    </Typography>
                    <FileUploadComponent id="2" image={sponsorLocale.topwidebanner ?sponsorLocale.topwidebanner :''} extra={{id: sponsorid,action:'/data/addSponsorTopwidebanner'}}
                                         accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                         maxWidth={720} maxHeight={392}
                    />
                    </fieldset>
                </Grid>

                <Grid item xs={12}>
                    {/*<FormControl variant="outlined" className={classes.formControl} style={{width:'100%'}} fullwidth>*/}
                    {/*    <div style={{display:'flex',alignItems:'center'}}>*/}
                    {/*    <CSelectBox*/}
                    {/*        title={t('Sponsor_Type')}*/}
                    {/*        required={true}*/}
                    {/*        name="category_id"*/}
                    {/*        onChange={onInputchange}*/}
                    {/*        data={video_types}*/}
                    {/*        value={sponsorLocale.type_video?sponsorLocale.type_video:'vimeo'}*/}
                    {/*        style={{marginRight:"15px",width:"140px"}}*/}
                    {/*    />*/}
                    {/*    <TextField id="outlined-basic" label="" variant="outlined" value={sponsorLocale.video?sponsorLocale.video:""} onChange={onInputchange} name='video' fullWidth/>*/}
                    {/*    </div>*/}
                    {/*</FormControl>*/}
                    <FormControl variant="outlined" className={classes.formControl} style={{width:'100%'}}>
                        <Typography variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px",padding:"0"}} gutterBottom>
                            {t('Video')}
                        </Typography>
                        <div style={{display:'flex',alignItems:'center'}}>
                            {/*<InputLabel id="demo-simple-select-outlined-label">Video Recording</InputLabel>*/}
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="grouped-native-select"
                                IconComponent={ExpandMoreIcon}
                                value={sponsorLocale && sponsorLocale.type_video?sponsorLocale.type_video:'vimeo'}
                                onChange={onInputchange}
                                name="type_video"
                                style={{marginRight:"15px",width:"140px"}}
                            >
                                {video_types.map((row) => (
                                    <MenuItem key={"io"+row.id} value={row.id}>{row.title}</MenuItem>
                                ))}
                            </Select>
                            <TextField id="outlined-basic" label="" variant="outlined" value={sponsorLocale.video?sponsorLocale.video:""} onChange={onInputchange} name='video' />
                        </div>
                    </FormControl>
                </Grid>



                <Grid item xs={6}>
                    <CTextField value={sponsorLocale.website?sponsorLocale.website:""} handler={onInputchange} name='website' label={t('Website')}/>
                </Grid>
                <Grid item xs={6}>
                    <CTextField value={sponsorLocale.email?sponsorLocale.email:""} handler={onInputchange} name='email' label={t('Email')}/>
                </Grid>


                <Grid item xs={12}>
                    <Typography xs={12} variant="h3" style={{textAlign:'left'}} gutterBottom>
                        {t('Executives')}
                    </Typography>
                    <Divider />
                </Grid>

                <Grid item xs={6}>
                    <AutoCompleteField
                        placeholder={t("Choose_an_executive")}
                        model={executives}
                        data={sponsorLocale.executives}
                        onChange={onExecutivesChange}
                        handleItemsOrder={handleExecutivesOrder}
                    />
                </Grid>

                {/*<Grid item xs={12}>*/}
                    <br/>
                    <Grid item xs={12}>
                        <Typography xs={12} variant="h3" style={{textAlign:'left'}} gutterBottom>
                            {t("Settings Social links")}:
                        </Typography>
                        <Divider />
                        <br/>
                    </Grid>
                    {/*<Typography variant="h5" style={{textAlign:'left'}} gutterBottom>*/}
                    {/*    {t("Settings Social links")}:*/}
                    {/*</Typography>*/}
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Facebook")} value={sponsor.socials.Facebook} name="Facebook" />
                        </Grid>
                        <Grid item xs={6}>
                            <CTextField handler={onInputchangeSocials} label={t("Sponsor Social LinkedIn")} value={sponsor.socials.LinkedIn} name="LinkedIn" />
                        </Grid>
                        <Grid item xs={6}>
                            <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Youtube")} value={sponsor.socials.Youtube} name="Youtube" />
                        </Grid>
                        <Grid item xs={6}>
                            <CTextField handler={onInputchangeSocials} label={t("Sponsor Social Twitter")} value={sponsor.socials.Twitter} name="Twitter" />
                        </Grid>
                    </Grid>
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <Typography xs={12} variant="h3" style={{textAlign:'left'}} gutterBottom>
                        {t('Files')}
                    </Typography>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <Button   size="medium" onClick={showSponsorFilesMenu(true)} variant="contained" height="50" color="secondary">{t('+ New File')}</Button>
                    <Grid item xs={12} style={file_wrapper_style}>
                        {/*{console.log("showSponsorFiles",props)}*/}
                        {showSponsorFiles.state?
                        showSponsorFiles.id?<UpdateFile sponsor_id={props.id} props={showSponsorFiles.id} triggerdrawer={showSponsorFilesMenu} triggerdrawerfunc={showSponsorFilesMenuFunc} triggerdataload={updatePostsHandler} />
                            :
                            <NewFile filename={filename} holdFilename={holdFilename} triggerdataload={addFileHandler} sponsor_id={props.id} triggerdrawer={showSponsorFilesMenu} triggerdrawerfunc={showSponsorFilesMenuFunc}/>:""}
                    </Grid>
                    <ListFiles filename={filename} holdFilename={holdFilename} triggerdataload={updatePostsHandler} sponsor_id={props.id} triggerdrawer={showSponsorFilesMenu} triggerdrawerfunc={showSponsorFilesMenuFunc}  data={sponsorFiles} OnClick={showSponsorFilesMenu}/>

                </Grid>

                <PanelBottomBar language={changeLocale} close={props.triggerdrawer(false)} submit={onSubmit} />

            </Grid>

        </React.Fragment>
        </div>
        </div>

    );

}

