import React, {Component, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import * as constants from "../../Panel/common/Constants"
import Loader from "../../common/Loader";
import {NavLink} from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";
import {createMedia} from "@artsy/fresnel";
import FrontEmptyDiv from "../../common/FrontEmptyDiv";
import PageProvider from "../PageContext";

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

export default function SpeakersIndividual(props){

    const {setSettingsWindow,settings} = useContext(PageProvider);
    const {t, i18n} = useTranslation();
    const [loading,setLoading] = useState(true);
    const [persons,setPersons] = useState([]);

        useEffect(() => {
            axios.post(constants.LocalUrl + `/data/get_all_speakers_data`)
                .then(res => {
                    const persons = res.data;
                    setPersons(persons)
                    setLoading(false)
                })
        },[])

        if (loading) {
            return <Loader/>
        }

        if (persons.length === 0) {
            return <FrontEmptyDiv className={"container agenda-wrapper agenda tab-content"}>
                <div dangerouslySetInnerHTML={{__html: t('front_agenda_no_data')}}></div>
                <br/>
                <br/>
                {/*<span dangerouslySetInnerHTML={{__html: this.props.t('front_agenda_no_data_speakers_1')}}></span>*/}
                {settings && settings.user && (settings.user.rolenames.includes("root") || settings.user.rolenames.includes("Administrator"))?<>
                <span dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_speakers_1')}}></span>
                <span className="set_pointer url_alike" onClick={()=>setSettingsWindow({activate:true,tab:5})}><b>{t("USERS")}</b></span>
                <span dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_speakers_2')}}></span><span className="set_pointer"><b>{t("SETTINGS")}</b></span>.</>:null}
            </FrontEmptyDiv>
        }



        return (

            <div style={{width: "100%", backgroundColor: "#fafafa"}}>
                <div className="container" id="speakers-individual" style={{maxWidth: "1473px", width: "100%"}}>

                    <MediaContextProvider>
                        <Media at='mobile'>
                            <header className="page_header" style={{margin:"20px 0",color:"rgba(0,0,0,0.87)"}}>{t('SPEAKERS')}</header>
                        </Media>
                    </MediaContextProvider>
                    <div className='SpeakersIndividual_wrapper'>
                    <div className='row' style={{justifyContent:"center"}}>

                        {persons.map(person =>


                                    <div key={"speakertr_"+person.id} className="speaker-element">
                                        <div className="networking-w-grid networking-w df ai-center">
                                            <div id={person.id} className="net-item">
                                                {/*<div className="top-sec df jc-sb ai-center">*/}
                                                {/*    /!*<div className="favorite">*!/*/}
                                                {/*    /!*    <a style={{float:'left'}}><StarOutlineIcon /></a>*!/*/}
                                                {/*    /!*</div>*!/*/}
                                                {/*    <div className="type" />*/}
                                                {/*</div>*/}
                                                <div className="avatar-info">
                                                    <NavLink key={"ttt"+person.id} as="a" aria-current="page" className="avatar"
                                                             to={constants.LocalUrl + "/profile/" + person.id}>
                                                        {/*<a aria-current="page" href="/profile/867" className="avatar">*/}
                                                        <div className="image df ai-center jc-center">
                                                            {person.user_img.length > 0 && person.user_img ?
                                                                <img src={person.user_img}/> :
                                                                <img src="https://dev.liveon.tech/uploads/p1.png"/>}
                                                        </div>
                                                        <div className="status offline"/>
                                                        {/*</a>*/}
                                                    </NavLink>
                                                    <div className="item-info">
                                                        <NavLink key={"tto"+person.id} style={{display:"block",marginBottom:"7px"}} as="a" className="name"
                                                                 to={constants.LocalUrl + "/profile/" + person.id}>{person.title}</NavLink>
                                                        <p className="text-alt" style={{
                                                            color: '#B1B1B1',
                                                            fontSize: '23px',
                                                            margin: '0',
                                                            width: '80%',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            lineHeight: 'unset',
                                                            marginBottom: '5px'
                                                        }}><small style={{fontSize: '20px',lineHeight:"1.2285em"}}>{person.jobtitle}</small></p>
                                                        <div className="company">{person.company}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                            )}
                    </div>
                    </div>

                </div>
            </div>

        );


}
