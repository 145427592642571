import React, {useEffect, useState} from 'react';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import * as constants from "../../../common/Constants"
import UserFields from "../../../common/UserFields";
import FileUploadComponent from "../../../common/FileUploadComponent";
import {useTranslation} from "react-i18next";
import Divider from "@material-ui/core/Divider";
import {FormHelperText} from "@material-ui/core";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import AutoCompleteField from "../../../../common/AutoCompleteField";
import UsersHook from "../UsersHook";

export default function NewUser(props)  {

    const {t, i18n} = useTranslation();
    const {
        error,
        user,
        optionsState,
        loading,
        getImage,
        // settings,
        handleCV,
        handleCheckbox,
        onRolesChange,
        onCountryChange,
        onTimezoneChange,
        handleUserCheckboxes,
        // updatePostsHandler,
        // changeLocale,
        onInputchange,
        onSubmit,
        hasError, errorMessage, setFormErrors } = UsersHook(props,'edit')


    // if(loading === true){
    //     return <Loader />
    // }


    return (
        <div>

            <div style={{display:'flex',justifyContent:'center'}}>
                <Grid className="registration_container backend" container spacing={4} style={{padding:'20px',maxWidth:'800px'}}>
                    <Grid item xs={12}>
                    <Typography variant="h3" style={{textAlign:'center',width:"100%"}} gutterBottom>
                        {t('New User')}
                    </Typography>
                        <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>

                    </Grid>
                    <Grid item xs={12}>
                        <Typography xs={12} variant="h3" style={{textAlign: 'left'}} gutterBottom>
                            {t('Information')}
                        </Typography>
                        <Divider light/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                            {t('User_image')}:
                            <span className="image_text_description">{t('upload_dimensions', {text: '200px * 200px'})}</span>
                        </Typography>
                        <FileUploadComponent style={{marginBottom:"100px"}} type="user_registration"
                                             getImage={getImage}
                                             notautoprocess={true}
                                             extra={{
                                                 action: constants.LocalUrl + '/data/addUserImg'
                                             }} fullwidth
                                             accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                             maxWidth={150} maxHeight={150}
                        />

                    </Grid>

                    <UserFields classname="user_backend_registration backend" type="user_backend" user={user} handleCountry={onCountryChange} handleTimezone={onTimezoneChange} handleUser={onInputchange} handleEditor={handleCV} handleCheckbox={handleUserCheckboxes} error={error}/>
                    <React.Fragment key={'right'}>

                        <Grid container spacing={3} style={{padding:'20px'}}>
                            <Grid item xs={12}>
                                <Typography xs={12} variant="h3" style={{textAlign:'left',marginTop:"20px"}} className="main_settings_section_title" gutterBottom>
                                    {t("Roles")}
                                </Typography>
                                <Divider />
                            </Grid>

                            {hasError('roler')?<FormHelperText style={{color:"#f44336"}}>{errorMessage('roler')}</FormHelperText>:""}

                            <Grid item xs={12}>

                                <AutoCompleteField
                                    // label={t('Roles')}
                                    placeholder={t("Choose a role...")}
                                    model={optionsState}
                                    data={user.roles}
                                    onChange={onRolesChange}
                                    type="single"
                                />

                            </Grid>

                        </Grid>
                    </React.Fragment>
                    <PanelBottomBar classes={"panel_utilities_element"} close={props.triggerdrawer(false)} submit={onSubmit}/>

                </Grid>
            </div>
        </div>
    );

}

