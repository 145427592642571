
import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import * as constants from "../../Panel/common/Constants"
import Loader from "../../common/Loader";
import {NavLink} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AirlineSeatFlatIcon from "@material-ui/icons/AirlineSeatFlat";
import {ReactSVG} from "react-svg";
import {useTranslation, withTranslation} from "react-i18next";
import {createMedia} from "@artsy/fresnel";
import FrontEmptyDiv from "../../common/FrontEmptyDiv";
import PageProvider from "../PageContext";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

export default function SponsorsIndividual () {

    const {setSettingsWindow,settings} = useContext(PageProvider);
    const [loading, setLoading] = useState(true);
    const [sponsors,setSponsors] = useState([]);
    const {t, i18n} = useTranslation();

    let social_icons = {
        'Facebook':{icon:<FacebookIcon/>,color:"#4267B2"},
        'LinkedIn':{icon:<LinkedInIcon/>,color:"#0077B5"},
        'Youtube':{icon:<YouTubeIcon/>,color:"#FF0000"},
        'Twitter':{icon:<TwitterIcon/>,color:"#1DA1F2"}
    }

    let category_icons = {
        1 : "/app/award-1.svg",
        2 : "/app/award-2.svg",
        3 : "/app/award-3.svg",
        4 : "/app/award-4.svg",
        5 : "/app/award-5.svg",
    };

    useEffect(() => {

        const loadPost = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            // setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(constants.LocalUrl + `/data/sponsor_all_full_data`);
            setSponsors(response.data);
            // console.log(response.data)


            // Closed the loading page
            setLoading(false);
        }

        loadPost();

    }, []);

    if(loading){
        return <Loader/>
    }

    if(Object.keys(sponsors).length === 0){
        return <FrontEmptyDiv id="empty_sponsor_indi" className={"container agenda-wrapper agenda tab-content"}>
            <div dangerouslySetInnerHTML={{__html: t('front_agenda_no_data')}}></div>
            <br/>
            <br/>
            {/*{console.log("settings.user",settings.user)}*/}
            {settings && settings.user && (settings.user.rolenames.includes("root") || settings.user.rolenames.includes("Administrator"))?<>
                <span dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_sponsors_1')}}></span><span className="set_pointer url_alike" onClick={()=>setSettingsWindow({activate:true,tab:4})}><b>{t("SPONSORS")}</b></span><span dangerouslySetInnerHTML={{__html: t('front_agenda_no_data_sponsors_2')}}></span><span className="set_pointer"><b>{t("SETTINGS")}</b></span>.
            </>:null}
        </FrontEmptyDiv>
    }


    return (
        <div style={{width:"100%",backgroundColor:"#fafafa"}}>
            <div className="container" id="sponsors-individual">
                <MediaContextProvider>
                    <Media at='mobile'>
                        <header className="page_header" style={{margin:"0 auto",marginBottom:"39px",marginTop:"12px",color:"rgba(0,0,0,0.87)"}}>{t('SPONSORS')}</header>
                    </Media>
                </MediaContextProvider>

                <div className="row" style={{marginTop:"20px"}}>
                    {Object.keys(sponsors).map((row) => (

                        row != "Small" ?
                            // <Grid container spacing={sponsors[row].size} style={{padding: '20px'}}
                            //                     className={row + "_sponsors_wrapper sponsor_container"}>

                            sponsors[row].map((sponsor) => (
                                sponsor.typos != "Small" ?
                                    <Grid item xs={sponsor.size} key={sponsor.id} className={"sponsor_stand_" + sponsor.typos + " " + row +"_sponsors_wrapper sponsor_container"}>
                                        <div className="card" style={{
                                            background: '#fff',
                                            borderRadius: '17px',
                                            boxShadow: '0 4px 4px #0000000a',
                                            marginBottom: '75px'
                                        }}>
                                            <button className="btn btn-primary sponsorList_category_indication"
                                                    type="button" style={{
                                                height: "63px",
                                                padding: '13px 31px',
                                                fontWeight: 'bold',
                                                borderColor: 'white',
                                                color: 'black',
                                                background: '#fff',
                                                marginTop: '-30px',
                                                marginRight: 'auto',
                                                marginLeft: 'auto',
                                                width: 'auto',
                                                position: 'relative',
                                                zIndex: 1,
                                                borderRadius: '30px',
                                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 7px',
                                                textTransform: 'uppercase'
                                            }}>

                                            {sponsor.category_title_single} {sponsor.category_icon ?
                                            <ReactSVG beforeInjection={(svg) => {
                                                svg.classList.add('svg-class-name');
                                                svg.setAttribute('style', 'width:35px');
                                                svg.setAttribute('style', 'height:35px');
                                                svg.setAttribute('fill', sponsor.category_color);
                                            }} src={category_icons[sponsor.category_icon]}/>
                                            : ""}

                                            </button>
                                            <div className="card-header" style={{
                                                borderRadius: '16px 16px 0 0',
                                                backgroundColor: 'white',
                                                textAlign: 'center',
                                                borderBottom: 'solid 1px var(--divider-color)'
                                            }}>

                                            <NavLink as="a" className="name"
                                                     to={constants.LocalUrl + "/sponsors/" + sponsor.id}>
                                                <img
                                                style={{
                                                    margin: '37px auto 60px',
                                                    textAlign: 'center',
                                                    maxWidth: '100%',
                                                    maxHeight: '180px'
                                                }} src={sponsor.logo}/>
                                            </NavLink>

                                            </div>
                                            <div className="card-body" style={{padding: 0, textAlign:"center"}}>
                                                {/*<p className="card-text" style={{*/}
                                                {/*    padding: '0 22px 0 22px',*/}
                                                {/*    margin: '0px 0px 30px',*/}
                                                {/*    color: '#000',*/}
                                                {/*    height: 'auto'*/}
                                                {/*}} dangerouslySetInnerHTML={{__html: sponsor.description}}/>*/}
                                                <button className="btn btn-primary sponsorList_sponsor_indication"
                                                        type="button" style={{
                                                    height: "63px",
                                                    padding: '13px 31px',
                                                    fontWeight: 'bold',
                                                    borderColor: 'white',
                                                    color: 'black',
                                                    background: '#fff',
                                                    marginTop: '-30px',
                                                    marginRight: 'auto',
                                                    marginLeft: 'auto',
                                                    width: 'auto',
                                                    position: 'relative',
                                                    zIndex: 1,
                                                    borderRadius: '30px',
                                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 7px',
                                                    textTransform: 'uppercase',
                                                    cursor: 'pointer'
                                                }}>
                                                    <NavLink as="a" className="name"
                                                             to={constants.LocalUrl + "/sponsors/" + sponsor.id}>{sponsor.title}</NavLink>

                                                </button>
                                                <div style={{textAlign: 'center', marginBottom: '20px'}}
                                                     className={"solo_sponsor_sponsor_info_wrapper"}>
                                                    <div className="solo_sponsor_sponsor_info">
                                                        {sponsor && sponsor.website ?
                                                            <a target="_blank" href={sponsor.website} style={{
                                                                borderBottom: '1px solid #efeeee',
                                                                borderTop: '1px solid #efeeee',
                                                                fontSize: '14px',
                                                                color: '#000',
                                                                padding: '18px 0',
                                                                display: 'inline-block',
                                                                width: '100%',
                                                                height: '100%'
                                                            }}>{t("visit_the_website")} <ExitToAppIcon style={{position:"relative",top:"-1px"}} /></a> : ""}
                                                        {sponsor && sponsor.email ?
                                                            <a target="_blank" href={"mailto:" + sponsor.email}
                                                               style={{
                                                                   borderBottom: '1px solid #efeeee',
                                                                   borderTop: '1px solid #efeeee',
                                                                   fontSize: '14px',
                                                                   color: '#000',
                                                                   padding: '18px 0',
                                                                   display: 'inline-block',
                                                                   width: '100%',
                                                                   height: '100%'
                                                               }}>{sponsor.email}</a> : ""}
                                                    </div>
                                                    <div className="solo_sponsor_sponsor_socials">
                                                        <ul>
                                                            {sponsor.socials && Object.keys(sponsor.socials).length > 0 && Object.keys(sponsor.socials).map((social,index) =>
                                                                sponsor.socials[social]?
                                                                    <li key={index}><a target="_blank"
                                                                           className={"social_icon_" + social}
                                                                           href={sponsor.socials[social]}>{social_icons[social].icon}</a>
                                                                    </li>:null
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid> : <></>
                            ))

                            // </Grid>
                            :

                            Object.keys(sponsors[row]).map((sponsor) => (
                                <Grid id={sponsor.id} container spacing={4} style={{padding: '20px',marginTop:"20px"}}
                                      className={"Small_sponsors_wrapper sponsor_container"}>
                                    <Grid item xs={4} className={"sponsor_stand_Small"}>
                                        <button className="btn btn-primary sponsorList_category_indication" type="button" style={{height:"63px",padding: '13px 31px', fontWeight: 'bold', borderColor: 'white', color: 'black', background: '#fff', marginTop: '-22px', marginRight: 'auto', marginLeft: 'auto', width: 'auto', position: 'relative', zIndex: 1, borderRadius: '30px', boxShadow: '0 4px 4px #0000000a', textTransform: 'uppercase'}}>
                                            {sponsor} {sponsors[row][sponsor][0]['category_icon']?
                                            <ReactSVG beforeInjection={(svg) => {
                                                svg.classList.add('svg-class-name');
                                                svg.setAttribute('style', 'width:35px');
                                                svg.setAttribute('style', 'height:35px');
                                                svg.setAttribute('fill', sponsors[row][sponsor][0]['category_color']);
                                            }} src={category_icons[sponsors[row][sponsor][0]['category_icon']]}/>
                                            :""}
                                        </button>
                                        <div className="card" style={{background: '#fff', borderRadius: '17px', boxShadow: '0 4px 4px #0000000',marginBottom:'40px'}}>

                                            {sponsors[row][sponsor].map((spon) => (
                                                <div className="card-header" style={{borderRadius: '16px 16px 0 0',backgroundColor: 'white', textAlign: 'center', borderBottom: 'solid 1px var(--divider-color)'}}>
                                                    <NavLink as="a" className="name" to={constants.LocalUrl+"/sponsors/"+sponsor.id}><img style={{margin: '60px auto', textAlign: 'center'}} src={spon.logo} /></NavLink>
                                                </div>
                                            ))}

                                        </div>
                                    </Grid>
                                </Grid>
                            ))



                    ))}
                </div>
            </div>
        </div>
    );


}

