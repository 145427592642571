import React, {useContext, useEffect, useState} from 'react';
import PageProvider from "../../PageContext";

import "../../datetimer.css"
import {useTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import InlineTranslate from "../../../common/Language/InlineTranslate";
// import WizardContext from "../../Wizard/WizardContext";

export default function DateTimer(){

    const {t, i18n} = useTranslation();
    const useCountdown = (targetDate) => {
        const countDownDate = new Date(targetDate).getTime();
        const [countDown, setCountDown] = useState(
            countDownDate - new Date().getTime()
        );

        useEffect(() => {
            const interval = setInterval(() => {
                setCountDown(countDownDate - new Date().getTime());
            }, 1000);

            return () => clearInterval(interval);
        }, [countDownDate]);

        return getReturnValues(countDown);
    };

    const getReturnValues = (countDown) => {
        // calculate time left
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        return [days, hours, minutes, seconds];
    };

    const {settings} = useContext(PageProvider);

    if(Object.keys(settings).length ==   0){
        return <CircularProgress />;
    }

    // console.log("EVENT_DATE")
    // console.log(Object.entries(settings))
    // console.log(settings.data.EVENT_DATE)

    // if(settings && settings.EVENT_DATE){
    // const[day,hour,minute,second] = useCountdown(settings.data.EVENT_DATE)
    // console.log("settings.EVENT_DATE",settings.EVENT_DATE)
// console.log("settings.data.EVENT_DATE",new Date().getTime())
    const[day,hour,minute,second] = useCountdown(settings.EVENT_DATE_OLD)
    // const[day,hour,minute,second] = useCountdown(new Date()"2022/06/29")
    // }

    return (
        <section id="datetimer_banner_widget" className="section align-center" style={{marginTop:"0px"}}>
            <div className="container datetimer_banner_widget">
                <h1 id="headline" style={{fontSize:"30px"}}>
                    {t("Event Starting In")}
                    {/*<InlineTranslate keyword={"Event Starting In"}></InlineTranslate>*/}
                </h1>
                <div id="countdown">
                    <ul style={{padding:"0"}}>
                        <li style={{display:"inline-block"}}><span style={{letterSpacing:"-0.05em"}} id="days">{day?day:"d"}</span><span>{t("days")}</span></li>
                        <li style={{display:"inline-block"}}><span style={{letterSpacing:"-0.05em"}} id="hours">{hour?hour:"d"}</span><span>{t("hours")}</span></li>
                        <li style={{display:"inline-block"}}><span style={{letterSpacing:"-0.05em"}} id="minutes">{minute?minute:"d"}</span><span>{t("minutes")}</span></li>
                        {/*<li><span id="seconds">{second}</span>seconds</li>*/}
                    </ul>
                </div>
                {/*<div id="content" className="emoji">*/}
                {/*    <span>🥳</span>*/}
                {/*    <span>🎉</span>*/}
                {/*    <span>🎂</span>*/}
                {/*</div>*/}
            </div>
        </section>



    )



}
