import React, {useContext, useEffect, useState} from 'react';
import PageProvider from "../PageContext";
import "../datetimer.css"
import {useTranslation} from "react-i18next";
import Loader from "../../common/Loader"

export default function DateTimer(){

    const {t, i18n} = useTranslation();
    const useCountdown = (targetDate) => {
        const countDownDate = new Date(targetDate).getTime();
        const [countDown, setCountDown] = useState(
            countDownDate - new Date().getTime()
        );

        useEffect(() => {
            const interval = setInterval(() => {
                setCountDown(countDownDate - new Date().getTime());
            }, 1000);

            return () => clearInterval(interval);
        }, [countDownDate]);

        return getReturnValues(countDown);
    };

    const getReturnValues = (countDown) => {
        // calculate time left

        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        if(days){
            return [days, hours, minutes, seconds];
        }else{
            return ["-", "-", "-", "-"];
        }
    };

    const {settings} = useContext(PageProvider);
    const[day,hour,minute,second] = useCountdown(settings.EVENT_DATE_OLD)

    if(Object.keys(settings).length == 0){
        return <Loader/>;
    }


    return (
        <section id="datetimer" className="section align-center" style={{marginTop:"30px"}}>
            <div className="container datetimer">
                <h1 id="headline">{t('Event Starting In:')}</h1>
                <div id="countdown">
                    <ul>
                        <li><span id="days">{day}</span>{t('days')}</li>
                        <li><span id="hours">{hour}</span>{t('hours')}</li>
                        <li><span id="minutes">{minute}</span>{t('minutes')}</li>
                    </ul>
                </div>
            </div>
        </section>

    )

}
