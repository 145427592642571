import request from '../../../utils/request'

function get(id) {
    return request({
        url:    `/data/user_full_data`,
        method: 'POST',
        data: {id:id}
    });
}

function all() {
    return request({
        url:    `/data/get_all_users`,
        method: 'POST',
    });
}

function speakers(locale) {
    return request({
        url:    `/data/get_all_speakers`,
        method: 'POST',
        data: {lang:locale}
    });
}

function coordinators(locale) {
    return request({
        url:    `/data/get_all_coordinators`,
        method: 'POST',
        data: {lang:locale}
    });
}


function create(data) {
    return request({
        url:    '/data/create_user',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        data: data

    });
}

function update(data) {
    return request({
        url:    '/data/update_user',
        method: 'POST',
        data: data

    });
}

const Functions = {
    update, create, get, all, speakers, coordinators //, update, delete, etc. ...
}

export default Functions;