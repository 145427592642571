import React, {useContext, useState} from "react";
import {Box, Button, Container, CssBaseline, TextField, Typography} from "@material-ui/core";
import {NavLink} from 'react-router-dom';
// import CTextField from "../../common/CTextField";
// import user from "./../../auth/user";
import "../../login.css"
import PageProvider from "../Page/PageContext"
import * as constants from "../Panel/common/Constants";
import Grid from "@material-ui/core/Grid";
import Logo from "../common/Logo";
import {useTranslation} from "react-i18next";

export default function RememberPassword(){


    const {settings} = useContext(PageProvider);
    const {t, i18n} = useTranslation();
    const [email,setEmail] = useState("");


    const handleEmail = (event) => {
        setEmail(event.target.value);
        // console.log(email,event.target,event.target.value)
    }

    const handleSubmit = (event) => {
        // event.preventDefault()
        // const formData = new FormData(event.currentTarget);

        // const loginCredentials = {
        //     email: formData.get('email'),
            // password: formData.get('password')
        // }

        // function authenticatedCallback() {
        //     let {from} = location.state || {from: {pathname: '/'}}
        //     history.replace(from)
        // }
// console.log(email)
        window.axios.post('/panel/password/email', {email:email}).then((response) => {
            // console.log(email)
            // user.authenticated(response.data, authenticatedCallback)
            // console.log('Logged successfully!')
        })
    }

    return (
        <Container maxWidth={"xs"} className="login_container">

            <CssBaseline/>
            <Box align="center">
                <Grid item xs={12} style={{borderBottom:"2px solid var(--divider-color)"}}>
                    <Box align="center">
                        <NavLink className="item" to={constants.LocalUrl + "/"} >
                            <Logo src={settings.logo} alt={t('alt_Logo')} height="75px"/>
                        </NavLink>
                    </Box>
                    <Typography variant="h5" style={{textAlign:'center',position:"relative",top:"0",fontSize:"24px",color:"rgb(33, 37, 41)",marginTop:"15px"}} gutterBottom>
                        {t('Reset Password')}
                    </Typography>
                </Grid>
            </Box>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>

                <Box component={"form"} style={{width:"100%",padding:"20px",marginTop:"0"}} onSubmit={handleSubmit}>
                    <Typography variant="h6" style={{textAlign:'left',marginLeft:"20px",marginBottom:"15px"}} gutterBottom>
                        {t("E-mail")}
                    </Typography>
                    <TextField
                        id="outlined-textarea"
                        placeholder=""
                        type="text"
                        style={{ width: '100%' }}
                        name="email"
                        // label=""
                        // value={props.value}
                        onChange={handleEmail}
                        variant="outlined"
                        fullWidth
                    />

                    <Box align="center">

                        <Button onClick={handleSubmit} style={{width:"80%",marginTop:"20px"}} id="close_button" variant="contained" type={"submit"} color="primary" component="span">{t('Send Password Reset Link')}</Button>
                        <br/>
                        <br/>
                        <span className="just_or">{t("or")}</span> <br/>
                    </Box>

                    <Grid item xs={12} style={{textAlign:"center"}}>

                        <NavLink className="url_button" as="a" to={constants.LocalUrl + "/app/login"}>
                            <Button className="login_register_button_wrapper" as="span" variant="outlined" className="item" style={{margin:"0 auto",marginTop:"13px",display:"block",padding:"5px 40px",borderRadius:"20px",borderColor:"rgba(0,0,0,0.87)"}}>
                                <Typography  className="login_register_button" align="center" variant="h6" style={{fontWeight:"500",fontSize:"15px",marginBottom:0}} gutterBottom>
                                    {t("Login")}
                                </Typography>
                            </Button>
                        </NavLink>

                        <br/>
                        <NavLink className="url_button" as="a" to={constants.LocalUrl + "/app/register"}>
                        <Button className="login_register_button_wrapper" as="span" variant="outlined" className="item" style={{margin:"0 auto",display:"block",padding:"5px 40px",borderRadius:"20px",borderColor:"rgba(0,0,0,0.87)"}}>
                            <Typography  className="login_register_button" align="center" variant="h6" style={{fontWeight:"500",fontSize:"15px",marginBottom:0}} gutterBottom>
                                {t("Register")}
                            </Typography>
                        </Button>
                        </NavLink>
                    </Grid>

                    <br />
                </Box>
            </Box>
        </Container>
    )
}

