import React, {useEffect, useState} from 'react';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import * as constants from "../../common/Constants";
import VerticalChart from "../../../common/VerticalChart"
import HorizontalBarChart from "../../../common/HorizontalBarChart"
import {useTranslation} from "react-i18next";
import CAccordionControlled from "../../../common/CAccordionControlled";
import {Box, Collapse, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CTabsAutoScroll from "../../../common/CTabsAutoScroll";
import StatCard from "../Dashboard/components/StatCard";
import {ReactSVG} from "react-svg";
import ReactDataGrid from "@inovua/reactdatagrid-community";

const useStyles = makeStyles({
    rootBlue: {
        width: '100%',
        background: '#17a2b8',
        color: '#FFFFFF'
    },
    rootGreen: {
        width: '100%',
        background: '#28a745',
        color: '#FFFFFF'
    },
    rootYellow: {
        width: '100%',
        background: '#ffc107',
        color: '#FFFFFF'
    },
    rootRed: {
        width: '100%',
        background: '#dc3545',
        color: '#FFFFFF'
    },
    footerDimmed: {
        backgroundColor: 'rgba(0,0,0,.15)',
        padding: 0,
        alignItems: "center"
    },
    footerButton: {
        color: '#FFFFFF',
        width: '100%',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    table: {
        minWidth: '100%',
        width: '100%',
    },
    rootWhite: {
        width: '100%',
        height: '244px',
        background: '#ffffff',
        color: '#000000',
        borderRadius: '10px',
        boxShadow: '0px 0px 2px 2px #5e5c9a1a',
    },
});

export default function Statistics() {
    const {t, i18n} = useTranslation();

    const [data, setData] = useState(null);
    const [registrations, setRegistrations] = useState([]);
    const classes = useStyles();
    const [gridRef, setGridRef] = useState(null);
    const rowStyle = ({data}) => {
        return null;
    }
    const gridStyle = {minHeight: 283, height: "100%"};
    const defaultPagination = 5;

    useEffect(() => {
        const loadItems = async () => {

            // Till the data is fetch using API
            // the Loading page will show.

            // Await make wait until that
            // promise settles and return its reult

            await axios.post(constants.LocalUrl + '/data/getGraphs').then(response => {
                setRegistrations(response.data);
                // After fetching data stored it in posts state.
                // console.log("////");
                // console.log(response.data);

            });

            await axios.post(constants.LocalUrl + '/data/statisticsData').then(response => {
                setData(response.data);
            });


        }

        // Call the function
        loadItems();
    }, []);

    const handleClick = (event) => {

        // console.log(event.target);
        // console.log(event.target.parentElement.name);
        let name = '';
        if (event.target.name && event.target.name.length > 0) {
            name = event.target.name;
        }
        if (event.target.parentElement.name && event.target.parentElement.name.length > 0) {
            name = event.target.parentElement.name;
        }
        // console.log(name);
        window.open('/panel/stats/' + name, '_blank');
    }

    const tabsUsers = {
        titles: [
            t("Registrations per day"),
            t("View logged in users")
        ],
        contents: [
            <VerticalChart registrations={registrations} button_text={t('Export graph')}/>,
            <>
                {
                    data && data.users_logged_in_detailed != undefined && Object.values(data.users_logged_in_detailed).length>0
                    ? <ReactDataGrid
                            theme='green-light'
                            onReady={setGridRef}
                            showCellBorders='horizontal'
                            rowHeight='auto'
                            nativeScroll={true}
                            rowStyle={rowStyle}
                            idProperty="__rowNum__"
                            style={gridStyle}
                            columns={[
                                {name: 'name', defaultFlex: 4, header: t('Users Attended')},
                                {name: 'minutes', defaultFlex: 1, header: t('Minutes')},
                            ]}
                            pagination={Object.values(data.users_logged_in_detailed).length>defaultPagination}
                            dataSource={Object.values(data.users_logged_in_detailed)}
                            defaultLimit={defaultPagination}
                            enableKeyboardNavigation={false}
                        />
                    : <ListItem><span style={{fontSize: '16px'}}>{t('no stats')}</span></ListItem>
                }

                {/*<Table size="small">*/}
                {/*    <TableHead>*/}
                {/*        <TableRow>*/}
                {/*            <TableCell style={{fontWeight: "bold"}}>{t('User')}</TableCell>*/}
                {/*            <TableCell align="right" style={{fontWeight: "bold"}}>{t('Minutes')}</TableCell>*/}
                {/*        </TableRow>*/}
                {/*    </TableHead>*/}
                {/*    <TableBody>*/}
                {/*        {data && data.users_logged_in_detailed != undefined ? Object.values(data.users_logged_in_detailed).map((user) => (*/}
                {/*            <TableRow key={user.name}>*/}
                {/*                <TableCell component="th" scope="row">{user.name}</TableCell>*/}
                {/*                <TableCell*/}
                {/*                    align="right">~{Math.round(user.last_connected / 60)} {t('minutes_short')}</TableCell>*/}
                {/*            </TableRow>*/}
                {/*        )) : <span>{t('no stats')}</span>}*/}
                {/*    </TableBody>*/}
                {/*</Table>*/}
            </>
        ],
    };
    const tabsSponsors = {
        titles: [t("Sponsor Visits graph")],
        contents: [<HorizontalBarChart registrations={registrations} button_text={t('Export graph')}/>],
    };

    if (Object.keys(registrations).length === 0) {
        return "";
    }

    if (!data) {
        return null;
    }

    const tabs = {
        titles: [t('statitistics_users_title'), t('statistics_sessions_section_title'), t('statistics_sponsors_section_title')],
        contents: [
            <>
                <Grid
                    container
                    direction="row"
                    spacing={1}
                    style={{padding: 1}}
                >
                    <Grid item xs={4}>
                        <StatCard className={classes.rootWhite + " start_card_wrapper"}
                                  style={{backgroundColor: '#FFF2CC', border: '2px solid #D2D1D2'}}
                                  number={data.users_registered} number_title={t('dashboard_users_registered')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StatCard className={classes.rootWhite + " start_card_wrapper"}
                                  style={{backgroundColor: '#FBE5D5', border: '2px solid #D2D1D2'}}
                                  number={data.users_logged_in_at_least_once}
                                  number_title={t('dashboard_users_logged_in_at_least_once')}
                                  percentage={data.users_logged_in_at_least_once_perc}
                                  percentage_title={t('dashboard_users_logged_in_at_least_once_perc')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <StatCard className={classes.rootWhite + " start_card_wrapper"}
                                  style={{backgroundColor: '#DAE2F3', border: '2px solid #D2D1D2'}}
                                  number={data.users_logged_in} number_title={t('dashboard_users_logged_in')}
                                  percentage={data.users_logged_in_perc}
                                  percentage_title={t('dashboard_users_logged_in_perc')}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button className="stat_button" onClick={handleClick} variant="outlined" name="attendees"
                                endIcon={<GetAppOutlinedIcon/>}>
                            {t('EXPORT ATTENDEES')}
                        </Button>
                    </Grid>
                    {/*<Grid item xs={4}>*/}
                    {/*    <Button className="stat_button" onClick={handleClick} variant="outlined" name="loggedin" endIcon={<GetAppOutlinedIcon />}>*/}
                    {/*        {t('EXPORT LOGGED IN')}*/}
                    {/*    </Button>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <p>&nbsp;</p>
                    </Grid>
                    <Grid item xs={12}>
                        <CAccordionControlled
                            titles={tabsUsers.titles}
                            contents={tabsUsers.contents}
                        ></CAccordionControlled>
                    </Grid>
                </Grid>
            </>,
            <>
                <Grid container spacing={3} style={{margin: '0px', padding: '0px', maxWidth: '100%'}}>

                    {/*<Grid item xs={12}>*/}
                    {/*    <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left',marginTop:"20px"}} className="main_settings_section_title" gutterBottom>*/}
                    {/*        {t('statistics_sessions_section_title')}*/}
                    {/*    </Typography>*/}
                    {/*    <Divider />*/}
                    {/*</Grid>*/}

                    <Grid item xs={4}>
                        <Button className="stat_button" onClick={handleClick} variant="outlined" name="attendance"
                                endIcon={<GetAppOutlinedIcon/>}>
                            {t('export attendance')}
                        </Button>
                    </Grid>

                    <Grid container spacing={0} style={{padding: 1}}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={10}><b></b></TableCell>
                                            <TableCell><b style={{fontSize: '18px'}}>{t('Session')}</b></TableCell>
                                            <TableCell align="center"><b style={{fontSize: '18px'}}>{t('Users Attended')}</b></TableCell>
                                            <TableCell align="center"><b style={{fontSize: '18px'}}>{t('Users Avg Time')}</b></TableCell>
                                            <TableCell align="right"><b style={{fontSize: '18px'}}>{t('Users Subscribed')}</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.agenda.map((agendaSession) => (
                                            <AgendaRow key={agendaSession.id} row={agendaSession}/>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                </Grid>
            </>,
            <>
                <Grid container spacing={3} style={{margin: '0px', padding: '0px', maxWidth: '100%'}}>

                    {/*<Grid item xs={12}>*/}
                    {/*    <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left',marginTop:"20px"}} className="main_settings_section_title" gutterBottom>*/}
                    {/*        {t('statistics_sponsors_section_title')}*/}
                    {/*    </Typography>*/}
                    {/*    <Divider />*/}
                    {/*</Grid>*/}

                    <Grid item xs={12} style={{padding: '1px', margin: 0, maxWidth: '100%'}}>
                        <CAccordionControlled
                            titles={tabsSponsors.titles}
                            contents={tabsSponsors.contents}
                        ></CAccordionControlled>
                    </Grid>

                    <Grid item xs={4}>
                        <Button className="stat_button" onClick={handleClick} variant="outlined"
                                name="sponsors_executive_actions" endIcon={<GetAppOutlinedIcon/>}>
                            {t('export sponsor executive actions')}
                        </Button>
                    </Grid>

                    <Grid item xs={4}>
                        <Button className="stat_button" onClick={handleClick} variant="outlined"
                                name="sponsor_files_actions" endIcon={<GetAppOutlinedIcon/>}>
                            {t('export sponsor files actions')}
                        </Button>
                    </Grid>

                    <Grid container spacing={0} style={{marginTop: "30px", padding: 1}}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={10}><b></b></TableCell>
                                            <TableCell><b style={{fontSize: '18px'}}>{t('Sponsor')}</b></TableCell>
                                            <TableCell align="right"><b style={{fontSize: '18px'}}>{t('Total Visits')}</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.sponsors.map((sponsor) => (
                                            <SponsorRow key={sponsor.id} row={sponsor}/>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                </Grid>
            </>
        ],
    };

    return (
        <>
            <CTabsAutoScroll
                titles={tabs.titles}
                contents={tabs.contents}
            ></CTabsAutoScroll>
        </>

    );

}

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function AgendaRow(props) {
    const {t, i18n} = useTranslation();
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const [gridRef, setGridRef] = useState(null);
    const rowStyle = ({data}) => {
        return null;
    }
    const gridStyle = {minHeight: 283, height: "100%"};
    const defaultPagination = 5;

    return (
        <React.Fragment>
            <TableRow key={row.name}>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row"><span style={{fontSize: '16px'}}>{row.title}</span></TableCell>
                <TableCell align="center"><span style={{fontSize: '16px'}}>{row.users_attended}</span></TableCell>
                <TableCell align="center"><span style={{fontSize: '16px'}}>{row.users_avg_time} {t('minutes_short')}</span></TableCell>
                <TableCell align="right"><span style={{fontSize: '16px'}}>{row.users_subscribed}</span></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{padding: 0}} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={0}>
                            {/*<Typography variant="h6" gutterBottom component="div">*/}
                            {/*    Stats*/}
                            {/*</Typography>*/}
                            {
                                row.stats != undefined && Object.values(row.stats).length > 0 ?
                                <ReactDataGrid
                                    theme='green-light'
                                    onReady={setGridRef}
                                    showCellBorders='horizontal'
                                    rowHeight='auto'
                                    nativeScroll={true}
                                    rowStyle={rowStyle}
                                    idProperty="__rowNum__"
                                    style={gridStyle}
                                    columns={[
                                        {name: 'name', defaultFlex: 4, header: t('Users Attended')},
                                        {name: 'minutes', defaultFlex: 1, header: t('Minutes')},
                                    ]}
                                    pagination={Object.values(row.stats).length>defaultPagination}
                                    dataSource={Object.values(row.stats)}
                                    defaultLimit={defaultPagination}
                                    enableKeyboardNavigation={false}
                                />
                                : <ListItem button ><span style={{fontSize: '16px'}}>{t('no stats')}</span></ListItem>
                            }
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function SponsorRow(props) {
    const {t, i18n} = useTranslation();
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const [gridRef, setGridRef] = useState(null);
    const rowStyle = ({data}) => {
        return null;
    }
    const gridStyle = {minHeight: 283, height: "100%"};
    const defaultPagination = 5;

    return (
        <React.Fragment>
            <TableRow key={row.name}>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell align="left" component="th" scope="row"><span style={{fontSize: '16px'}}>{row.title}</span></TableCell>
                <TableCell align="right"><span style={{fontSize: '16px'}}>{row.visits}</span></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{padding: 0}} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={0}>
                            {/*<Typography variant="h6" gutterBottom component="div">*/}
                            {/*    Stats*/}
                            {/*</Typography>*/}

                            {
                                row.executives != undefined && Object.values(row.executives).length > 0 ?
                                <ReactDataGrid
                                    theme='green-light'
                                    onReady={setGridRef}
                                    showCellBorders='horizontal'
                                    rowHeight='auto'
                                    nativeScroll={true}
                                    rowStyle={rowStyle}
                                    idProperty="__rowNum__"
                                    style={gridStyle}
                                    columns={[
                                        {name: 'name', defaultFlex: 3, header: t('Executive')},
                                        {name: 'connects', defaultFlex: 2, header: t('Connects')},
                                        {name: 'chats', defaultFlex: 1, header: t('Chats')},
                                        {name: 'videocalls', defaultFlex: 1, header: t('Videocalls')},
                                    ]}
                                    pagination={Object.values(row.executives).length>defaultPagination}
                                    dataSource={Object.values(row.executives)}
                                    defaultLimit={defaultPagination}
                                    enableKeyboardNavigation={false}
                                />
                                : <ListItem button ><span style={{fontSize: '16px'}}>{t('no executives')}</span></ListItem>
                            }

                            {
                                row.files != undefined && Object.values(row.files).length > 0 ?
                                <ReactDataGrid
                                    theme='green-light'
                                    onReady={setGridRef}
                                    showCellBorders='horizontal'
                                    rowHeight='auto'
                                    nativeScroll={true}
                                    rowStyle={rowStyle}
                                    idProperty="__rowNum__"
                                    style={gridStyle}
                                    columns={[
                                        {name: 'title', defaultFlex: 2, header: t('File')},
                                        {name: 'views', defaultFlex: 1, header: t('Views')},
                                        {name: 'briefcase', defaultFlex: 1, header: t('In briefcase')},
                                    ]}
                                    pagination={Object.values(row.files).length > defaultPagination}
                                    dataSource={Object.values(row.files)}
                                    defaultLimit={defaultPagination}
                                    enableKeyboardNavigation={false}
                                />
                                : <ListItem button><span style={{fontSize: '16px'}}>{t('no files')}</span></ListItem>
                            }

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
