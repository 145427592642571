import React, {useEffect, useState} from 'react';
import * as constants from "../../../../../Panel/common/Constants";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import Divider from "@material-ui/core/Divider";


export default function EditPageLink(props) {

    const {t, i18n} = useTranslation();
    const [link, setLink] = useState([]);
    const [linkLocale, setLinkLocale] = useState([]);
    const [formErrors, setFormErrors] = useState(null);
    const [localeLanguage, setLocaleLanguage] = useState(i18n.language);

    function changeLanguage () {

        let flags = {el:"/app/greece-flag-round.png",en:"/app/united-kingdom-flag-round.png"}
        const setLanguage = (value) =>{
            setLocaleLanguage(value)
        }

        return(
            <div className="language_change_component">
                <span className={localeLanguage == 'el' ? 'active' : ''} onClick={() => setLanguage('el')}>
                {/*    <ReactSVG beforeInjection={(svg) => {*/}
                {/*    svg.classList.add('svg-class-name');*/}
                {/*    svg.setAttribute('style', 'width:20px');*/}
                {/*}} src={constants.LocalUrl + flags.el}/>*/}
                    <img src={flags.el} alt=""/>
                </span>
                <span className={localeLanguage == 'en' ? 'active' : ''} onClick={() => setLanguage('en')}>
                {/*    <ReactSVG beforeInjection={(svg) => {*/}
                {/*    svg.classList.add('svg-class-name');*/}
                {/*    svg.setAttribute('style', 'width:24px');*/}
                {/*}} src={constants.LocalUrl + flags.en}/>*/}
                    <img src={flags.en} alt=""/>
                </span>
            </div>
        )
    }

    useEffect(() => {
        setLocaleData(link);
    },[localeLanguage])

    const setLocaleData = (data) => {
        let localeData = {};
        Object.keys(data).map(value => {

            if(typeof data[value] === 'object' && data[value] !== null && value != "data"){
                // console.log("a",value,data[value])
                localeData[value] = data[value][localeLanguage];
            }else{
                // console.log("b",Object.keys(data[value]).length,value,data[value])
                localeData[value] = data[value];
            }
        })
        // console.log("localeData",localeData,localeLanguage)
        setLinkLocale(localeData)
    }

    const handleLinks = (event) => {
        event.persist();

        if(typeof link[event.target.name] === 'object'){
            // console.log('object')

            setLink((prevState) => ({
                ...prevState,
                [event.target.name]:{
                    ...prevState[event.target.name],
                    [localeLanguage]:event.target.value,
                }
            }));
        }else{
            // console.log('nope')
            setLink((prevState) => ({
                ...prevState,
                [localeLanguage]: event.target.value,
            }));

        }
        setLinkLocale((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

    }

    const onSubmitForm = () => {

        const loadItems = async () => {
            await axios.post(
                constants.LocalUrl + '/data/footer_link_edit', {id: props.link_id, data: linkLocale, lang: localeLanguage}).then(response => {

                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                }
                else if (response.data.status == 'ok') {
                    // console.log(response)

                    let index_id;
                    const i = props.posts.map((single_post, index) => {
                            // console.log(single_post.id,response.data.data.id);
                            if (single_post.id == response.data.data.id) {
                                index_id = index;
                                return true;
                            }
                        }
                    );


                    let items = [...props.posts];
                    let item = {...items[index_id]};

                    item.name = response.data.data.name;
                    item.title = response.data.data.name;

                    items[index_id] = item;
                    // console.log("sss");
                    // console.log(items);
                    // console.log(index_id);
                    props.triggerdataload(items);
                    props.triggerdrawerfunc(false);
                }

            });


        }

        loadItems();

    }

    useEffect(() => {


        axios.post(
            constants.LocalUrl + '/data/footer_link_get', {id: props.link_id}).then(response => {

            // console.log(response)
            setLink(response.data);
            setLocaleData(response.data);
        });


    }, [])

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    return (
        <div>
            {/*<Grid container spacing={3} style={{padding: '20px'}}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <CloseIcon onClick={props.triggerdrawer(false)}*/}
            {/*                   style={{position: 'absolute', right: '9px', top: '9px'}}/>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            {/*{changeLanguage()}*/}
            <Typography aligncenter variant="h3" style={{textAlign: 'center'}} gutterBottom>
                {t('Edit URL')}
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
            <Grid container spacing={3} style={{padding: '20px'}}>

                <Grid item xs={12}>
                    <Typography variant="h6" style={{display: 'block'}} gutterBottom>
                        {t('Name')}:
                    </Typography>
                    <TextField
                        id="outlined-multiline-flexible"
                        placeholder=""
                        style={{width: '100%'}}
                        name='name'
                        onChange={handleLinks}
                        variant="outlined"
                        value={linkLocale.name}
                        required error={hasError('name')} helperText={errorMessage('name')}
                    />
                    <br/><br/>
                    <Typography variant="h6" style={{display: 'block'}} gutterBottom>
                        {t('URL')}:
                    </Typography>
                    <TextField
                        id="outlined-multiline-flexible"
                        placeholder=""
                        defaultValue=""
                        style={{width: '100%'}}
                        name='data'
                        // label="Stream stopped message"
                        value={linkLocale.data}
                        onChange={handleLinks}
                        variant="outlined"
                        required error={hasError('data')} helperText={errorMessage('data')}
                    />
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*    <Button id="close_button" onClick={onSubmitForm} variant="contained" color="primary"*/}
                {/*            component="span">{t('SAVE')}</Button>*/}
                {/*</Grid>*/}
                <Grid className="panel_utilities_element" item xs={12}>
                    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)} >{t("go_back")}</Button>
                    <div className="right_part">
                        {changeLanguage()}
                        <Button id="close_button" variant="contained" color="primary" onClick={onSubmitForm} component="span">{t('Save')}</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}