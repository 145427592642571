import React, {useEffect, useState} from 'react';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import FileUploadComponent from "../../../common/FileUploadComponent";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import * as constants from "../../../common/Constants"
import UserFields from "../../../common/UserFields"
import {useTranslation} from "react-i18next";
import {FormHelperText} from "@material-ui/core";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import AutoCompleteField from "../../../../common/AutoCompleteField";
import UsersHook from "../UsersHook";


export default function EditUser(props) {

    const {t, i18n} = useTranslation();

    const {
        error,
        user,
        optionsState,
        loading,
        handleCV,
        onRolesChange,
        onCountryChange,
        onTimezoneChange,
        handleUserCheckboxes,
        // changeLocale,
        onInputchange,
        onSubmit,
        hasError, errorMessage, setFormErrors
    } = UsersHook(props, 'edit');

    if (loading === true || Object.keys(user).length == 0) {
        return <Loader/>
    }
    return (


        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Grid className="registration_container backend" container spacing={4}
                  style={{padding: '0', maxWidth: '800px'}}>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{textAlign: 'center'}} gutterBottom>
                        {t('Edit User')}
                    </Typography>

                    <Typography variant="subtitle1" style={{textAlign: 'center'}} gutterBottom>
                        <span
                            style={{fontSize: "21px", fontStyle: "italic"}}>{user && user.name ? user.name : ""}</span>
                    </Typography>
                    <Divider style={{backgroundColor: "#39BB0F", marginBottom: "15px"}}/>
                </Grid>

                {/*{user.onboarding_imported_url}*/}

                <Grid item xs={12}>
                    <Typography xs={12} variant="h3" style={{textAlign: 'left'}} gutterBottom>
                        {t('Information')}
                    </Typography>

                    <Divider light/>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" style={{display: 'block', marginBottom: "15px", marginLeft: "20px"}}
                                gutterBottom>
                        {t('User_image')}:
                        <span
                            className="image_text_description">{t('upload_dimensions', {text: '200px * 200px'})}</span>
                    </Typography>
                    <FileUploadComponent style={{marginBottom: "100px"}} image={user.user_img ? user.user_img : ''}
                                         type="user_registration"
                                         extra={{
                                             id: props.id,
                                             action: constants.LocalUrl + '/data/addUserImg'
                                         }} fullwidth
                                         accept={constants.acceptedUploadMimeGroups.images}
                                         maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                                         maxWidth={150} maxHeight={150}
                    />

                </Grid>

                <UserFields classname="user_backend_registration" type="user_backend" user={user}
                            handleUser={onInputchange}
                            handleCountry={onCountryChange} handleTimezone={onTimezoneChange}
                            handleEditor={handleCV} handleCheckbox={handleUserCheckboxes} error={error}/>


                <Grid item xs={12}>

                    <Typography xs={12} variant="h3" style={{textAlign: 'left', marginTop: "20px"}}
                                className="main_settings_section_title" gutterBottom>
                        {t("Roles")}
                    </Typography>
                    <Divider/>

                    <Divider light/>

                    {hasError('roler') ?
                        <FormHelperText style={{color: "#f44336"}}>{errorMessage('roler')}</FormHelperText> : ""}
                    <AutoCompleteField
                        // label={t('Roles')}
                        placeholder={t("Choose a role...")}
                        model={optionsState}
                        data={user.roles}
                        onChange={onRolesChange}
                        type="single"
                    />

                </Grid>


                <PanelBottomBar classes={"panel_utilities_element"} close={props.triggerdrawer(false)}
                                submit={onSubmit}/>

            </Grid>
        </div>

    );

}

