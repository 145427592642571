import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import * as constants from "../../../common/Constants"
import CTextField from "../../../common/CTextField";
import {useTranslation} from "react-i18next";
import {ChromePicker} from "react-color";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlat';
import {ReactSVG} from "react-svg";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PanelBottomBar from "../../../../common/PanelBottomBar";

export default function LoadedSponsor(props)  {

    const {t, i18n} = useTranslation();
    const [sponsorid, setSponsorid] = useState(null);
    const [sponsor, setSponsor] = useState([]);
    const [sponsorcategories, setSponsorcategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sponsorcategory, setSponsorcategory] = useState('');
    const [formErrors, setFormErrors] = useState(null);

    const handleChange = (event) => {
        setSponsorcategory(event.target.value);
        // console.log(sponsorcategory);
        // console.log(event.target.value);
        event.persist();

        setSponsor((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    const thid =  props;
    // useEffect(() => {
    //     const loadSponsor = async () => {
    //         setSponsorid(props.props);
    //         // Till the data is fetch using API
    //         // the Loading page will show.
    //         setLoading(true);
    //
    //         // Await make wait until that
    //         // promise settles and return its reult
    //         const response = await axios.post(`/Lite/data/sponsor_full_data`, {id: thid}).then(response => {
    //             console.log('response', response);
    //             // After fetching data stored it in posts state.
    //             setSponsor(response.data[0]);
    //
    //             // Closed the loading page
    //             setLoading(false);
    //         });
    //
    //
    //
    //     }
    //
    //     // Call the function
    //     loadSponsor();
    // }, []);

    const onSubmitForm = () => {
        const loadItems = async () => {
            await axios.post(
                constants.LocalUrl + '/data/create_sponsor_category',sponsor).then(response => {

                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                }else {
                    // console.log(response.data);

                    props.triggerdataload(response.data[0]);
                    props.triggerdrawerfunc(false);
                }
            });
        }

        // Call the function
        loadItems();
    };

    const onInputchange = (event) => {
        event.persist();

        setSponsor((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

        // if(event.target.name == 'category_id'){
        //     setSponsorcategory(event.target.value);
        // }
    };

    const createSponsorCategory = async (event) => {
        // console.log(event);
        var newcategory = $('#outlined-basic').val();
        await axios.post(
            constants.LocalUrl + '/data/create_sponsor_category',{cat:newcategory}).then(response => {

            setSponsorcategories(response.data);

        });

    };

    const handleCategoryColor = (data) => {
        setSponsor((prevState) => ({
            ...prevState,
            category_color: data.hex,
        }));
    }

    let category_icons = {
        1 : "/app/award-1.svg",
        2 : "/app/award-2.svg",
        3 : "/app/award-3.svg",
        4 : "/app/award-4.svg",
        5 : "/app/award-5.svg",
    };

    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            right: '9px',
            top: '0px'
        },
    }));

    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();

    if(loading === true || sponsor.length == 0){
        // return <CircularProgress />
    }

    // let decodedHtml;
    // decodedHtml =  sponsor.description.replace(/&lt;/g, '<').replace(/&gt;/g, '>')

    return (
        <div className="bottom_bar">

            <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('New Sponsor Category')}
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
            <div style={{display:'flex',justifyContent:'center'}}>
                <React.Fragment key={'right'}>
                    <Grid container spacing={4} style={{padding:'20px',maxWidth:'800px'}}>

                        <Grid item xs={6}>
                            <CTextField handler={onInputchange} name='title_single' label={t('Title single')} required error={hasError('title_single')} helperText={errorMessage('title_single')}/>
                        </Grid>

                        <Grid item xs={6}>
                            <CTextField handler={onInputchange} name='title_plural' label={t('Title plural')} required error={hasError('title_plural')} helperText={errorMessage('title_plural')}/>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="h6" style={{textAlign:'left',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                                {t('Sponsor_category_color')}:
                            </Typography>
                            <ChromePicker color={sponsor.category_color} onChange={handleCategoryColor} disableAlpha />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="h6" style={{display: 'block',borderRadius:'23px',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                                {t('Sponsor_category_icons')}
                            </Typography>

                            <Select style={{borderRadius:'23px'}} onChange={onInputchange} id="grouped-native-select"  variant="outlined"  name="category_icon" className="category_icon" IconComponent={ExpandMoreIcon}>
                                {Object.keys(category_icons).map((data,index) => (
                                    <MenuItem key={"yy"+index} value={data}>
                                        <ReactSVG beforeInjection={(svg) => {
                                            svg.classList.add('svg-class-name');
                                            svg.setAttribute('style', 'width:35px');
                                            // svg.setAttribute('style', 'width:35px;position:absolute;top:12px');
                                        }} src={category_icons[data]}/>
                                    </MenuItem>
                                ))}
                            </Select>

                        </Grid>

                        <PanelBottomBar close={props.triggerdrawer(false)} submit={onSubmitForm}/>

                        {/*<Grid id="panel_utilities_element" className="panel_utilities_element" item xs={12}>*/}
                        {/*    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)} className={close_button.root}>{t("go_back")}</Button>*/}
                        {/*    <div className="right_part">*/}
                        {/*    <Button id="close_button" variant="contained" color="primary" onClick={onSubmitForm} component="span">{t('Save')}</Button>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}

                    </Grid>
                </React.Fragment>
            </div>
        </div>

    );

}

