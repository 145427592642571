import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Divider from "@material-ui/core/Divider";
import * as constants from "../../../../Panel/common/Constants"
import CTextField from "../../../../Panel/common/CTextField";
import Editor from "../../../../Panel/common/Editor";
import {useTranslation} from "react-i18next";
import DraggableSimplestList from "../../../../common/DraggableSimplestList";
import DateFnsUtils from "@date-io/date-fns";
import {DateTimePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import PageProvider from "../../../../Page/PageContext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import WizardContext from "../../../WizardContext";
import Loader from "../../../../common/Loader";
import {
    datepickerCancelLabelLocales,
    datepickerClearLabelLocales,
    datepickerLocales
} from "../../../../common/Language/DatepickerLocales";

const useedStyles = makeStyles({
    root: {
        color: "#39BB0F",
        "&$checked": {
            color: "#39BB0F"
        }
    }
});

export default function NewSchedule(props){

    const {t, i18n} = useTranslation();
    const {settings} = useContext(PageProvider);
    const {setStepButtonState,activatedSteps,setActivatedSteps} = useContext(WizardContext);
    // console.log(settings)
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let currentdate = `${year}-${month<10?`0${month}`:`${month}`}-${date}`;
    let minimumDate = new Date(settings.EVENT_DATE);
    let mdate = minimumDate.getDate();
    let mmonth = minimumDate.getMonth() + 1;
    let myear = minimumDate.getFullYear();
    let minDate = `${myear}-${mmonth<10?`0${mmonth}`:`${mmonth}`}-${mdate}`;


    let stime = new Date(settings.EVENT_DATE?settings.EVENT_DATE:null);
    const [formData, setformData] = useState({});
    const [formErrors, setFormErrors] = useState(null);

    const [speakers, setSpeakers] = useState([]);
    const [coordinators, setCoordinators] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkbox, setCheckbox] = useState({});

    const checkboxes_style = useedStyles();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small" className={checkboxes_style.root}/>;

    useEffect(() => {
        var obj_settings = {
            name: "newschedule",
            start_date:stime,
            end_date:stime,
            start_time:stime.getTime(),
            end_time:stime.getTime(),
            start_time_picker:stime.getTime(),
            end_time_picker:stime.getTime(),
        };
        setformData(obj_settings);
    },[settings])

    const container_styles = makeStyles({
        root: {
            padding: '40px',
        },
        fullList: {
            width: 'auto',
        },
    });

    const useStyles = makeStyles({
        list: {
            // width: '800px',
            padding:'40px'
        },
        fullList: {
            width: 'auto',
        },
    });

    const new_schedule = makeStyles((theme) => ({
        root: {
            display: 'block',
            '& > *': {
                margin: theme.spacing(1),
                width: theme.spacing(45),
                // height: theme.spacing(13),
            },
        },
        new_schedule: {
            // width:'100%',
            textAlign:'center'
        }
    }));


    const close_button = makeStyles((theme) => ({
        root: {
            position: 'absolute',
            right: '9px',
            top: '0px'
        },
    }));

    const onInputchange = (event) => {
        setformData({ ...formData, [event.target.name]: event.target.value });
        // console.log("edo");
        // console.log(formData);
    };

    const handleDescription = (data) => {

        // data.persist();
        setformData((prevState) => ({
            ...prevState,
            subtitle: data,
        }));

        // console.log(formData);
        // console.log("handleSettings");
    }

    const onSubmitForm = () => {
        const loadItems = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);

            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(
                constants.LocalUrl + '/data/create_schedule',formData).then(response => {
                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                }
                else if (response.data.status == 'ok') {

                    setStepButtonState(false);
                    setActivatedSteps([...activatedSteps,2])
                    let items = [...props.posts];
                    let item = {};
                    item.title = response.data.data.title;
                    item.startDate = response.data.data.startDate;
                    item.startTime = response.data.data.startTime;
                    item.id = response.data.data.id;
                    items.push(item)

                    // console.log("items",items)
                    props.triggerdataload(items);
                    props.ooo(false);
                }
                setLoading(false);
            });

            // After fetching data stored it in posts state.
            // setformData(response.data);

        }

        // Call the function
        loadItems();
    };

    const handleChange = (event) => {
        event.persist();
        setformData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.checked,
        }));
    };


    const onSponsorsChange = (event, values) => {
        event.persist();
        setformData(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                addsponsors: values      // update the value of specific key

            }))
    }


    const onSyntonistesChange = (event, values) => {
        event.persist();
        setformData(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                addsyntonistes: values      // update the value of specific key

            }))
    }

    const onSpeakersChange = (event, values) => {
        event.persist();
        setformData(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                addspeakers: values      // update the value of specific key

            }))
    }

    const return_options_sponsors = (sponsores) => {
        let arr = [];
        sponsors.map((e,index) =>{
            if(sponsores && sponsores.includes(e.id)){
                arr.push(sponsors[index]);
            }
        });
        return arr;
    }

    const return_options_speakers = (omilites) => {
        let arr = [];
        speakers.map((e,index) =>{
            if(omilites && omilites.includes(e.id)){
                arr.push(speakers[index]);
            }
        });
        return arr;
    }

    const return_options_syntonistes = (syntonistes) => {
        let arr = [];
        coordinators.map((e,index) =>{
            if(syntonistes && syntonistes.includes(e.id)){
                arr.push(coordinators[index]);
            }
        });
        return arr;
    }

    const saveScheduleSpeakersOrder = (event) => {

        setformData(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            ["speakersOrder"]: event      // update the value of specific key

        }))

    }


    const saveScheduleSyntonistesOrder = (event) => {

        setformData(prevState => ({
            // object that we want to update
            ...prevState,    // keep all other key-value pairs
            ["syntonistesOrder"]: event      // update the value of specific key

        }))

    }

    const handleStartTime = (event) => {

        let end_date_now = new Date(formData.end_time_picker);
        let start_date_now = new Date(event);
        // console.log("start_date_now",start_date_now,start_date_now.getTime(),event.getTime())
        if(event.getTime() >= end_date_now.getTime()){
            // console.log("sstart_date_now",start_date_now,start_date_now.getTime(),event.getTime())
            setformData((prevState) => ({
                ...prevState,
                end_time_picker: start_date_now.setHours(start_date_now.getHours(), start_date_now.getMinutes() + 1, 0, 0),
            }));
            // event.setDate(end_date_now);
        }

        setformData((prevState) => ({
            ...prevState,
            start_time: event.getTime(),
        }));

        setformData((prevState) => ({
            ...prevState,
            start_time_picker: event,
        }));

        //change
        // console.log("handleSettings");
        // console.log(formData);
        // console.log("handleSettings");
    }

    const handleEndTime = (event) => {

        let start_date_locale = new Date(formData.start_time_picker);
        let end_date_now = new Date(event);

        if(end_date_now.getTime() <= start_date_locale.getTime()){
            end_date_now.setHours(start_date_locale.getHours(), start_date_locale.getMinutes() + 1, 0, 0);
        }

        setformData((prevState) => ({
            ...prevState,
            end_time: end_date_now.getTime(),
        }));
        setformData((prevState) => ({
            ...prevState,
            end_time_picker: end_date_now,
        }));

        //change
        // console.log("handleSettings");
        // console.log(formData);
        // console.log("handleSettings");
    }

    useEffect(() => {

        window.axios.post(constants.LocalUrl + '/data/get_all_speakers').then((response) => {

            // this.setState({ sponsors: response.data});
            setSpeakers(response.data);
            // this.state.sponsors = response.data;
            // console.log(this.state.sponsors);
        });

        window.axios.post(constants.LocalUrl + '/data/get_all_coordinators').then((response) => {

            // this.setState({ sponsors: response.data});
            setCoordinators(response.data);
            // this.state.sponsors = response.data;
            // console.log(this.state.sponsors);
        });

        window.axios.post(constants.LocalUrl + '/data/get_all_sponsors').then((response) => {

            // this.setState({ sponsors: response.data});
            setSponsors(response.data);
            // this.state.sponsors = response.data;
            // console.log(this.state.sponsors);
        });

    }, []);

    const hasError = (field_name) => {
        return formErrors && formErrors[field_name] ? true : false;
    }

    const errorMessage = (field_name) => {
        return formErrors && formErrors[field_name] ? <div dangerouslySetInnerHTML={{__html: formErrors[field_name].join('<br/>')}}></div> : false;
    }

    const onInputTimechange = (event) => {
        event.persist();

        // console.log("-----");
        date = formData.start_date + " " + event.target.value;
        var timestamp_date = Math.floor(new Date(date).getTime() / 1000)
        // console.log("formData.start_date",formData.start_date);
        // console.log("event.target.value",event.target.value);
        // console.log(date);
        // console.log(timestamp_date);
        // console.log("-----");

        setformData((prevState) => ({
            ...prevState,
            [event.target.name]: timestamp_date,
        }));
        // console.log(formData);
    };

    // let video_types = [{id:'vimeo',title:'Vimeo'},{id:'youtube',title:'YouTube'},{id:'local',title:'LiveOn'},{id:'facebook',title:'Facebook'}]
    let video_types = [{id:'vimeo',title:'Vimeo'},{id:'youtube',title:'YouTube'}]


    var start = new Date(formData.start_time * 1000);
    var end = new Date(formData.end_time * 1000);
    var start_time = ('0' + start.getHours()).slice(-2) + ':' + ('0' + start.getMinutes()).slice(-2);
    var end_time = ('0' + end.getHours()).slice(-2) + ':' + ('0' + end.getMinutes()).slice(-2);

    if(Object.keys(settings).length == 0){
        return <Loader />
    }

    return (
        <div className="bottom_bar">

            <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('New Session')}
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>


            <div style={{display:'flex',justifyContent:'center'}}>
                <Grid container spacing={4} style={{padding:'20px',maxWidth:'800px'}}>

                    <Grid item xs={12}>
                        <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                            {t('Session Details')}
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <CTextField handler={onInputchange} name='title' label={t('Title')} required error={hasError('title')} helperText={errorMessage('title')}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Editor handler={handleDescription} label={t("Description")} name="subtitle"/>
                        {/*<CTextField handler={onInputchange} name='subtitle' label='Subject'/>*/}
                    </Grid>

                    <Grid item xs={6}>
                        <Typography style={{marginBottom:"15px",marginLeft:"20px"}} alignLeft variant="h6" gutterBottom>
                            {t('Start Date')}
                        </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={datepickerLocales[i18n.language]}>
                            <DateTimePicker
                                id="outlined-textarea"
                                inputVariant="outlined"
                                ampm={true}
                                label=""
                                value={formData.start_time_picker?formData.start_time_picker:stime}
                                required error={hasError('start_date')}
                                helperText={errorMessage('start_date')}
                                minDate={settings.EVENT_DATE}
                                maxDate={settings.EVENT_DATE_TO}
                                fullWidth
                                onChange={handleStartTime}
                                onError={console.log}
                                format="dd/MM/yyyy HH:mm"
                                cancelLabel={datepickerCancelLabelLocales[i18n.language]}
                                clearLabel={datepickerClearLabelLocales[i18n.language]}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{marginBottom:"15px",marginLeft:"20px"}} alignLeft variant="h6" gutterBottom>
                            {t('End Date')}
                        </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={datepickerLocales[i18n.language]}>
                            <DateTimePicker
                                id="outlined-textarea"
                                inputVariant="outlined"
                                ampm={true}
                                label=""
                                value={formData.end_time_picker?formData.end_time_picker:stime}
                                required error={hasError('start_date')}
                                helperText={errorMessage('start_date')}
                                minDate={settings.EVENT_DATE}
                                maxDate={settings.EVENT_DATE_TO}
                                fullWidth
                                onChange={handleEndTime}
                                onError={console.log}
                                format="dd/MM/yyyy HH:mm"
                                cancelLabel={datepickerCancelLabelLocales[i18n.language]}
                                clearLabel={datepickerClearLabelLocales[i18n.language]}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography xs={12} alignLeft variant="h3" style={{textAlign:'left'}} gutterBottom>
                            {t('Speakers & Coordinators')}
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className="draggable_autocomplete_wrapper" elevation={0} style={{
                            padding: 8,display:'inline-block',marginRight: '10px',width:'100%'}}>
                            <Typography alignLeft variant="h6" style={{display: 'block',margin:"11px 20px 17px"}} gutterBottom>
                                {t('Speakers')}
                            </Typography>
                            <div id="autocomplete_selector">
                                <Autocomplete
                                    multiple
                                    popupIcon={<ExpandMoreIcon />}
                                    id="checkboxes-tags-demo"
                                    options={speakers}
                                    disableCloseOnSelect
                                    onChange={onSpeakersChange}
                                    defaultValue={return_options_speakers(formData.speakers)}
                                    getOptionLabel={(option) => option.title}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                                color="#000000"
                                            />
                                            {option.title}
                                        </React.Fragment>
                                    )}
                                    style={{ }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label=""  placeholder={t("Choose a speaker...")} fullwidth/>
                                    )}
                                />
                                {formData.addspeakers && Object.keys(formData.addspeakers).length > 0?<h3 className="autocomplete_sorting_title">{t("Sorting")}</h3>:""}
                                <DraggableSimplestList  data={formData.addspeakers==='true'?formData.draggable_speakers:formData.addspeakers} save={saveScheduleSpeakersOrder}/>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={6}>
                        <Paper className="draggable_autocomplete_wrapper" elevation={0} style={{
                            padding: 8,display:'inline-block',marginRight: '10px',width:'100%'}}>
                            <Typography alignLeft variant="h6" style={{display: 'block',margin:"11px 20px 17px"}} gutterBottom>
                                {t('Coordinators')}
                            </Typography>
                            <div id="autocomplete_selector">
                                <Autocomplete
                                    multiple
                                    popupIcon={<ExpandMoreIcon />}
                                    id="checkboxes-tags-demo"
                                    options={coordinators}
                                    disableCloseOnSelect
                                    onChange={onSyntonistesChange}
                                    defaultValue={return_options_syntonistes(formData.syntonistes)}
                                    getOptionLabel={(option) => option.title}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                                color="#000000"
                                            />
                                            {option.title}
                                        </React.Fragment>
                                    )}
                                    style={{ }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label=""  placeholder={t("Choose a coordinator...")} fullwidth/>
                                    )}
                                />
                                {formData.addsyntonistes && Object.keys(formData.addsyntonistes).length > 0?<h3 className="autocomplete_sorting_title">{t("Sorting")}</h3>:""}
                                <DraggableSimplestList  data={formData.addsyntonistes==='true'?formData.draggable_syntonistes:formData.addsyntonistes} save={saveScheduleSyntonistesOrder}/>
                            </div>
                        </Paper>
                    </Grid>

                    <PanelBottomBar classes={"panel_utilities_element basicinfo"} close={props.triggerdrawer(false)} style={{justifyContent:"space-between"}} submit={onSubmitForm} />
                    {/*<Grid className="panel_utilities_element" item xs={12}>*/}
                    {/*    <Button variant="contained" color="secondary" onClick={props.triggerdrawer(false)} className={close_button.root}>{t("go_back")}</Button>*/}
                    {/*    <div className="right_part">*/}
                    {/*        <Button id="close_button" variant="contained" onClick={() => onSubmitForm()} color="primary" component="span">{t('SAVE')}</Button>*/}
                    {/*    </div>*/}
                    {/*</Grid>*/}

                </Grid>
            </div>
        </div>
    )

}