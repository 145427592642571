import React, {useContext, useEffect, useState} from 'react';
import './LiveonChat.css';
import "react-chat-elements/dist/main.css";
import LiveonChatWindow from "./LiveonChatWindow";
import axios from "axios";
import * as constants from "../../Panel/common/Constants";
import PageProvider from "../../Page/PageContext";
import ChatProvider from "../../Page/ChatContext";
import {CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const chat_icons = {
    SidebarChatIcon: "/images/panel/sidebar_chat.svg",
}

export default function LiveonChatWindows(props) {

    const {t, i18n} = useTranslation();
    const {settings} = useContext(PageProvider);
    const [myuser, setMyuser] = useState(null);
    const {conversations, setConversations, setReloadData, chatWindows, setChatWindows} = useContext(ChatProvider);
    const [channel, setChannel] = useState(null);


    function reloadConversations(callback) {
        return axios.post(constants.LocalUrl + '/data/chat_get_user_conversations')
            .then(res => {
                const conversationz = res.data;
                setConversations(conversationz);
            })
    }

    useEffect(() => {
        setMyuser(settings.user);
        function handleStatusChange(status) {
        }
        if (conversations.length == 0) {
            reloadConversations();
        }
        if (!channel) {
            var ch = window.Echo.private('chatlist.' + window.temp_user_id)
                .listen('.open-chat-window', (data) => {
                    reloadConversations().then(() => {
                        openChatWindow(data.user_id);
                    });
                })
                .listen('.reload-conversations', (data) => {
                    reloadConversations();
                });
            setChannel(ch);
        }
        return function cleanup() {
        };

    }, [])

    const openChatWindow = (user_id) => {
        let windows = chatWindows;
        if (windows.filter((e) => {
            return e.user_id == user_id
        }).length < 1) {
            setChatWindows((windows) => {
                windows = [...windows, {user_id: user_id}]
                return unique(windows, 'user_id');
            });
        }
    }

    const unique = (objArray, key) => {
        return [...new Map(objArray.map((item) => [item[key], item])).values()];
    }

    const closeChatWindow = (user_id) => {
        // console.log("user_id");
        // console.log(user_id);
        let cw = chatWindows.filter(e => e.user_id != user_id);
        setChatWindows(cw);
    }

    const strip = (html) => {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }


    if (window.temp_user_id && Object.values(conversations).length > 0 ) {
        return (
            <div id="chat-windows">
                {chatWindows.map(window => <LiveonChatWindow closeHandler={closeChatWindow}
                                                             key={'lcw-' + window.user_id} user_id={window.user_id}
                                                             conversation={Object.values(conversations).filter(e => e.other_user.id == window.user_id)[0]}
                />)}
            </div>
        )
    } else return null;

}
