import React, {Component} from 'react';

import {map} from 'lodash';

import FlatButton from '@material-ui/core/Button';
import FontIcon from '@material-ui/icons/House';

import awesome from 'font-awesome/css/font-awesome.css';
import styles from './Repeatable.css';

const plus = <FontIcon className={`${awesome.fa} ${awesome['fa-plus']}`}/>;
const minus = <FontIcon className={`${awesome.fa} ${awesome['fa-minus']}`}/>;
// console.log(plus);
// console.log(minus);

export default class Repeatable extends Component {

    static defaultProps = {
        initialCopies: 1,
        requiredCopies: 1,
        childrenRenderer: (index) => [],
        onAdd: (index) => undefined,
        onRemove: (index) => undefined,
        style: {}
    }

    constructor() {
        super();
        this.counter = 0;
        this.state = {
            items: {}
        };
    }

    componentDidMount() {
        this.createItems(this.props.initialCopies);
    }

    renderItems() {
        return map(
            this.state.items,
            (_, index) => (
                // <div>
                // {this.props.childrenRenderer(index)}
                // <FlatButton icon={minus} label="sss" onClick={this.removeItem(index)}>
                //     Minus
                // </FlatButton>
                // </div>
                <div key={`repeatable-item-${index}`} className={styles.Row}>
                    <div className={styles.Item}>
                        {this.props.childrenRenderer(index)}
                    </div>
                    <div
                        className={styles.ItemControls}
                        style={{display: index < this.props.requiredCopies ? 'none' : ''}}>
                        <FlatButton icon={minus} label="" onClick={this.removeItem(index)}>
                            Minus
                        </FlatButton>
                    </div>
                </div>
            )
        );
    }

    removeItem = (index) => () => {
        let items = { ...this.state.items };
        delete items[index];
        this.setState({ items });
        this.props.onRemove(index);
    }

    createItems = (num = 1) => {
        let items = { ...this.state.items };
        for (let index = 0; index < num; index++) {
            let key = this.counter++;
            items[key] = true;
        }
        this.setState({ items });
        return this.counter;
    }

    addItem = () => this.props.onAdd(this.createItems(1))

    render() {
        return (
            <div style={this.props.style}>
                {this.renderItems()}
                <div className={styles.Controls}>
                    <FlatButton  label="plus" onClick={this.addItem}>Plus</FlatButton>
                </div>
            </div>
        );
    }

}