import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {withStyles} from '@material-ui/core/styles';
import * as constants from "../../Panel/common/Constants";
import Tooltip from '@material-ui/core/Tooltip';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import VideocamIcon from '@material-ui/icons/Videocam';
import ContactsIcon from '@material-ui/icons/Contacts';
import DoneIcon from '@material-ui/icons/Done';
import ChatIcon from '@material-ui/icons/Chat';
import PageProvider from "../PageContext";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AirlineSeatFlatIcon from "@material-ui/icons/AirlineSeatFlat";
import {ReactSVG} from "react-svg";
import UserCard from "../../common/UserCard";
import Button from "@material-ui/core/Button";
import Loader from "../../common/Loader";
import {Navigate, NavLink, useNavigate, useSearchParams} from 'react-router-dom';
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";

export default function soloSponsor() {

    let [searchParams, setSearchParams] = useSearchParams();
    const {t, i18n} = useTranslation();
    const [sponsor, setSponsor] = useState([])
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [startVideoCall, setStartVideoCall] = useState(false)
    const [filesStatus, setFilesStatus] = useState({})
    const {putVideoCall, setPutVideoCall} = useContext(PageProvider);
    const navigate = useNavigate();

    const handleVideoCall = (data, uid) => {
        if (!window.temp_user_id || window.temp_user_id == 0) {
            navigate("/app/login");
            return;
        }
        // if (data === true) {
        //     setPutVideoCall({activate: true, uid: uid})
        // }
        axios.post(constants.LocalUrl + '/data/video_call_notify', {from: window.temp_user_id, to: uid, type: 'start'})
    }

    const handleConnectSponsor = () => {
        axios.post(
            constants.LocalUrl + "/data/connectToSponsor", {
                'sponsor_id': sponsor.id,
            }).then(response => {
                if(response.data.status === "ok"){
                    notify_sponsor_connected();
                }
        });
    }

    const handleUserSponsorConnect = (executive_id) => {
        if (!window.temp_user_id || window.temp_user_id == 0) {
            navigate("/app/login");
            return;
        }
        axios.post(
            constants.LocalUrl + "/data/connectUserSponsor", {
                'executive_id': executive_id,
            }).then(response => {
            if(response.data.status === "ok"){
                notify_user_sponsor_connected();
            }
        });
    }

    let social_icons = {
        'Facebook':{icon:<FacebookIcon/>,color:"#4267B2"},
        'LinkedIn':{icon:<LinkedInIcon/>,color:"#0077B5"},
        'Youtube':{icon:<YouTubeIcon/>,color:"#FF0000"},
        'Twitter':{icon:<TwitterIcon/>,color:"#1DA1F2"}
    }

    const notify = () => toast.success(t("Successfully added"), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notify_removed = () => toast.success(t("Successfully removed"), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notify_sponsor_connected = () => toast.success(t("Your info has successfully been sent to the sponsor."), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notify_user_sponsor_connected = () => toast.success(t("Your info has successfully been sent to the sponsor."), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const BoldTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.87)',
            color: '#ffffff',
            boxShadow: theme.shadows[1],
            fontSize: 15,
            fontWeight: "bold",
        },
        arrow: {
            color: 'rgba(0, 0, 0, 0.87)',
        },
    }))(Tooltip);

    const CategoryTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: 'rgba(255, 255, 255, 0.87)',
            color: '#000',
            boxShadow: theme.shadows[1],
            fontSize: 15,
            fontWeight: "bold",
            padding: "20px"
        },
        arrow: {
            color: 'rgba(255, 255, 255,  0.87)',
        },
    }))(Tooltip);

    useEffect(() => {

        const loadPost = async () => {

            // Till the data is fetch using API
            // the Loading page will show.
            setLoading(true);
            var sponsor_id = location.pathname.split("/")[2];
            // console.log(location.pathname.split("/"))
            // Await make wait until that
            // promise settles and return its reult
            const response = await axios.post(
                constants.LocalUrl + "/data/sponsor_full_data", {'id': sponsor_id});
            // console.log(response.data)
            setSponsor(response.data);
            setFiles(response.data.files);
            // After fetching data stored it in posts state.


            // Closed the loading page
            setLoading(false);
        }
        // Call the function
        loadPost();
    }, []);

    const openFileHandler = (event) => {
        const track = async () => {
            await axios.post(constants.LocalUrl + "/data/track_sponsorfile_open", {
                id: event.target.closest('a').getAttribute('data-sponsorfileid'),
            }).then(response => {});
        }
        track();
    }

    const downloadsHandler = (event) => {

        // console.log(event.target.attributes)
        let file_id = event.target.attributes[4].value;
        let action = event.target.attributes[5].value;
        // console.log(action)
        // const loadPost = async () => {
        axios.post(
            constants.LocalUrl + "/data/add_remove_download", {
                'type': 'Sponsor',
                id: file_id,
                'action': action
            }).then(response => {

            if (response.data.status === "Success") {

                // console.log(files)
                let filez = [...files];
                filez.map((file, index) => {
                        if (file.id == file_id) {
                            filez[index].downloaded = !file.downloaded;
                        }
                    }
                );
                // console.log(filez)
                setFiles(filez);
                if (response.data.action == 'add') {
                    notify();
                } else {
                    notify_removed();
                }
            }
        });
        // }

        // loadPost();
    }


    let category_icons = {
        1 : "/app/award-1.svg",
        2 : "/app/award-2.svg",
        3 : "/app/award-3.svg",
        4 : "/app/award-4.svg",
        5 : "/app/award-5.svg",
    };


    if (loading === true || Object.keys(sponsor).length == 0) {
        return <Loader />;
    }

    const openChatWith = (user_id) => {
        if (!window.temp_user_id || window.temp_user_id == 0) {
            navigate("/app/login");
            return;
        }
        axios.post(constants.LocalUrl + '/chat/openWindow/' + user_id);
    }

    const video_source = (type,src) => {
        let services = {
            youtube : "https://www.youtube.com/embed/",
            vimeo : "https://player.vimeo.com/video/",
            facebook : "",
            liveon : "",
        }

        return services[type] + src;
    }

    return (


        <div style={{width: "100%", backgroundColor: "#fafafa", height: "100vh",paddingLeft:"20px",paddingRight:"20px"}}>
            <div className="container solosponsor" id="speakers-individual"
                 style={{maxWidth: "1473px", width: "100%", paddingTop: "45px"}}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card" style={{
                            borderStyle: 'none',
                            background: '#fff',
                            borderRadius: '10px',
                            boxShadow: '-5px 3px 40px #5e5c9a1a',
                            marginBottom: '14px'
                        }}>
                            <div className="card-body" style={{padding: 0}}>
                                <div className="sponsorList_category_indication_wrapper">
                                    <button className="btn btn-primary sponsorList_category_indication"
                                            type="button" style={{
                                        height: "63px",
                                        padding: '13px 31px',
                                        fontWeight: 'bold',
                                        borderColor: 'white',
                                        color: 'black',
                                        background: '#fff',
                                        marginTop: '-30px',
                                        marginRight: 'auto',
                                        marginLeft: 'auto',
                                        width: 'auto',
                                        position: 'relative',
                                        zIndex: 1,
                                        borderRadius: '30px',
                                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 7px',
                                        textTransform: 'uppercase'
                                    }}>

                                        {sponsor.category_title_single} {sponsor.category_icon ?
                                        <ReactSVG beforeInjection={(svg) => {
                                            svg.classList.add('svg-class-name');
                                            svg.setAttribute('style', 'width:35px');
                                            svg.setAttribute('style', 'height:35px');
                                            svg.setAttribute('fill', sponsor.category_color);
                                        }} src={category_icons[sponsor.category_icon]}/>
                                        : ""}

                                    </button>
                                </div>
                                <div className="sponsor_individual"
                                     style={{display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: '1px solid var(--divider-color)'}}>
                                    {/*{sponsor.logo?<img src={constants.LocalUrl + sponsor.logo} style={{padding: '22px 18px 13px 30px', maxHeight: '120px'}} />:''}*/}
                                    {sponsor.logo ? <img src={sponsor.logo} style={{
                                       maxHeight: '116px',
                                        paddingLeft: '30px'
                                    }}/> : ''}
                                    {/*<div className="badge" style={{padding: '22px 18px 13px 30px'}}>*/}
                                    {/*<div className="badge" >*/}
                                    {/*    <div className="img">*/}

                                    {/*        {sponsor.category_icon?*/}
                                    {/*            <CategoryTooltip title={sponsor.category_title_single} arrow>*/}
                                    {/*                <ReactSVG beforeInjection={(svg) => {*/}
                                    {/*                    svg.classList.add('svg-class-name');*/}
                                    {/*                    svg.setAttribute('style', 'width:35px');*/}
                                    {/*                    svg.setAttribute('fill', sponsor.category_color);*/}
                                    {/*                    svg.setAttribute('stroke', sponsor.category_color);*/}
                                    {/*                }} src={category_icons[sponsor.category_icon]}/></CategoryTooltip>:""}*/}



                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                {/*{console.log("sponsor.video",sponsor.video)}*/}
                                {
                                    sponsor.video && sponsor.type_video
                                        ? <div className="ratio ratio-16x9">
                                            <iframe src={video_source(sponsor.type_video,sponsor.video)}/>
                                        </div>
                                        :
                                        sponsor.topwidebanner
                                            ? <div>
                                                <img width="100%" src={sponsor.topwidebanner} alt=""/>
                                            </div>
                                            : null
                                }
                            </div>
                        </div>
                        <div className="card" style={{
                            borderStyle: 'none',
                            borderRadius: '10px',
                            background: '#fff',
                            boxShadow: '-5px 3px 40px #5e5c9a1a',
                            marginBottom: '14px'
                        }}>

                            {Object.keys(sponsor).length > 0 && sponsor.executives.length > 0?<div className="card-body" style={{padding: 0}}>

                                <h6 className="card-title" style={{
                                    fontSize: '20px',
                                    fontWeight: 700,
                                    padding: '27px 18px 20px 27px',
                                    borderBottom: '1px solid var(--divider-color)'
                                }}>{t("Executives")}</h6>
                                {sponsor.executives && sponsor.executives.map(executive =>
                                    <div key={executive.id} style={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        display: 'flex',
                                        padding: '4px 30px 4px 30px'
                                    }}>
                                        <UserCard user={executive} />

                                        <div className="sponsor_representative_buttons" style={{width: '200px', minWidth: '200px'}}>
                                            {
                                                window.temp_user_id != executive.id
                                                ? <>
                                                        <BoldTooltip title={t("solosponsor_Card")} arrow>
                                                            <button onClick={()=>handleUserSponsorConnect(executive.id)} className="btn btn-secondary" type="button" style={{
                                                                borderColor: "rgb(57, 187, 15)",
                                                                borderRadius: '50%',
                                                                display: 'inline-block',
                                                                marginLeft: '20px',
                                                                width: '46px',
                                                                height: '46px',
                                                                backgroundColor: "#fff"
                                                            }}><ContactsIcon/></button>
                                                        </BoldTooltip>
                                                        <BoldTooltip title={t("solosponsor_Chat")} arrow>
                                                            <button onClick={() => openChatWith(executive.id)}
                                                                    className="btn btn-secondary" type="button" style={{
                                                                backgroundColor: "#fff",
                                                                borderColor: "rgb(57, 187, 15)",
                                                                borderRadius: '50%',
                                                                display: 'inline-block',
                                                                marginLeft: '20px',
                                                                width: '46px',
                                                                height: '46px'
                                                            }}><ChatIcon/></button>
                                                        </BoldTooltip>
                                                        <BoldTooltip title={t("solosponsor_VideoCall")} arrow>
                                                            <button onClick={() => handleVideoCall(true, executive.id)}
                                                                    className="btn btn-secondary" type="button" style={{
                                                                backgroundColor: "#fff",
                                                                borderColor: "rgb(57, 187, 15)",
                                                                borderRadius: '50%',
                                                                display: 'inline-block',
                                                                marginLeft: '20px',
                                                                width: '46px',
                                                                height: '46px'
                                                            }}><VideocamIcon/></button>
                                                        </BoldTooltip>
                                                    </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>:null}

                        </div>
                        <div className="card" style={{
                            borderStyle: 'none',
                            borderRadius: '10px',
                            background: '#fff',
                            boxShadow: '-5px 3px 40px #5e5c9a1a',
                            marginBottom: '14px'
                        }}>

                            {files && files.length > 0?<div className="card-body" style={{padding: 0}}>
                                <h6 className="card-title" style={{
                                    fontSize: '20px',
                                    fontWeight: 700,
                                    padding: '27px 18px 20px 27px',
                                    borderBottom: '1px solid var(--divider-color)'
                                }}>{t("Company_Documentation")}</h6>
                                <div className="file-wrapper">
                                    {files && files.map(file =>
                                            <div key={file.title+"_"+file.id} style={{
                                                background: '#f0f0f0',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                display: 'flex',
                                                padding: '19px 30px 19px 30px'
                                            }}>
                                    <span className="filename">
                                        {file.title}
                                    </span>
                                                <div className="file-actions">
                                                    <BoldTooltip title="Open file" arrow>
                                                    <a target="_blank" href={constants.LocalUrl + file.file} data-sponsorfileid={file.id} onClick={openFileHandler} ><SystemUpdateAltOutlinedIcon/></a>
                                                    </BoldTooltip>
                                                    {file.downloaded === true ?

                                                        <BoldTooltip title="Remove file" arrow>
                                                            <a ><DoneIcon data-id={file.id} action="remove"
                                                                                          onClick={downloadsHandler}/></a>
                                                        </BoldTooltip>:<BoldTooltip title="Save file" arrow>
                                                            <a><AddOutlinedIcon data-id={file.id} action="add"
                                                                         onClick={downloadsHandler}/></a></BoldTooltip>}
                                                </div>
                                            </div>
                                    )}
                                </div>
                            </div>:null}

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card sponsor_description_box" style={{
                            borderStyle: 'none',
                            borderRadius: '10px',
                            background: '#fff',
                            boxShadow: '-5px 3px 40px #5e5c9a1a'
                        }}>

                            <div className="sponsor_description_header">
                                <div className="solo_sponsor_sponsor_socials" style={{justifyContent:"flex-end",marginRight:"21px"}}>
                                    <ul>
                                        {sponsor.socials && Object.keys(sponsor.socials).length > 0 && Object.keys(sponsor.socials).map(social =>
                                            sponsor.socials[social]?
                                                <li key={"socials_"+social}><a target="_blank"
                                                       className={"social_icon_" + social}
                                                       href={sponsor.socials[social]}>{social_icons[social].icon}</a>
                                                </li>:null
                                        )}
                                    </ul>
                                </div>
                                <div className="card-header" style={{padding: 0}}>
                                    <h4 className="card-title"
                                        style={{fontSize: '21px', fontWeight: 700, maxHeight:"58px",overflow:"hidden"}}>{sponsor.title}</h4>
                                </div>
                            </div>
                            <div className="card-body">
                                {/*<h4 className="card-title"*/}
                                {/*    style={{fontSize: '16px', fontWeight: 700}}>{sponsor.title}</h4>*/}
                                <p style={{
                                    // padding: '26px 36px 26px 36px',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    padding:"22px 21px",
                                    // margin: '1rem -1rem 0rem -1rem',
                                    borderTop: '1px solid var(--divider-color)'
                                }} dangerouslySetInnerHTML={{__html: sponsor.description}}/>
                                <div style={{padding: '1rem'}}>
                                    <hr/>
                                    <div style={{textAlign: 'center'}}>
                                        <Button style={{
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            color: '#fff',
                                            borderRadius: '20px',
                                            backgroundColor:"rgb(57, 187, 15)",
                                            borderColor:"rgb(57, 187, 15)"
                                        }} className="btn btn-warning keep_my_info" id="close_button" variant="contained" color="primary" component="span">{t("KEEP_MY_INFO")}</Button>

                                        {/*<button className="btn btn-warning" type="button" onClick={handleConnectSponsor} style={{*/}
                                        {/*    fontSize: '14px',*/}
                                        {/*    fontWeight: 500,*/}
                                        {/*    color: '#000',*/}
                                        {/*    boxShadow: '0 4px #0000000a',*/}
                                        {/*    borderRadius: '25px'*/}
                                        {/*}}>{t("KEEP_MY_INFO")}*/}
                                        {/*</button>*/}
                                    </div>
                                    <hr/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}