import {createMedia} from '@artsy/fresnel';
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {ReactSVG} from 'react-svg'
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import '../../index.css';
import '../../general.css';
import {Button, Container, Menu, Segment, Sidebar, Visibility,} from 'semantic-ui-react'
import SettingsPanel from "../Panel/ModalPanel";
import * as constants from "../Panel/common/Constants"
import PageProvider from "../Page/PageContext";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import {useTranslation} from "react-i18next";
import Logo from "../common/Logo";
import MenuIcon from '@material-ui/icons/Menu';
import Divider from "@material-ui/core/Divider";
import LanguageSelectorForMenu from "../common/Language/LanguageSelectorForMenu";
import {toast} from "react-toastify";
import user from "../../auth/user"
import StageLayout from "./content/Stage";


const {MediaContextProvider, Media} = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})


/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
// const HomepageHeading = ({ mobile, image }) => (
//
//     <Container style={{background:'url(' + image +') no-repeat 100% 100%',height:'500px',width:'unset',justifyContent:'center',alignItems:'flex-end',display:'flex',backgroundSize:'cover'}}>
//
//         <Button primary size='huge' style={{marginBottom: '30px'}}>
//             Get Started
//         </Button>
//     </Container>
// )
//
// HomepageHeading.propTypes = {
//     mobile: PropTypes.bool,
// }

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
function getLocation() {
    let location = useLocation();
    // console.log(location)
}

var menu_icons = {
    home: "/images/menu_home.svg",
    agenda: "/images/menu_agenda.svg",
    sponsors: "/images/menu_sponsors.svg",
    speakers: "/images/menu_speakers.svg",
    stage: "/images/menu_stage.svg",
    login: "/images/login.svg",
    setup: "/images/setup.svg",
    language: "/images/language.svg",
};

export function DesktopContainer(props) {

    const {t, i18n} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(props.id);
    const [fixed, setFixed] = useState(false);
    const [footerlinks, setFooterlinks] = useState(false);
    const {settings, setSettingsWindow, setReloadData} = useContext(PageProvider);
    // console.log(settings);
    const navigate = useNavigate();


    const notify_logout = () => toast.success(t('Succesfully logged out'), {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const hideFixedMenu = () => setFixed(false)
    const showFixedMenu = () => setFixed(true)

    const handleLogout = () => {
        window.axios.post(constants.LocalUrl + '/panel/logout').finally((response) => {

            user.logout("d", "l");
            notify_logout();
            setReloadData(true);
            setTimeout(function () {
                document.location.href = "/";
            }, 1500);

        })
    }


    // let opposite_locale = settings.locale == "en" ? "el" : "en";
    return (

        <Media className={props.id} greaterThan='mobile'>

            <Visibility
                animation="false"
                once={false}
                onBottomPassed={() => showFixedMenu}
                onBottomPassedReverse={() => hideFixedMenu}
                id="header"
            >
                <Segment
                    inverted
                    textAlign='center'
                    style={{minHeight: 'auto'}}
                    vertical
                    className="header-menu"
                    id="main-menu"
                >
                    <Menu
                        fixed={fixed ? 'top' : null}
                        inverted={!fixed}
                        pointing={!fixed}
                        secondary={!fixed}
                        size='large'
                    >
                        <Container>
                            <div className="header_logo_container">
                                {Object.keys(settings).length > 0 ? <a href={constants.LocalUrl + "/"}>
                                    <Logo src={settings.logo} alt={t('alt_Logo')} height="75px"/>
                                </a> : ""}
                            </div>
                            <div className="menu-items">
                                <ul>
                                    <li key="main_menu_home">
                                        <NavLink id="main_menu_home" className="item" to={constants.LocalUrl + "/"}
                                                 activeclassname='is-active'>
                                            <ReactSVG beforeInjection={(svg) => {
                                                svg.classList.add('svg-class-name');
                                                svg.setAttribute('style', 'width:27px');
                                            }} src={constants.LocalUrl + menu_icons.home}/>
                                            {t('HOME')}
                                        </NavLink>
                                    </li>
                                    {settings && settings.menu_stage_visible ? <li key="main_menu_stage">
                                        <NavLink key="main_menu_stage" id="main_menu_stage" className="item" to={constants.LocalUrl + "/stage"}>
                                            <ReactSVG beforeInjection={(svg) => {
                                                svg.classList.add('svg-class-name');
                                                svg.setAttribute('style', 'width:27px');
                                            }} src={constants.LocalUrl + menu_icons.stage}/>
                                            {t('STAGE')}
                                        </NavLink>
                                    </li> : ""}
                                    {settings && settings.menu_agenda_visible ? <li key="main_menu_agenda">
                                        <NavLink  id="main_menu_agenda" className="item" to={constants.LocalUrl + "/agenda"}>
                                            <ReactSVG beforeInjection={(svg) => {
                                                svg.classList.add('svg-class-name');
                                                svg.setAttribute('style', 'width:27px');
                                            }} src={constants.LocalUrl + menu_icons.agenda}/>
                                            {t('AGENDA')}
                                        </NavLink>
                                    </li> : ""}
                                    {settings && settings.menu_speakers_visible ? <li key="main_menu_speakers">
                                        <NavLink id="main_menu_speakers" className="item" to={constants.LocalUrl + "/speakers"}>
                                            <ReactSVG beforeInjection={(svg) => {
                                                svg.classList.add('svg-class-name');
                                                svg.setAttribute('style', 'width:27px');
                                            }} src={constants.LocalUrl + menu_icons.speakers}/>
                                            {t('SPEAKERS')}
                                        </NavLink>
                                    </li> : ""}
                                    {settings && settings.menu_sponsors_visible ? <li key="main_menu_sponsors">
                                        <NavLink id="main_menu_sponsors" className="item" to={constants.LocalUrl + "/sponsors"}>
                                            <ReactSVG beforeInjection={(svg) => {
                                                svg.classList.add('svg-class-name');
                                                svg.setAttribute('style', 'width:27px');
                                            }} src={constants.LocalUrl + menu_icons.sponsors}/>
                                            {t('SPONSORS')}
                                        </NavLink>
                                    </li> : ""}
                                </ul>
                            </div>

                            {/*{settings.locale?<Menu.Item as="a" className="settings_platform" position='right'>
                                    <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('style', 'width:27px');}} src={constants.LocalUrl + menu_icons.language} />
                                    {settings.locale && settings.locale == "en"?"English":"Ελληνικά"}
                                    <div className="submenu">
                                        <ul>
                                            <li>
                                                <a href={"/locale/" + opposite_locale }>{settings.locale == "en"?"Ελληνικά":"English"}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </Menu.Item>:""}*/}
                            <div className="right_part_menu">
                            <LanguageSelectorForMenu/>
                            {settings.user && settings.user.id ?
                                <Menu.Item key="settings_platform" className="settings_platform user" position='right'>
                                    <span style={{borderRadius: '50%', border: "3px solid #676868", display: "block"}}>
                                        <img style={{
                                            borderRadius: '50%',
                                            border: "2px solid #fff",
                                            width: "45px",
                                            height: "45px"
                                        }} className="profile_pic" src={settings.user.user_img}/>
                                    </span>
                                    <div className="submenu">
                                        <ul>
                                            <li key="mmprofile">
                                                <NavLink as="a"
                                                         to={constants.LocalUrl + "/profile"}>{t('My Profile')}</NavLink>
                                            </li>
                                            <li key="mmlogout">
                                                <a onClick={handleLogout}>{t('Logout')}</a>
                                                {/*<a href={constants.LocalUrl + "/panel/logout"}>Logout</a>*/}
                                            </li>
                                        </ul>
                                    </div>
                                </Menu.Item> : <Menu.Item key="settings_platformmm" className="settings_platform login" position='right'>
                                    <NavLink as="a" to={constants.LocalUrl + "/app/login"}>
                                        <ReactSVG style={{marginBottom: "0px"}} beforeInjection={(svg) => {
                                            svg.classList.add('svg-class-name');
                                            svg.setAttribute('style', 'width:31px');
                                        }} src={constants.LocalUrl + menu_icons.login}/>
                                        {t('LOGIN')}
                                    </NavLink>

                                </Menu.Item>}
                            {/*{console.log(settings)}*/}
                            {settings && settings.user && (settings.user.rolenames.includes("root") || settings.user.rolenames.includes("Administrator")) ?
                                <Menu.Item key="settings_platform_settings" as="a" className="settings_platform" position='right'>
                                    {/*{settings && settings.user.id == (settings.setup_object.root_uid || settings.setup_object.support_id)?<Menu.Item as="a" className="settings_platform" position='right'>*/}

                                    <SettingsPanel>
                                        <ReactSVG beforeInjection={(svg) => {
                                            svg.classList.add('svg-class-name');
                                            svg.setAttribute('style', 'width:27px');
                                        }} src={constants.LocalUrl + menu_icons.setup}/>
                                        {t('SETTINGS')}
                                    </SettingsPanel>
                                </Menu.Item> : ""}
                            </div>
                        </Container>
                    </Menu>

                </Segment>
            </Visibility>
            <div id="main_content">
                {props.children}
            </div>
            <div id="footer">
                <div id="footer_content">
                    <div className="cold-sm-4">
                        <a href={constants.LocalUrl + "/"}>
                            {/*<img src={settings.settings_footer_logo} alt="Logo"/>*/}
                            <Logo src={settings.settings_footer_logo} alt={t('alt_Logo')} height="75px"/>
                        </a>
                        <ul>
                            {settings.footer_facebook_social ?
                                <li key={"ffs1"}><a href={settings.footer_facebook_social} target="_blank"><FacebookIcon/></a>
                                </li> : ""}
                            {settings.footer_linkedin_social ?
                                <li key={"ffs2"}><a href={settings.footer_linkedin_social} target="_blank"><LinkedInIcon/></a>
                                </li> : ""}
                            {settings.footer_twitter_social ?
                                <li key={"ffs3"}><a href={settings.footer_twitter_social} target="_blank"><TwitterIcon/></a>
                                </li> : ""}
                            {settings.footer_instagram_social ?
                                <li key={"ffs14"}><a href={settings.footer_instagram_social} target="_blank"><InstagramIcon/></a>
                                </li> : ""}
                            {settings.footer_youtube_social ?
                                <li key={"ffs15"}><a href={settings.footer_youtube_social} target="_blank"><YouTubeIcon/></a>
                                </li> : ""}
                        </ul>
                    </div>
                    <div className="cold-sm-4" style={{marginLeft: "60px",overflow:"anywhere"}}>
                        {/*<h5 style={{*/}
                        {/*    fontSize: "30px",*/}
                        {/*    letterSpacing: "-0.05em",*/}
                        {/*    fontWeight: "400",*/}
                        {/*    marginBottom: "10px"*/}
                        {/*}}>{t('Contact')}</h5>*/}
                        {
                            settings.footer_contact_text && settings.footer_contact_text[i18n.language].trim().length > 0
                            ? <div style={{textAlign: 'left'}}
                                   dangerouslySetInnerHTML={{__html: settings.footer_contact_text ? settings.footer_contact_text[i18n.language] : ""}}/>
                            : <>
                                <span dangerouslySetInnerHTML={{__html: t('about_text_empty_1')}}></span>
                                <span className="set_pointer url_alike" onClick={() => setSettingsWindow({
                                    activate: true,
                                    tab: 5
                                })}><b>{t("WEBSITE_SETTINGS")}</b></span>
                                <span dangerouslySetInnerHTML={{__html: t('about_text_empty_2')}}></span>
                            </>
                        }
                    </div>
                    <div className="cold-sm-4" style={{marginLeft: "60px"}}>
                        {/*<h5 style={{*/}
                        {/*    fontSize: "30px",*/}
                        {/*    letterSpacing: "-0.05em",*/}
                        {/*    fontWeight: "400",*/}
                        {/*    marginBottom: "10px"*/}
                        {/*}}>{t('Links')}</h5>*/}
                        <ul className="footer_links">
                            {settings.common_pages && Object.values(settings.common_pages).map((link) => (
                                <li key={"common_page__"+link.id}><NavLink as="a" to={"/page/" + link.id}>{link.title}</NavLink></li>
                            ))}
                            {settings.footer_links && Object.values(settings.footer_links).map((link) => (
                                <li key={"footer_link__"+link.id}><a target="_blank" href={link.data}>{link.name}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

        </Media>
    )
    // }
}

DesktopContainer.propTypes = {
    children: PropTypes.node,
}

export function MobileContainer(props) {
    // state = {}
    const [state, setState] = useState({sidebarOpened: false})
    const {settings, setReloadData} = useContext(PageProvider);

    const handleSidebarHide = () => setState({sidebarOpened: false})
    const handleToggle = () => setState({sidebarOpened: true})


    const {children} = props.children
    const {sidebarOpened} = state.sidebarOpened
    const {t, i18n} = useTranslation();
    const handleLogout = () => {
        window.axios.get(constants.LocalUrl + '/panel/logout').then((response) => {
            // console.log("response")
            // console.log(response)
            setReloadData(true);
        })

        setTimeout(function () {
            setReloadData(true);
        }, 2000);

    }

    return (
        <Media as={Sidebar.Pushable}
               className={settings.user && settings.user.id ? props.id + " logged_in" : props.id + " not_logged"}
               at='mobile'>
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted
                    onHide={handleSidebarHide}
                    vertical
                    visible={state.sidebarOpened}
                >
                    {settings.user && settings.user.id ?
                        <Menu.Item as="a" className="settings_platform user" position='right'>
                            {/*<span style={{borderRadius:'50%',border:"3px solid #676868",display:"block"}}>*/}
                            <span>
                                <img style={{
                                    borderRadius: '50%',
                                    border: "2px solid #fff",
                                    width: "50px",
                                    height: "50px",
                                    marginRight: "10px"
                                }} className="profile_pic" src={settings.user.user_img}/>
                                {settings.user.name}
                            </span>
                        </Menu.Item> : ""}
                    <NavLink onClick={handleSidebarHide} className="item"
                             to={constants.LocalUrl + "/"}>{t('HOME')}</NavLink>
                    {settings.menu_stage_visible ? <NavLink onClick={handleSidebarHide} className="item"
                                                            to={constants.LocalUrl + "/stage"}>{t('STAGE')}</NavLink> : ""}
                    {settings.menu_agenda_visible ? <NavLink onClick={handleSidebarHide} className="item"
                                                             to={constants.LocalUrl + "/agenda"}>{t('AGENDA')}</NavLink> : ""}
                    {settings.menu_speakers_visible ? <NavLink onClick={handleSidebarHide} className="item"
                                                               to={constants.LocalUrl + "/speakers"}>{t('SPEAKERS')}</NavLink> : ""}
                    {settings.menu_sponsors_visible ? <NavLink onClick={handleSidebarHide} className="item"
                                                               to={constants.LocalUrl + "/sponsors"}>{t('SPONSORS')}</NavLink> : ""}
                    <Divider style={{height: "2px", backgroundColor: "var(--divider-color)", opacity: 1}}/>
                    {settings.user && settings.user.id ? <>

                            <NavLink onClick={handleSidebarHide} style={{borderTop: "none"}} className="item"
                                     to={constants.LocalUrl + "/profile"}>{t('My Profile')}</NavLink>

                            {settings && settings.user && (settings.user.rolenames.includes("root") || settings.user.rolenames.includes("Administrator")) ?
                                <SettingsPanel classes="item" type="horizontal">
                                    {t('SETTINGS')}
                                </SettingsPanel> : null}

                            <a className="item" onClick={handleLogout}>{t('Logout')}</a></> :
                        <NavLink onClick={handleSidebarHide} className="item" to={constants.LocalUrl + "/app/login"}>
                            {t('LOGIN')}
                        </NavLink>

                    }
                </Sidebar>

                <Sidebar.Pusher dimmed={state.sidebarOpened}>
                    <Segment
                        inverted
                        textAlign='center'
                        style={{minHeight: 'auto', padding: '1em 0em', backgroundColor: "rgba(255,255,255,.9)"}}
                        vertical
                    >
                        <Container>

                            <Menu inverted pointing secondary size='large'
                                  style={{display: "flex", justifyContent: "space-between"}}>
                                {/*{settings && settings.user && (settings.user.rolenames.includes("root") || settings.user.rolenames.includes("Administrator"))?<Menu.Item as="a" className="settings_platform" position='right'>*/}
                                {/*{settings && settings.user.id == (settings.setup_object.root_uid || settings.setup_object.support_id)?<Menu.Item as="a" className="settings_platform" position='right'>*/}

                                {/*<SettingsPanel type="horizontal">*/}
                                {/*    <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('style', 'width:27px');}} src={constants.LocalUrl + menu_icons.setup} />*/}
                                {/*    {t('SETTINGS')}*/}
                                {/*</SettingsPanel>*/}
                                {/*</Menu.Item>:""}*/}
                                <div style={{marginRight: '30px', marginBottom: '7px'}}>
                                    <a href={constants.LocalUrl + "/"}>
                                        <Logo src={settings.logo} alt="Logo" width="100%"/>
                                    </a>
                                </div>
                                <Menu.Item onClick={handleToggle}>
                                    {/*<Icon name='sidebar' />*/}
                                    <MenuIcon className="burger_icon"/>
                                </Menu.Item>
                                {/*<Menu.Item position='right'>*/}
                                {/*    <Button as='a' inverted>*/}
                                {/*        Log in*/}
                                {/*    </Button>*/}
                                {/*    <Button as='a' inverted style={{ marginLeft: '0.5em' }}>*/}
                                {/*        Sign Up*/}
                                {/*    </Button>*/}
                                {/*</Menu.Item>*/}
                            </Menu>
                        </Container>
                        {/*<HomepageHeading mobile />*/}
                    </Segment>

                </Sidebar.Pusher>
            </Sidebar.Pushable>
            <div id="main_content">
                {props.children}
            </div>
            <div id="footer">
                <div id="footer_content">
                    <div className="cold-sm-3">
                        <a href={constants.LocalUrl + "/"}>
                            <Logo src={settings.settings_footer_logo} alt={t('alt_Logo')} height="75px"/>
                            {/*<img src={settings.settings_footer_logo} alt="Logo"/>*/}
                        </a>
                        <ul>
                            {settings.footer_facebook_social ?
                                <li key="tffs1"><a href={settings.footer_facebook_social} target="_blank"><FacebookIcon/></a>
                                </li> : ""}
                            {settings.footer_linkedin_social ?
                                <li key="tffs2"><a href={settings.footer_linkedin_social} target="_blank"><LinkedInIcon/></a>
                                </li> : ""}
                            {settings.footer_twitter_social ?
                                <li key="tffs3"><a href={settings.footer_twitter_social} target="_blank"><TwitterIcon/></a>
                                </li> : ""}
                            {settings.footer_instagram_social ?
                                <li key="tffs4"><a href={settings.footer_instagram_social} target="_blank"><InstagramIcon/></a>
                                </li> : ""}
                            {settings.footer_youtube_social ?
                                <li key="tffs5"><a href={settings.footer_youtube_social} target="_blank"><YouTubeIcon/></a>
                                </li> : ""}
                        </ul>
                    </div>
                    <div className="cold-sm-3" style={{overflow:"anywhere"}}>
                        <h5 style={{
                            fontSize: "30px",
                            letterSpacing: "-0.05em",
                            fontWeight: "400",
                            marginBottom: "10px"
                        }}>{t('Contact')}</h5>
                        <div style={{textAlign: 'left'}}
                             dangerouslySetInnerHTML={{__html: settings && settings.footer_contact_text ? settings.footer_contact_text[i18n.language] : ""}}/>
                    </div>
                    <div className="cold-sm-3" style={{}}>
                        <h5 style={{
                            fontSize: "30px",
                            letterSpacing: "-0.05em",
                            fontWeight: "400",
                            marginBottom: "10px"
                        }}>{t('Links')}</h5>
                        <ul className="footer_links">
                            {settings.common_pages && Object.values(settings.common_pages).map((link) => (
                                <li key={"common_pages__"+ link.id}><NavLink as="a" to={"/page/" + link.id}>{link.title}</NavLink></li>
                            ))}
                            {settings.footer_links && Object.values(settings.footer_links).map((link) => (
                                <li key={"footer_links__"+ link.id}><a target="_blank" href={link.data}>{link.name}</a></li>
                            ))}
                        </ul>
                    </div>
                    {/*<div className="cold-sm-3" style={{marginLeft: "60px"}}>*/}
                    {/*    <h5 style={{*/}
                    {/*        fontSize: "30px",*/}
                    {/*        letterSpacing: "-0.05em",*/}
                    {/*        fontWeight: "400",*/}
                    {/*        marginBottom: "10px"*/}
                    {/*    }}>{t('Socials')}</h5>*/}
                    {/*</div>*/}
                </div>
            </div>
            {/*{props.id != 'stage_page' ? <StageLayout/> : null}*/}
            <StageLayout position='footer'/>
        </Media>
    )

}

MobileContainer.propTypes = {
    children: PropTypes.node,
}

export default function ResponsiveContainer(props) {
    /* Heads up!
     * For large applications it may not be best option to put all page into these containers at
     * they will be rendered twice for SSR.
     */
    const usePathname = () => {
        const location = useLocation();
        return location.pathname;
    }
    var location = usePathname();

    var strArray = location.split("/");
    var position = strArray[strArray.length - 1]

    if (position == 'onboarding') {
        position = 'register';
    }

    // console.log("position")
    // console.log(position)
    return (
        <MediaContextProvider>
            <DesktopContainer id={position + "_page"}>{props.children}</DesktopContainer>
            <MobileContainer id={position + "_page"}>{props.children}</MobileContainer>
        </MediaContextProvider>
    );
}

ResponsiveContainer.propTypes = {
    children: PropTypes.node,
}
