import React, {useContext, useEffect, useState} from 'react';
import * as constants from "../../../../common/Constants";
import Grid from "@material-ui/core/Grid";
import ToastProvider from "../../../../../../Providers/ToastProvider";



export default function UpdateFile(props){

    const {notify} = useContext(ToastProvider);
    const [file,setfile] = useState([]);


    useEffect(() => {
        // const loadSchedule = async () => {
        setfile(props.data?props.data:'');
        // }
        // console.log(props);
        // console.log("asd");

        // Call the function
        // loadSchedule();

    }, []);

    const handleFiles = (event) => {
        event.persist();

        setfile((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));

    }

    const onSubmitForm = () => {
        // console.log({file_name:file,sponsor_id:props.sponsor_id})
        axios.post(
            constants.LocalUrl + '/data/sponsorAddFiles',{file_name:file,sponsor_id:props.sponsor_id,filename:props.filename}).then(response => {

            // console.log(response)
            if(response.data.status == 'ok'){
                notify();
            }
            props.triggerdataload(response.data.data);
            props.triggerdrawerfunc(false);
        });

    }

    // if(file.length === 0){
    //     return "Loading..."
    // }


    return (

        <div>
            {/*<Grid container spacing={3} style={{padding:'20px',position:"relative"}}>*/}
            {/*    <Grid item xs={12}>*/}
            {/*        <CloseIcon onClick={props.triggerdrawer(false)} style={{position:'absolute',right:'9px',top:'9px'}}/>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}

            <Grid container spacing={3} style={{padding:'0'}}>
                {/*<Grid item xs={6}>*/}
                {/*    <CTextField name="title" label="File Name" handler={handleFiles} value={props.data.title}/>*/}
                {/*    <br/><br/>*/}
                {/*</Grid>*/}
                <Grid item xs={6}>
                    {/*<FileUploader holdFilename={props.holdFilename} sponsor_id={props.sponsor_id} type={'sponsor_files_data'} filePath={"sponsors_files"} />*/}
                    <img style={{height:"115px"}} src={props.data.file} alt={props.data.file}/>
                </Grid>


                {/*<Grid item xs={12}>*/}
                {/*    <Button id="close_button" onClick={onSubmitForm} variant="contained" color="primary" component="span">Save file</Button>*/}
                {/*</Grid>*/}
            </Grid>
        </div>
    )

}