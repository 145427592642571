import React, {useContext, useEffect, useState} from "react";
import "./styles.css";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Paper from "@material-ui/core/Paper";
import * as constants from "../../Panel/common/Constants";
import {ListItem} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import {ReactSVG} from "react-svg";
import List from "@material-ui/core/List";
import DeleteIcon from "@material-ui/icons/Delete";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import TooltipProvider from "../../../Providers/TooltipProvider"

// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: '100%',
//         maxWidth: 360,
//         backgroundColor: theme.palette.background.paper,
//     },
// }));

export default function DraggableSimpleList(props) {

    const {t, i18n} = useTranslation();
    const {BoldTooltip} = useContext(TooltipProvider);
    const [itemList, setItemList] = useState([]);
    const edit_icon = {
        icon: "/images/edit.svg",
        delete: "/images/trash.svg",
    }

    const deleteRow = (id) => {

        const response = axios.post(constants.LocalUrl + "/data/delete_object", {object: props.object_type,id:id}).then(response => {
            if(response.data.status == "error"){
                toast.error(t('item not deleted'), window.toastOptions);
            }else{
                toast.success(t('item deleted'), window.toastOptions);
                var itemList_c = [...itemList];

                if(itemList_c.length > 0){
                    itemList_c.map((item,index) => {
                        if(item.id == id){
                            itemList_c.splice(index,1)
                        }
                    })
                }
                props.triggerdataload(itemList_c);
            }
        });
    }


    const handleItemListValues = (event) => {


        let value = event.target.parentElement.getAttribute("data-value");
        let name = event.target.parentElement.getAttribute("data-name");
        let item_index = event.target.parentElement.getAttribute("data-index");
        // let value_name = event.target.parentElement.getAttribute("data-value_name");
        // console.log("handleItemListValueaaaaas",item_index,value,name)
        let field_values = [...itemList]
        field_values[item_index]["status"] = value == 1?0:1;
        setItemList(field_values);
        // console.log("handleItemListValues",value,name,field_values)
        {props.buttonAction?props.buttonAction(event):null}

    }

    useEffect(() => {
        setItemList(props.data?props.data:'');
    }, [props.data]);
    // React state to track order of items


    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setItemList(updatedList);
        props.save(updatedList);
    };

    let activeLabel = (value,id,value_name,index) => {
        // console.log("value,id,value_name,index",value,id,value_name,index);
        return(

            <Button className={value == 1?"registration_switch active":"registration_switch inactive"}
                    style={value === 1?{fontSize:"0.875rem",borderRadius:"20px",marginRight:"7px",borderColor:"#39BB0F",color:"#39BB0F"}:{fontSize:"0.875rem",borderRadius:"20px",marginRight:"7px"}}
                    data-id={id}
                    data-index={index}
                    data-value={value}
                    data-value_name={value_name}
                    data-name="in_registration"
                    onClick={handleItemListValues}
                    button-value={value} variant="outlined" color="secondary" >{value === 1?t("Activated_Page"):t("Deactivated_Page")}</Button>
        )
    };

    if(itemList.length == 0){
        return (<Paper style={{padding:'10px 20px 20px 20px', borderRadius:'23px'}}>
            <List id="draggable_simple_list" component="nav" style={{width:'100%',maxWidth:"unset", borderRadius:'23px'}}>
                {props.no_content_message}
            </List>
        </Paper>);
    }


    return (
        // <div className="App">
        <Paper style={{padding:'10px 20px 20px 20px', borderRadius:'23px'}}>
            {/*<Paper style={{overflowY:'auto',maxHeight:'600px',padding:'20px', borderRadius:'23px'}}>*/}
            <List id="draggable_simple_list" component="nav" style={{width:'100%',maxWidth:"unset", borderRadius:'23px'}}>

                {props.table_titles?
                <ListItem>
                    {props.table_titles.map((item,index) =>
                        <ListItemText key={"lit"+index} className="panel_table_list_title" primary={item} />
                    )
                    }
                </ListItem>:<></>}


                <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                        {(provided) => (
                            <div
                                className="list-container"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{width:"100%"}}
                            >
                                {itemList.map((item, index) => (

                                    <Draggable key={item[props.id_key]?item[props.id_key]:item.title + item.id} draggableId={item[props.id_key]?item[props.id_key]:item.title + item.id} index={index}>
                                        {(provided) => (

                                            <div
                                                className="item-container"
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                            >
                                                <Divider light />
                                                <ListItem button key={item.id}>
                                                    <ImportExportIcon style={{fill:"#818181",position:"relative",left:"-4px"}}/>
                                                    <ListItemText className={"draglist-50p"} primary={item.title?item.title:item.name} />
                                                    {props.buttonAction?<ListItemText style={{textAlign:"right"}} secondary={activeLabel(item.status,item.id,item.title,index)} />:null}
                                                    {props.edit?<BoldTooltip title={t("Edit")} arrow>
                                                        <Button className="edit-button"  size="medium" onClick={props.edit(true,item.id)} link-id={item.id}  height="50" color="secondary"> <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.icon} />
                                                        </Button>
                                                    </BoldTooltip>:null}
                                                    {props.delete != 0?<BoldTooltip title={t("Delete")} arrow>
                                                        <Button  className="delete-button" size="medium" onClick={()=>deleteRow(item.id)} height="50" color="secondary">
                                                            <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-edit-button'); svg.setAttribute('style', 'width:29px');}} src={constants.LocalUrl + edit_icon.delete} />
                                                        </Button>
                                                    </BoldTooltip>:null}
                                                </ListItem>
                                            </div>


                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </List>
        </Paper>
        // </div>
    );
}

