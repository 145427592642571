import React, {useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import * as constants from "../../../Panel/common/Constants";
import WizardContext from "../../WizardContext";
import PageProvider from "../../../Page/PageContext";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function CheckboxList() {
    const classes = useStyles();
    const {saveLanguage,setSaveLanguage} = useContext(WizardContext);
    const {setReloadData,settings} = useContext(PageProvider);
    const [checked, setChecked] = React.useState([]);
    const [languages, setLanguage] = React.useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        // console.log(checked)
        setChecked(newChecked);
    };

    let flags = {el:"/app/greece-flag-round.png",en:"/app/united-kingdom-flag-round.png"}

    useEffect(() => {

    },[])

    useEffect(() => {

            window.axios.post(constants.LocalUrl + '/data/getLanguages').then((response) => {
                // console.log("response",response)
                setLanguage(response.data)
            })


    },[])

    useEffect(() => {
        // console.log(settings.languages)
        let ar = []
        Object.keys(settings.languages).map((value) => {
            // console.log(value,"ddd")
            ar.push(value)
        })
        setChecked(ar)
        // console.log(checked)
    },[settings])

    useEffect(() => {
        if(saveLanguage === true) {
            window.axios.post(constants.LocalUrl + '/data/saveFrontEndPanelLanguages', {data: checked}).then((response) => {
                // console.log("response", response)
                // setLanguage(response.data)
                setReloadData(true)
            })
            setSaveLanguage(false)

        }

    },[saveLanguage])

    return (
        <div>
        {/*<p>    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>*/}
        <List className={classes.root}>
            {Object.keys(languages).map((value) => {
                const labelId = `checkbox-list-label-${value}`;

                return (


                    <ListItem className="wizard_language_list" key={value} role={undefined} dense button onClick={handleToggle(value)}>
                        {/*{console.log(checked,value)}*/}
                        <ListItemIcon style={{minWidth:"unset"}}>
                            <Checkbox
                                edge="start"
                                checked={checked.includes(value)?1:0}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={languages[value]} style={{fontSize:"16px"}}/>
                        <ListItemSecondaryAction>
                            <img src={flags[value]} height="28px" width="28px" alt=""/>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List>
        </div>
    );
}