import React, {createRef, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import BasicInfo from './tabs/BasicInfo';
import Dashboard from './tabs/Dashboard';
import Stage from './tabs/Stage';
import Statistics from './tabs/Statistics';
import Sponsors from './tabs/Sponsors';
import Settings from './tabs/Settings';
import Schedule from './tabs/Schedule';
import RegistrationFields from './tabs/RegistrationFields';
import Users from './tabs/Users';
import Invitations from './tabs/Invitations';
import HelpIcon from '@material-ui/icons/Help';

import {ReactSVG} from 'react-svg'
import Divider from '@material-ui/core/Divider';
import './Panel.css';
import * as constants from "./common/Constants"
import {useTranslation} from "react-i18next";
import OndemandDashboard from "./tabs/Ondemand";

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import CloseIcon from "@material-ui/icons/Close";
import HelpDrawer from "./common/HelpDrawer";
import InlineTranslate from "../common/Language/InlineTranslate";
import TooltipProvider from "../../Providers/TooltipProvider";
import PageProvider from "../Page/PageContext";

const tabs_icons = {
    DashboardIcon: "/images/panel/dashboard.svg",
    InfoIcon: "/images/panel/INFO.svg",
    SettingsIcon: "/images/panel/settings.svg",
    SponsorsIcon: "/images/panel/SPONSORS.svg",
    StageIcon: "/images/panel/STAGE.svg",
    StatisticsIcon: "/images/panel/statistics.svg",
    UsersIcon: "/images/panel/users.svg",
    InvitationIcon: "/images/panel/invitation.svg",
    ScheduleIcon: "/images/panel/schedule.svg",
    RegistrationIcon: "/images/panel/REGISTER.svg",
    OndemandIcon: "/images/panel/ONDEMAND.svg",
    MarketingIcon: "/images/panel/MARKETING.svg",
}


function TabPanel(props) {
    const {children, value, index, ...other} = props;


    return (

        <div
            role="tabpanel"
            hidden={value !== index}
            // id={`vertical-tabpanel-${index}`}
            // aria-labelledby={`vertical-tab-${index}`}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        // id: `vertical-tab-${index}`,
        // 'aria-controls': `vertical-tabpanel-${index}`,
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const container_help_button = makeStyles((theme) => ({
    styles: {
        color: '#39BB0F',
        opacity: '.55',
        '&:hover': {
            opacity: 1
        },
        marginLeft: '11px'
    }
}));


const container_styles = makeStyles((theme) => ({
    styles: {
        display: 'flex',
    }
}));

const container_styles_mobile = makeStyles((theme) => ({
    styles: {
        display: 'block',
    }
}));

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',

        // backgroundImage:'<AcUnitIcon />'
    },
    tabs: {
        borderRight: `9px solid ${theme.palette.divider}`,
        width: '21%',
        borderRadius: '4px',
        overflow: 'visible'
    },
    tabs_content: {
        width: '79%',
    },
}));

const useStylesMobile = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'block',
        height: '90%',

        // backgroundImage:'<AcUnitIcon />'
    },
    tabs: {
        borderBottom: `1px solid #fff`,
        width: '100%',
        borderRadius: '4px',
        overflow: 'visible'
    },
    tabs_content: {
        width: '100%',
    },
}));

export default function VerticalTabs(props) {

    const {t, i18n} = useTranslation();
    const helpDrawerRef = createRef();

    const container_style = props.type == "horizontal" ? container_styles_mobile() : container_styles();
    const classes = props.type == "horizontal" ? useStylesMobile() : useStyles();
    const helpbutton_styles = container_help_button();
    const {BoldTooltip} = useContext(TooltipProvider);
    const {settingsWindow} = useContext(PageProvider);

    const [value, setValue] = React.useState(0);
    const [drawerstate, setDrawerstate] = React.useState({
            open: false,
            open_cat: false,
            // id: null,
            id_cat: null,
        }
    );

    useEffect(() => {
        setValue(props.tabSelection)
    }, [props.tabSelection])

    useEffect(() => {
        setValue(settingsWindow.tab?settingsWindow.tab:value)
    }, [settingsWindow])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const toggleDrawer = (attached, help_id) => () => {
        setDrawerstate({...drawerstate, open_cat: attached, id_help: help_id});
        // console.log(drawerstate)
    };

    const tab_headers = [
        {tab_title: t("DASHBOARD"), icon: tabs_icons.DashboardIcon},
        {tab_title: t("BASIC INFO"), icon: tabs_icons.InfoIcon},
        {tab_title: t("STAGE"), icon: tabs_icons.StageIcon},
        {tab_title: t("AGENDA"), icon: tabs_icons.ScheduleIcon},
        {tab_title: t("SPONSORS"), icon: tabs_icons.SponsorsIcon},
        {tab_title: t("USERS"), icon: tabs_icons.UsersIcon},
        {tab_title: t("REGISTRATION"), icon: tabs_icons.RegistrationIcon},
        {tab_title: t("MARKETING"), icon: tabs_icons.MarketingIcon},
        {tab_title: t("ON DEMAND"), icon: tabs_icons.OndemandIcon},
        {tab_title: t("STATISTICS"), icon: tabs_icons.StatisticsIcon},
        {tab_title: t("WEBSITE_SETTINGS"), icon: tabs_icons.SettingsIcon},
    ]


    return (

        <div className={classes.root + " settings_panel"} style={{position: "relative"}}>

            <Container maxWidth="lg" className={container_style.styles}>
                <CloseIcon className="close_icon" onClick={props.handleClose}
                           style={{position: 'absolute', right: '22px', top: '24px'}}/>
                <Tabs
                    orientation={props.type ? props.type : "vertical"}
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                    // TabsProps={{
                    //     style: {
                    //         overflow: "visible",
                    //     }
                    // }}
                    TabIndicatorProps={{

                        style: props.type == "horizontal" ? {
                            backgroundColor: "#39BB0F",
                            borderRadius: "4px",
                            right: "0",
                            zIndex: "1"
                        } : {
                            backgroundColor: "#39BB0F",
                            width: "9px",
                            borderRadius: "4px",
                            right: "0",
                            zIndex: "1"
                        }
                    }}
                >


                    {tab_headers.map((tab, i) => {

                        // Return the element. Also pass key
                        return (<Tab key={i} style={{lineHeight: "20px"}} icon={<ReactSVG beforeInjection={(svg) => {
                            svg.classList.add('svg-class-name');
                            svg.setAttribute('style', 'width:55px');
                        }} src={constants.LocalUrl + tab.icon}/>} label={tab.tab_title} {...a11yProps(i)} />)
                    })}

                </Tabs>


                <TabPanel className={classes.tabs_content} value={value} key={0} index={0}>
                    <Typography xs={12}  variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("DASHBOARD")}
                        <BoldTooltip title={t("Information")} arrow>
                        <HelpIcon
                        className={helpbutton_styles.styles}
                        onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, 'dashboard')}
                        />
                        </BoldTooltip>
                    </Typography>


                    <Divider style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    <br/><br/>

                    <Dashboard/>

                </TabPanel>

                <TabPanel className={classes.tabs_content} value={value} key={1} index={1}>
                    <Typography xs={12}  variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("BASIC INFO")}
                        <BoldTooltip title={t("Information")} arrow>
                        <HelpIcon
                        className={helpbutton_styles.styles}
                        onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "basicinfo")}
                        />
                        </BoldTooltip>
                    </Typography>


                    <Divider style={{height: "3px", backgroundColor: "#39BB0F"}}/>


                    <br/><br/>

                    <BasicInfo/>

                </TabPanel>

                <TabPanel className={classes.tabs_content} value={value} key={2} index={2}>
                    <Typography xs={12}  variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("STAGE")}
                        <BoldTooltip title={t("Ηelp")} arrow>
                        <HelpIcon
                        className={helpbutton_styles.styles}
                        onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "stage")}
                        />
                        </BoldTooltip>
                    </Typography>


                    <Divider style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    <br/><br/>

                    <Stage/>

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} key={3} index={3}>
                    <Typography xs={12}  variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("AGENDA")}
                        <BoldTooltip title={t("Ηelp")} arrow>
                            <HelpIcon
                        className={helpbutton_styles.styles}
                        onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "agenda")}
                        />
                        </BoldTooltip>
                    </Typography>


                    <Divider style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    <br/><br/>

                    <Schedule/>

                </TabPanel>


                <TabPanel className={classes.tabs_content} value={value} key={4} index={4}>
                    {/*<div className="header_part">*/}
                    <Typography xs={12}  variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("SPONSORS")}
                        <BoldTooltip title={t("Ηelp")} arrow>
                            <HelpIcon
                            className={helpbutton_styles.styles}
                            onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "sponsors")}
                            />
                        </BoldTooltip>
                    </Typography>
                    {/*<div className="tools_part">*/}
                    {/*{changeLanguage()} */}

                    {/*</div>*/}
                    {/*</div>*/}

                    <Divider style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    <br/><br/>

                    <Sponsors/>

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} key={5} index={5}>
                    <Typography xs={12}  variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("USERS")}
                        <BoldTooltip title={t("Ηelp")} arrow>
                        <HelpIcon
                        className={helpbutton_styles.styles}
                        onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "users")}
                        />
                        </BoldTooltip>
                    </Typography>


                    <Divider style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    <br/><br/>

                    <Users/>

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} key={6} index={6}>
                    <Typography xs={12}  variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("REGISTRATION")}
                        <BoldTooltip title={t("Ηelp")} arrow>
                        <HelpIcon
                        className={helpbutton_styles.styles}
                        onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "registration")}
                        />
                        </BoldTooltip>
                    </Typography>


                    <Divider style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    {/*<br/><br/>*/}

                    <RegistrationFields/>

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} key={7} index={7}>
                    <Typography xs={12}  variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("MARKETING")}
                        <BoldTooltip title={t("Ηelp")} arrow>
                        <HelpIcon
                        className={helpbutton_styles.styles}
                        onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "marketing")}
                        />
                        </BoldTooltip>
                    </Typography>


                    <Divider style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    {/*<br/><br/>*/}

                    <Invitations/>

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} key={8} index={8}>
                    <Typography xs={12}  variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("ON DEMAND")}
                        <BoldTooltip title={t("Ηelp")} arrow>
                        <HelpIcon
                            className={helpbutton_styles.styles}
                            onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "ondemand")}
                        />
                        </BoldTooltip>
                    </Typography>


                    <Divider style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    {/*<br/><br/>*/}

                    <OndemandDashboard></OndemandDashboard>

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} key={9} index={9}>
                    <Typography xs={12}  variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("STATISTICS")}
                        <BoldTooltip title={t("Ηelp")} arrow>
                            <HelpIcon
                                className={helpbutton_styles.styles}
                                onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "statistics")}
                            />
                        </BoldTooltip>
                    </Typography>


                    <Divider style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    {/*<br/><br/>*/}

                    <SimpleBar forceVisible="y" autoHide={false}
                               style={{maxHeight: "658px", height: "658px", overflowX: "hidden"}}>
                        <Statistics/>
                    </SimpleBar>

                </TabPanel>
                <TabPanel className={classes.tabs_content} value={value} key={10} index={10}>
                    <Typography xs={12}  variant="h5" style={{
                        textAlign: 'left',
                        fontWeight: 600,
                        display: "flex",
                        lineHeight: "20px",
                        justifyContent: "flex-start"
                    }} gutterBottom>
                        {t("WEBSITE_SETTINGS")}
                        <BoldTooltip title={t("Ηelp")} arrow>
                            <HelpIcon className={helpbutton_styles.styles}
                                      onClick={() => helpDrawerRef.current.toggleDrawerAndId(true, "settings")}
                            />
                        </BoldTooltip>
                    </Typography>


                    <Divider style={{height: "3px", backgroundColor: "#39BB0F"}}/>

                    {/*<br/><br/>*/}

                    <Settings/>

                </TabPanel>


            </Container>

            <HelpDrawer ref={helpDrawerRef} />
        </div>
    );
}
